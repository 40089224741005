import {
  FixedContainer,
  HomeHeroButtonContainer,
  HomeHeroContainer,
  HomeHeroContainerSpacer,
  HomeHeroPrimaryText,
  HomeHeroSecondaryText,
  HomeHeroStickyContainer,
} from './Home.styled';
import { getGreeting } from '../../utils/dateFormatter';
import { Button, ButtonColor, ButtonSize, Icon, IconName } from '@tia/react-ui-library';
import { Routes } from '../../router/routes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppWrapper } from '../../components/appWrapper/AppWrapper';
import debounce from '../../utils/debounce';
import { usePartyAndContactStore } from '../../hooks/store';
import { useHistory } from 'react-router';

export const HomeHeroComponent: React.FC = () => {
  const { t } = useTranslation();
  const appWrapper = useAppWrapper();

  const [scrollPosition, setScrollPosition] = useState<string>('inactive');
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const tiaButtonFullWidth = screenWidth < 1024;
  const tiaButtonLarge = screenWidth < 1024 ? ButtonSize.X_LARGE : ButtonSize.LARGE;
  const isBuyInsuranceVisible = screenWidth >= 768 ? true : false;
  const { partyData } = usePartyAndContactStore();

  useEffect(() => {
    const resizeEventListener = (): void => debounce(() => setScreenWidth(window.innerWidth), 100);

    window.addEventListener('resize', resizeEventListener);

    return (): void => window.removeEventListener('resize', resizeEventListener);
  }, [screenWidth]);

  const listener = (): void => {
    if (appWrapper.scrollTop > 0) {
      setScrollPosition('active');
    } else {
      setScrollPosition('inactive');
    }
  };

  useEffect(() => {
    if (!appWrapper) {
      return;
    }

    appWrapper.addEventListener('scroll', listener);

    return (): void => appWrapper.removeEventListener('scroll', listener);
  }, []);

  const name = partyData?.forename || '';

  return (
    <>
      <HomeHeroStickyContainer>
        <HomeHeroContainer className={scrollPosition} data-testid="home-hero">
          <FixedContainer className={scrollPosition}>
            <HomeHeroPrimaryText>{`${getGreeting(t)}, ${name}!`}</HomeHeroPrimaryText>
            <HomeHeroSecondaryText className={scrollPosition}>
              {t('views.home.heroSecondaryText')}
            </HomeHeroSecondaryText>
            <HomeHeroButtonContainer className={scrollPosition}>
              <MakeAClaimButton size={tiaButtonLarge} block={tiaButtonFullWidth} />
              {isBuyInsuranceVisible && <div style={{ width: '10px', height: '16px' }} />}
              {isBuyInsuranceVisible && <BuyAnInsuranceButton size={tiaButtonLarge} block={tiaButtonFullWidth} />}
            </HomeHeroButtonContainer>
          </FixedContainer>
        </HomeHeroContainer>
      </HomeHeroStickyContainer>

      <HomeHeroContainerSpacer />
    </>
  );
};

const MakeAClaimButton: React.FC<{ size: ButtonSize; block: boolean }> = ({ size, block }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Button
      onClick={(): void => history.push(Routes.Claim)}
      block={block}
      icon={<Icon icon={IconName.LIBRARY_BOOKS} />}
      size={size}
    >
      {t('views.home.heroPrimaryCTA')}
    </Button>
  );
};

const BuyAnInsuranceButton: React.FC<{ size: ButtonSize; block: boolean }> = ({ size, block }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Button
      onClick={(): void => history.push(Routes.Products)}
      icon={<Icon icon={IconName.ADD_CIRCLE} />}
      size={size}
      block={block}
      color={ButtonColor.ALTERNATIVE}
    >
      {t('views.home.heroSecondaryCTA')}
    </Button>
  );
};
