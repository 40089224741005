import React, { FC, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@tia/react-ui-library';
import IMenuItems from '../../interfaces/IMenuItems';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Routes } from '../../router/routes';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

interface IProps {
  menuItems: IMenuItems[];
  isMobileMenuVisible: boolean;
  setMobileVisibility: Dispatch<SetStateAction<boolean>>;
}

interface IMenuWrapperProps {
  hideMenu: boolean;
}
const MenuItems: FC<IProps> = ({ menuItems, isMobileMenuVisible, setMobileVisibility }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledMenuContainer>
      {menuItems.map((item) => (
        <StyledMenuWrapper hideMenu={item.route === Routes.Products} key={item.route}>
          <StyledMenuItemContainer to={item.route} onClick={(): void => setMobileVisibility(!isMobileMenuVisible)}>
            <StyledIconContainer>
              <StyledIcon icon={item.icon} />
            </StyledIconContainer>
            <StyledMenuTitle> {t(`menu.${item.translationKey}`)}</StyledMenuTitle>
          </StyledMenuItemContainer>
        </StyledMenuWrapper>
      ))}
    </StyledMenuContainer>
  );
};

const StyledMenuTitle = styled.div`
  font-size: 14px;
  padding-left: 15px;
`;

const StyledIconContainer = styled.div`
  padding-top: 5px;
`;

const StyledMenuContainer = styled.div``;

const StyledMenuWrapper = styled.div<IMenuWrapperProps>`
  display: ${({ hideMenu }): string => (hideMenu ? 'none' : 'block')};

  @media (min-width: 768px) {
    display: block;
    height: 64px;
    width: 240px;
    max-width: 240px;
  }
`;

const StyledMenuItemContainer = styled(NavLink).attrs({
  activeClassName: 'active',
})`
  width: calc(100% - 32px);
  height: 48px;
  font-weight: bold;

  background-color: ${ThemeUtility.colorMenuItemBackground};
  color: ${ThemeUtility.colorMenuItemText};
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin: 20px 0px 8px 8px;
  overflow: hidden;
  text-decoration: none;
  border-radius: 24px;
  &.active {
    color: ${ThemeUtility.colorPrimary};
    background: rgba(255, 145, 129, 0.1);
    font-weight: 800;
  }

  &:hover:not(.active) {
    background: ${ThemeUtility.colorMenuItemHoverBackground};
    border-radius: 24px;
  }
`;

const StyledIcon = styled(Icon)`
  &.active {
    color: ${ThemeUtility.colorPrimary};
    font-weight: 800;
  }
`;

export default MenuItems;
