import React from 'react';
import { useTranslation } from 'react-i18next';
import IAuth from '@tia/authenticator/dist/interfaces/IAuth.interface';
import styles from './unauthorized.module.scss';
import { Button } from '@tia/react-ui-library';

interface IProps {
  auth: IAuth;
}

const Unauthorized: React.FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation();

  const onLogin = (): void => props.auth.logout();

  return (
    <div className={styles.unauthorizedContainer}>
      <div className={styles.card}>
        <span className={styles.icon} />
        <span className={styles.errorText}> {t('views.unauthorized.loginErrorText')} </span>
        <div className={styles.buttonContainer}>
          <Button onClick={onLogin} block={true}>Login</Button>
        </div>
      </div>
      <span className={styles.copyright}> {t('views.unauthorized.copyrightText')} </span>
    </div>
  );
};

export default Unauthorized;
