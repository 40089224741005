import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DetailsHeroAction } from '../../components/detailsHero/DetailsHero.component';
import { PolicyObjectGroupDetails } from './components/PolicyObjectGroupDetails.component';
import { PolicyGeneralGroupDetails } from './components/PolicyGeneralGroupDetails.component';
import { CoverGroups } from '../../components/coverGroups/CoverGroups.component';
import styled from 'styled-components';
import { ESalesPolicyProviderMode, SalesPolicyProvider } from '../../hooks/useSalesPolicy';
import { StyledAppContainer } from '../../styles/Styled';
import { ButtonColor, Icon, IconName, Spinner } from '@tia/react-ui-library';
import { getPolicyEditRoute, Routes } from '../../router/routes';
import {
  DesktopDetailsOverlapContainer,
  Detail,
  DetailHeader,
  DetailsContainer,
  DetailsMobileContainer,
  DetailText,
} from '../../components/detailsHero/DetailsHero.styled';
import { PolicyStatus } from '@tia/salesproduct-parser/dist/interfaces/Enums';
import { DetailsHeroHeader } from '../../components/detailsHero/DetailsHeroHeader.component';
import { usePolicyDetails } from './usePolicyDetails';
import { PolicyUpdateFloatingAction } from '../policy/PolicyUpdateFloatingAction.componen';
import { ISalesProduct } from '@tia/salesproduct-parser/dist/interfaces/ISalesProduct';
import { fieldsSelector } from '../../utils/salesPolicy';

export const PolicyDetailsView: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    policyDetails,
    policyData,
    policyUuid,
    policyMode,
    policyStatus,
    subTitle,
    backgroundImage,
    title,
    resetPolicy,
    updateSalesPolicyData,
    togglePolicyMode,
  } = usePolicyDetails();

  const detailElements = policyDetails.map((element, index) => (
    <Detail key={index}>
      <DetailHeader>{element.title}</DetailHeader>
      <DetailText>{element.text ? element.text : <Spinner size={20} />}</DetailText>
    </Detail>
  ));

  const disableEdit = React.useMemo(() => {
    if (policyStatus === PolicyStatus.Quote) {
      return false;
    }

    if (!policyData) {
      return true;
    }

    if (policyData.salesProduct.root.salesProductHeader.policyStatus === PolicyStatus.Policy) {
      return !fieldsSelector(policyData).find((f) => f.fieldUiProperties.mtaEditAble);
    }

    return true;
  }, [policyStatus, policyData]);

  const handlePolicyEdit = (): void => {
    if (policyStatus === PolicyStatus.Quote) {
      history.push(getPolicyEditRoute(policyUuid));

      return;
    }

    if (policyMode === ESalesPolicyProviderMode.EDIT) {
      handleCancel();

      return;
    }

    togglePolicyMode();
  };

  const handleCancel = (): void => {
    resetPolicy();
    togglePolicyMode();
  };

  const handleUpdate = (data: ISalesProduct): void => {
    updateSalesPolicyData(data);
    togglePolicyMode();
  };

  const editPolicyButtonText = React.useMemo(() => {
    if (policyStatus === PolicyStatus.Quote) {
      return t('views.policy-details.editQuote');
    } else if (policyStatus === PolicyStatus.Policy && policyMode === ESalesPolicyProviderMode.VIEW) {
      return t('views.policy-details.editPolicy');
    } else if (policyStatus === PolicyStatus.Policy && policyMode === ESalesPolicyProviderMode.EDIT) {
      return t('views.policy-details.cancelEditing');
    }

    throw 'Unexpected state';
  }, [policyData, policyMode]);

  return (
    <>
      <DetailsHeroHeader subTitle={subTitle} title={title} image={backgroundImage}>
        <DetailsHeroAction
          icon={<Icon icon={IconName.LIBRARY_BOOKS} />}
          color={ButtonColor.PRIMARY}
          onClick={(): void => history.push(Routes.MakeAClaim)}
        >
          {t('views.policy-details.buttonMakeAClaim')}
        </DetailsHeroAction>
        <DetailsHeroAction
          color={ButtonColor.SECONDARY}
          onClick={handlePolicyEdit}
          disabled={disableEdit}
          data-testid="edit-policy-button"
        >
          {editPolicyButtonText}
        </DetailsHeroAction>
      </DetailsHeroHeader>
      <DesktopDetailsOverlapContainer>
        <StyledAppContainer paddingTop="0" paddingBottom="0">
          <DetailsContainer>{detailElements}</DetailsContainer>
        </StyledAppContainer>
      </DesktopDetailsOverlapContainer>
      <DetailsMobileContainer>
        <DetailsContainer>{detailElements}</DetailsContainer>
      </DetailsMobileContainer>

      <StyledAppContainer paddingTop={'0'}>
        <SalesPolicyProvider entities={policyData} mode={policyMode}>
          <PolicyDetailsContainer>
            {policyMode === ESalesPolicyProviderMode.EDIT && (
              <PolicyUpdateFloatingAction onUpdate={handleUpdate} onCancel={handleCancel} />
            )}

            <PolicyDetailsColumn>
              <CoverGroupsInColumn renderToggle={true} disableToggle={policyMode === ESalesPolicyProviderMode.VIEW} />
            </PolicyDetailsColumn>

            <PolicyDetailsColumn>
              <PolicyObjectGroupDetails />
              <PolicyGeneralGroupDetails />
            </PolicyDetailsColumn>
          </PolicyDetailsContainer>
        </SalesPolicyProvider>
      </StyledAppContainer>
    </>
  );
};

const PolicyDetailsContainer = styled.div`
  margin: 0 -1rem;
  display: flex;
  flex-wrap: wrap;
`;

const PolicyDetailsColumn = styled.div`
  margin: 0 1rem;
  flex-basis: calc(50% - 2rem);
  @media (max-width: 767px) {
    flex-basis: 100%;
  }
`;

const CoverGroupsInColumn = styled(CoverGroups)`
  flex-direction: column;
  margin-top: 24px;
`;
