import React from 'react';
import { SalesProductGroupsContext } from '../productDetails/salesProductGroups';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { StyledProductCircle, StyledProductLink, StyledProductMenu } from '../productDetails/ProductDetails.styled';
import classNames from 'classnames';
import { DesktopView, HeroContainer, HeroPrimaryText, MobileView } from '../../styles/Styled';
import { useSalesPolicyProvider } from '../../hooks/useSalesPolicy';
import { ISalesProductRoutingGroup } from '../../interfaces/ISalesProductRouting';
import { getSalesProduct } from '../../utils/salesProductSelectors';
import styled from 'styled-components';

interface PolicyNavigationContext {
  /** Navigates to next group */
  navigateToNextGroup: () => void;
  /** Stores active group ID */
  groupId: string;
  /** Returns active policy routing group */
  getActiveGroup: () => ISalesProductRoutingGroup;
}

const PolicyNavigationContext = React.createContext<PolicyNavigationContext>({} as PolicyNavigationContext);

export const PolicyGroupsNavigation: React.FC = ({ children }): JSX.Element => {
  const { routeGroups, isGroupEnabled } = React.useContext(SalesProductGroupsContext);
  const { entities } = useSalesPolicyProvider();
  const history = useHistory();
  const match = useRouteMatch<{ policyId: string }>();
  const groupId = getSelectedGroupId(`${match.path}/:groupId`);

  const salesProduct = entities && getSalesProduct(entities);

  const getGroupUrl = (groupId: string): string => {
    return `${match.url}/${groupId}`;
  };

  const getActiveGroup = (): ISalesProductRoutingGroup => {
    const routeGroup = routeGroups.find((rg) => rg.nodeId === groupId);

    if (!routeGroup) {
      throw 'Could not find route group';
    }

    return routeGroup;
  };

  const navigateToNextGroup = (): void => {
    const currentGroupIndex = routeGroups.findIndex((rg) => rg.nodeId === groupId);
    const nextRouteGroup = routeGroups[currentGroupIndex + 1];

    if (!nextRouteGroup) {
      return;
    }

    history.push(getGroupUrl(nextRouteGroup.nodeId));
  };

  const navigationContext: PolicyNavigationContext = {
    navigateToNextGroup,
    getActiveGroup,
    groupId,
  };

  return (
    <>
      <DesktopView>
        <DesktopContainer>
          <HeroPrimaryText>{salesProduct?.salesProductUiProperties.label}</HeroPrimaryText>
          <StyledProductMenu>
            {routeGroups?.map((productObject, key) => {
              const isActive = productObject.nodeId === groupId ? 'active' : '';
              const isEnabled = isGroupEnabled(productObject);
              const routeTo = isEnabled ? getGroupUrl(productObject.nodeId) : '#';

              const productLinkClassNames = classNames({
                active: isActive,
                disabled: !isEnabled,
                completed: productObject.isCompleted && isEnabled,
              });

              const productCircleClassNames = classNames({
                active: isActive,
                'is-enabled': isEnabled,
                completed: productObject.isCompleted,
              });

              return (
                <StyledProductLink className={productLinkClassNames} to={routeTo} key={productObject.nodeId}>
                  <StyledProductCircle className={productCircleClassNames}>{key + 1}</StyledProductCircle>
                  {productObject.title}
                </StyledProductLink>
              );
            })}
          </StyledProductMenu>
        </DesktopContainer>
      </DesktopView>
      <MobileView />
      <PolicyNavigationContext.Provider value={navigationContext}>{children}</PolicyNavigationContext.Provider>
    </>
  );
};

const getSelectedGroupId = (routeSchema: string): string => {
  try {
    const { params } = useRouteMatch(routeSchema) as { params: { groupId: string } };

    return params.groupId;
  } catch (e) {
    return '';
  }
};

export const usePolicyNavigation = (): PolicyNavigationContext => {
  return React.useContext(PolicyNavigationContext);
};

const DesktopContainer = styled(HeroContainer)`
  height: 272px;
`;
