import React, { useContext, useEffect, useState } from 'react';
import { ISubGroup } from '../../utils/salesPolicy';
import { SalesProductGroupsContext } from './salesProductGroups';
import { EObjectGroup, ISalesProductRoutingGroup } from '../../interfaces/ISalesProductRouting';
import { INormalizedSalesProduct } from '../../utils/salesProductSchema';
import { allGeneralGroupFieldsSelector, objectGroupFieldsSelector } from '../../utils/salesProductSelectors';
import { IField } from '@tia/salesproduct-parser/dist/interfaces/ISalesProduct';
import { useSalesPolicyProvider } from '../../hooks/useSalesPolicy';
import { usePolicyNavigation } from '../policy/PolicyGroupsNavigation.component';

export interface IFieldValidationContext {
  [key: string]: number | boolean | string;
}

interface IProductSubGroups {
  subGroups: ISubGroup[];
  setSubGroups: (subGroups: ISubGroup[]) => void;
  updateSubGroup: (subGroup: ISubGroup) => void;
  getValidationContext: (subGroup: ISubGroup) => IFieldValidationContext;
  isStepEnabled: (subGroup: ISubGroup) => boolean;
}

export const ProductSubGroupsContext = React.createContext<IProductSubGroups>({} as IProductSubGroups);

export const ProductSubGroupsProvider: React.FC<{ value: IProductSubGroups }> = ({ children, value }) => {
  return <ProductSubGroupsContext.Provider value={value}>{children}</ProductSubGroupsContext.Provider>;
};

export const useProductSubGroups = (group: ISalesProductRoutingGroup): IProductSubGroups => {
  const [subGroups, setSubGroups] = useState<ISubGroup[]>([]);
  const { updateGroup } = useContext(SalesProductGroupsContext);
  const { entities } = useSalesPolicyProvider();
  const { getActiveGroup } = usePolicyNavigation();

  useEffect(() => {
    const isCompleted = !subGroups.map((sg) => sg.isCompleted).includes(false);

    updateGroup({ ...group, isCompleted });
  }, [subGroups]);

  const isStepEnabled = (subGroup: ISubGroup): boolean => {
    const index = subGroups.findIndex((sg) => sg.nodeId === subGroup.nodeId);
    const elementsUntilSubGroup = [...subGroups].slice(0, index);
    const incompleteStep = elementsUntilSubGroup.find((sg) => !sg.isCompleted);

    return !incompleteStep;
  };

  /**
   * Generates a validation context for specified sub group
   */
  const getValidationContext = (): IFieldValidationContext => {
    const activeGroup = getActiveGroup();
    const values: IFieldValidationContext = {};

    const generalGroupFields = allGeneralGroupFieldsSelector(entities);
    const activeGroupFields = getActiveGroupFields(entities, activeGroup);

    [...generalGroupFields, ...activeGroupFields].forEach((field) => {
      values[field.id] = field.value.value;
    });

    return values;
  };

  const updateSubGroup = (subGroup: ISubGroup): void => {
    setSubGroups((oldSubGroups) => {
      const index = oldSubGroups.findIndex((sg) => sg.nodeId === subGroup.nodeId);
      const items = [...oldSubGroups];

      items[index] = { ...subGroup };

      return items;
    });
  };

  return {
    subGroups,
    setSubGroups,
    updateSubGroup,
    getValidationContext,
    isStepEnabled,
  };
};

const getActiveGroupFields = (entities: INormalizedSalesProduct, activeGroup: ISalesProductRoutingGroup): IField[] => {
  if (activeGroup && activeGroup.type === EObjectGroup.ObjectGroup) {
    return objectGroupFieldsSelector(entities, activeGroup.nodeId);
  }

  return [];
};
