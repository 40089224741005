"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var API_URL = 'v1/sales-policies';
exports.getSalesPolicyByUuidUsingGET = function (uuid) {
    return {
        url: API_URL + "/" + uuid,
        method: 'GET',
    };
};
exports.createSalesPolicyUsingPOST = function (data) {
    return {
        url: API_URL,
        method: 'POST',
        data: data,
    };
};
exports.stSalesPolicyInForceUsingPUT = function (data) {
    return {
        url: API_URL + "/set-in-force",
        method: 'PUT',
        data: data,
    };
};
exports.calculatePremiumUsingPUT = function (data) {
    return {
        url: API_URL + "/calculate-premium",
        method: 'PUT',
        data: data,
    };
};
exports.updateSalesPolicyUsingPUT = function (data) {
    return {
        url: API_URL,
        method: 'PUT',
        data: data,
    };
};
exports.cancelSalesPolicyUsingDELETE = function (spdUuid, params) {
    return {
        url: API_URL + "/" + spdUuid + "?" + params.toString(),
        method: 'DELETE',
    };
};
