import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProfileStyledIcon,
  StyledProfileContainer,
  StyledProfileContainersWrapper,
  StyledProfileHero,
  StyledProfileHeroData,
  StyledProfileHeroImage,
  StyledProfileShortInfo,
} from './Profile.styled';
import { StyledAppContainer } from '../../styles/Styled';
import { withRouter, RouteComponentProps } from 'react-router';
import getPartyInformation from '../../utils/partyInformation';
import { usePartyAndContactStore } from '../../hooks/store';
import IParty from '@tia/customer-api-connector/dist/model/IParty';
import { Routes } from '../../router/routes';
import { IconName, Spinner } from '@tia/react-ui-library';

interface ProfileContainer {
  titleTranslationKey: string;
  descriptionTranslationKey: string;
  route: string;
}

const profileContainers: ProfileContainer[] = [
  {
    route: Routes.ProfileDetails,
    titleTranslationKey: 'information',
    descriptionTranslationKey: 'informationDescription',
  },
  {
    route: Routes.ProfileInvoices,
    titleTranslationKey: 'invoices',
    descriptionTranslationKey: 'invoiceDescription',
  },
];

const Profile: FC<RouteComponentProps> = ({ history }): JSX.Element => {
  const { t } = useTranslation();
  const { partyData } = usePartyAndContactStore();

  const routeToPage = (route: string): void => {
    history.push(route);
  };

  const renderedProfileContainers = profileContainers.map((profileContainer) => (
    <StyledProfileContainer
      key={profileContainer.titleTranslationKey}
      onClick={(): void => routeToPage(profileContainer.route)}
    >
      <div>
        <h5>{t(`views.profile.${profileContainer.titleTranslationKey}`)}</h5>
        <p>{t(`views.profile.${profileContainer.descriptionTranslationKey}`)}</p>
      </div>
      <ProfileStyledIcon icon={IconName.CHEVRON_RIGHT} color={'var(--minimal-button-font-color, #9299a9)'} />
    </StyledProfileContainer>
  ));

  const renderStyledProfileHero = (partyData: IParty | undefined): JSX.Element => {
    if (partyData === undefined || !Object.values(partyData).length) {
      return (
        <StyledProfileShortInfo>
          <Spinner size={32} />
        </StyledProfileShortInfo>
      );
    }

    const partyInfo = getPartyInformation(partyData);

    return (
      <StyledProfileShortInfo>
        <h4>{partyInfo.name}</h4>
        <p>{partyInfo.email}</p>
        <p>{partyInfo.mobile}</p>
      </StyledProfileShortInfo>
    );
  };

  return (
    <div>
      <StyledProfileHero>
        <StyledProfileHeroData>
          <StyledAppContainer>{renderStyledProfileHero(partyData)}</StyledAppContainer>
        </StyledProfileHeroData>
        <StyledProfileHeroImage />
      </StyledProfileHero>

      <StyledAppContainer>
        <StyledProfileContainersWrapper>{renderedProfileContainers}</StyledProfileContainersWrapper>
      </StyledAppContainer>
    </div>
  );
};

export default withRouter(Profile);
