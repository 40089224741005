import React, { FC } from 'react';
import ClaimsListWidget from '../../widgets/ClaimsList.widget';
import userIdentityTypes from '../../utils/userIdentityTypes';
import PolicyQuotesWidget from '../../widgets/PolicyQuotes.widget';
import { StyledAppContainer } from '../../styles/Styled';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { HomeHeroComponent } from './HomeHero.component';

const Home: FC<RouteComponentProps> = (): JSX.Element => {
  const identityType = userIdentityTypes();

  return (
    <>
      <HomeHeroComponent />

      <StyledAppContainer>
        <PolicyQuotesWidget partyId={identityType.customer} />
        <ClaimsListWidget userId={identityType.customer} />
      </StyledAppContainer>
    </>
  );
};

export default withRouter(Home);
