import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  StyledInformationButton,
  StyledInformationContainersWrapper,
  StyledInformationContainer,
  StyledAddress,
  StyledContactDetails,
  MobileView,
  DesktopView,
} from './Information.styled';
import { StyledAppContainer } from '../../../styles/Styled';
import getPartyNameAndAddress from '../../../utils/partyNameAndAddress';
import { usePartyAndContactStore } from '../../../hooks/store';
import useEventBinder from '../../../hooks/useEventBinder';
import { apiConnector } from '../../../utils/apiConnector';
import showToastMessage from '../../../utils/showToastMessage';
import ContactType from '@tia/customer-api-connector/dist/model/ContactType';
import { Routes } from '../../../router/routes';
import { ButtonSize, Button, ToastVariant } from '@tia/react-ui-library';
import { NavigateBackButton } from '../Profile.styled';

const Information: FC<RouteComponentProps> = ({ history }): JSX.Element => {
  const { t } = useTranslation();
  const { partyData, setLoaded, emailState, mobileState } = usePartyAndContactStore();

  const returnToProfile = (): void => {
    history.push(Routes.Profile);
  };

  const updateInformation = async (): Promise<void> => {
    try {
      const partyResponse = await apiConnector().party.updateContactDetails([
        {
          type: ContactType.EMAIL,
          detail: emailState,
        },
        {
          type: ContactType.MOBIL,
          detail: mobileState,
        },
      ]);

      if (partyResponse) {
        setLoaded(false);
        showToastMessage({
          message: t('views.profile.myInformation.successMessage') || 'Successful',
          variant: ToastVariant.SUCCESS,
        });
        history.push(Routes.Profile);
      }
    } catch (e) {
      console.error('Could not fetch contacts', e);
    }
  };

  const renderContactDetails = (): JSX.Element => {
    return (
      <>
        <MobileView>
          <ContactDetail theme="minimal" />
        </MobileView>
        <DesktopView>
          <ContactDetail theme="default" />
        </DesktopView>
      </>
    );
  };

  const renderAddressAndContact = (): JSX.Element => {
    const { name, street, streetNo, postArea, city, country } = getPartyNameAndAddress(partyData);

    return (
      <StyledInformationContainer>
        <h5>{name}</h5>
        <StyledAddress>
          {street}, {streetNo}, {postArea}, {city}, {country}
        </StyledAddress>
        {renderContactDetails()}
        <p>{t('views.profile.myInformation.customerInfo')}</p>
      </StyledInformationContainer>
    );
  };

  const renderCustomerInformation = (): JSX.Element => {
    return (
      <>
        {renderAddressAndContact()}
        <div className="updateButton">
          <Button onClick={updateInformation} size={ButtonSize.LARGE}>
            {t('views.profile.myInformation.updateInformation')}
          </Button>
        </div>
      </>
    );
  };

  const renderCustomerInformationWrapper = (): JSX.Element => {
    return (
      <>
        <MobileView>{renderCustomerInformation()}</MobileView>
        <DesktopView>{renderCustomerInformation()}</DesktopView>
      </>
    );
  };

  return (
    <StyledAppContainer>
      <StyledInformationButton>
        <NavigateBackButton onClick={returnToProfile} />
        <h2>My information</h2>
      </StyledInformationButton>
      <StyledInformationContainersWrapper>{renderCustomerInformationWrapper()}</StyledInformationContainersWrapper>
    </StyledAppContainer>
  );
};

const ContactDetail: FC<{ theme: string }> = ({ theme }): JSX.Element => {
  const { t } = useTranslation();
  const { emailState, mobileState, setEmailState, setMobileState } = usePartyAndContactStore();

  const emailRef = useEventBinder<{ value: string }>('change', (e) => {
    if (e?.detail) {
      setEmailState(e.detail.value);
    }
  });

  const mobileRef = useEventBinder<{ value: string }>('change', (e) => {
    if (e?.detail) {
      setMobileState(e?.detail.value);
    }
  });

  return (
    <StyledContactDetails>
      <tia-input
        class="firstInput"
        type="text"
        ref={emailRef}
        label={t('views.profile.myInformation.email')}
        size="small"
        full="true"
        value={emailState}
        theme={theme}
      />
      <tia-input
        class="secondInput"
        type="text"
        ref={mobileRef}
        label={t('views.profile.myInformation.mobile')}
        size="small"
        full="true"
        value={mobileState}
        theme={theme}
      />
    </StyledContactDetails>
  );
};

export default withRouter(Information);
