import React, { useMemo, useState } from 'react';

interface ISalesPolicyErrorsContext {
  controllers: ErrorController[];
  registerController: (errorController: ErrorController) => void;
  unregisterController: (errorController: ErrorController) => void;
}

const SalesPolicyErrorsContext = React.createContext<ISalesPolicyErrorsContext>(undefined as never);

interface ErrorController {
  id: number;
  error?: string;
}

/**
 * Provides sales policy/product for children components
 */
export const SalesPolicyErrorProvider: React.FC = ({ children }) => {
  const [errorControllers, setErrorControllers] = useState<ErrorController[]>([]);

  const registerController = (errorController: ErrorController): void => {
    if (errorControllers.find((ec) => ec.id === errorController.id)) {
      return;
    }

    setErrorControllers((previousValue) => {
      return [...previousValue, errorController];
    });
  };

  const unregisterController = (errorController: ErrorController): void => {
    setErrorControllers((previousErrors) => {
      return previousErrors.filter((c) => c !== errorController);
    });
  };

  const context = useMemo(
    () => ({
      registerController,
      unregisterController,
      controllers: errorControllers,
    }),
    [errorControllers, registerController, unregisterController]
  );

  return <SalesPolicyErrorsContext.Provider value={context}>{children}</SalesPolicyErrorsContext.Provider>;
};

interface IUseSalesPolicyErrors {
  hasError: () => boolean;
  registerController: (errorController: ErrorController) => void;
  unregisterController: (errorController: ErrorController) => void;
}

export const useSalesPolicyErrors = (): IUseSalesPolicyErrors => {
  const context = React.useContext(SalesPolicyErrorsContext);

  if (context === undefined) {
    throw new Error('useSalesPolicyErrors must be used within a SalesPolicyErrorProvider');
  }

  const { registerController, unregisterController, controllers } = context;

  const hasError = (): boolean => {
    return !!controllers.find((c) => !!c.error);
  };

  return {
    hasError,
    registerController,
    unregisterController,
  };
};

let errorUniqueId = 1;

export const createErrorController = (error = ''): ErrorController => {
  return {
    id: errorUniqueId++,
    error,
  };
};
