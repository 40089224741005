import i18nInit from './i18n';

export interface ICustomerPortalOptions {
  domain: string;
  clientID: string;
  redirectUri: string;
  successfulLogoutUri: string;
  claimsApiUri: string;
  tiaClaimListWidget?: Record<string, unknown>;
  cssApiUri: string;
  tiaPolicyQuotesWidget?: Record<string, unknown>;
  fallbackLanguage: string;
  supportedLanguages: string;
  audience: string;
  additionalAuthOptions: Record<string, unknown>;
  contextPath: string;
  overrides: { __token_issuer: string };
  contextLocation: string;
  cjdAudience: string;
  brandedWidgets: {
    'tia-css-branded-claim': string;
    'tia-css-branded-make-a-claim': string;
    'tia-css-branded-product-variant-one': string;
    'tia-css-branded-product-variant-two': string;
  };
}

declare global {
  interface Window {
    custEnv: ICustomerPortalOptions;
  }
}

/**
 * Will register configurable env variables and attach them to the window object in window.custEnv
 * From here variables can be requested as window.custEnv.variableName
 *
 * In production:
 *    Will fetch variables from json file from public/environment/environment.json
 *    The json should be complient with the ICustomerPortalOptions interface definition above
 *
 * In development:
 *    Will read from .env.development file and attach the associated variables
 *
 * NB: register() is asynchronous, and will have to be
 * awaited before mounting the root component to the DOM
 */
const register = async (): Promise<void> => {
  let options: ICustomerPortalOptions;

  if (process.env.NODE_ENV === 'production') {
    const optionsJson = await fetch(`${process.env.PUBLIC_URL}/environment/environment.json`);

    options = await optionsJson.json();

    localStorage.setItem('cjd_endpoint', options.claimsApiUri);
    localStorage.setItem('css_api', options.cssApiUri);
  } else {
    options = {
      domain: process.env.REACT_APP_DOMAIN || '',
      clientID: process.env.REACT_APP_CLIENT_ID || '',
      successfulLogoutUri: process.env.REACT_APP_SUCCESSFUL_LOGOUT_URI || '',
      redirectUri: process.env.REACT_APP_REDIRECT_URI || '',
      claimsApiUri: process.env.REACT_APP_CLAIMS_API_URI || '',
      cssApiUri: process.env.REACT_APP_CSS_API_URI || '',
      supportedLanguages: process.env.REACT_APP_SUPPORTED_LANGUAGES || '',
      fallbackLanguage: process.env.REACT_APP_FALLBACK_LANGUAGE || '',
      audience: process.env.REACT_APP_AUDIENCE || '',
      cjdAudience: process.env.REACT_APP_CJD_AUDIENCE || '',
      additionalAuthOptions: {},
      brandedWidgets: {
        'tia-css-branded-claim': process.env.REACT_APP_WIDGET_CLAIM || '',
        'tia-css-branded-make-a-claim': process.env.REACT_APP_WIDGET_MAKE_A_CLAIM || '',
        'tia-css-branded-product-variant-one': process.env.REACT_APP_WIDGET_PRODUCT_VARIANT_ONE || '',
        'tia-css-branded-product-variant-two': process.env.REACT_APP_WIDGET_PRODUCT_VARIANT_TWO || '',
      },
      contextPath: '',
      overrides: {
        // eslint-disable-next-line
        __token_issuer: '',
      },
      contextLocation: '',
    };

    localStorage.setItem('cjd_endpoint', process.env.REACT_APP_CLAIMS_API_URI || '');
    localStorage.setItem('css_api', process.env.REACT_APP_CSS_API_URI || '');
  }

  window.custEnv = options;
  const windowLocation = window.location.origin;
  const contextPath = window.custEnv.contextPath;

  window.custEnv.contextLocation = contextPath ? `${windowLocation}/${contextPath}` : windowLocation;

  i18nInit(options.supportedLanguages, options.fallbackLanguage, options.contextPath);
};

export default register;
