import React, { ReactElement } from 'react';
import styled, { keyframes } from 'styled-components';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

interface IStyledAppContainer {
  paddingTop?: string;
  paddingBottom?: string;
}

export const StyledAppContainer = styled.div<IStyledAppContainer>`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: ${({ paddingTop }): string => paddingTop || '24px'};
  padding-bottom: ${({ paddingBottom }): string => paddingBottom || '24px'};

  @media (min-width: 1025px) {
    max-width: 1172px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledMainWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const StyledBackButton = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    margin: 67px 0 28px;
    justify-content: flex-start;
    tia-button {
      margin-right: 16px;
      &:hover {
        box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.05);
      }
      &:active {
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
      }
      h2 {
        font-size: 26px;
        font-weight: 300;
      }
    }
  }
`;

export const MobileView = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const DesktopView = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const HeroPrimaryText = styled.h1`
  font-weight: 300;
  font-size: 28px;
  color: ${ThemeUtility.colorHeaderText};
  letter-spacing: 0;
  margin: 0 0 8px 16px;
  align-self: center;

  @media (min-width: 1025px) {
    align-self: center;
    font-size: 32px;
    margin: 0 0 16px 0;
  }
`;

export const HeroSecondaryText = styled.h2`
  font-weight: 400;
  font-size: 16px;
  color: ${ThemeUtility.colorCaptionText};
  opacity: 1;
  align-self: center;
  margin: 0 0 0 16px;

  @media (min-width: 1025px) {
    align-self: center;
    text-align: center;
    margin: 0 0 32px 0;

    &.active {
      margin: 0;
      opacity: 0;
    }
  }
`;

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 0;
  background: #fff;
`;

const StyledBackgroundOverlay = styled.div`
  opacity: 0.8;
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const StyledDesktopContainer = styled.div<{ img?: string }>`
  background: url(${({ img }: { img?: string }): string => (img ? img : '')});
  background-repeat: no-repeat;
  background-size: cover;
  height: 272px;
`;

const StyledHeroChildren = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  z-index: 1;
  margin: 56px 0 0 0;
`;

export const HeroProductContainer = ({
  img,
  children,
}: {
  img?: string;
  children: ReactElement | ReactElement[];
}): JSX.Element => (
  <div style={{ position: 'relative' }}>
    <StyledHeroChildren>{children}</StyledHeroChildren>
    <StyledBackgroundOverlay />
    <StyledDesktopContainer img={img} />
  </div>
);

export const fadeInKeyframes = keyframes`
  0% {
    opacity: 0;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }

  100% {
    opacity: 1;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }
`;

export const fadeOutKeyframes = keyframes`
  0% {
    opacity: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  1% {
    flex-direction: column;
    align-items: center;
  }

  100% {
    opacity: 1;
  }
`;
