import styled from 'styled-components';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const StyledInformationButton = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    margin: 67px 0 28px;
    //width: 662px;
    justify-content: flex-start;
    tia-button {
      margin-right: 16px;
      &:hover {
        box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
      }
      &:active {
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
      }
      h2 {
        font-size: 26px;
        font-weight: 300;
      }
    }
  }
`;

export const StyledInformationContainersWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  height: 470px;
  width: 100%;

  .updateButton {
    align-self: center;
  }

  @media (min-width: 1024px) {
    margin: 0 auto;
    height: 342px;

    .updateButton {
      align-self: flex-end;
    }
  }
`;

export const StyledInformationContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  margin-bottom: 24px;
  background-color: ${ThemeUtility.colorPanelBackground};
  border-bottom: 1px solid rgb(226, 229, 234);

  h5 {
    color: ${ThemeUtility.colorHeaderText};
    font-size: 22px;
    font-weight: normal;
    height: 28px;
    letter-spacing: 0px;
    max-width: 179px;
  }
  p {
    flex: 1;
    color: ${ThemeUtility.colorBodyText}
    width: 100%;
    height: 110px;
    font-size: 12px;
    font-weight: normal;
    line-height: 22px;
  }

  @media (min-width: 1024px) {
    height: 286px;
    //width: 662px;
    margin-bottom: 16px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid rgb(226, 229, 234);
    padding: 24px 32px;

    p {
      height: 66px;
      font-size: 14px;
    }
  }
`;

export const StyledAddress = styled.div`
  color: ${ThemeUtility.colorCaptionText};
  font-size: 16px;
  font-weight: normal;
  height: 20px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const StyledContactDetails = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 136px;
  margin-bottom: 24px;
  margin-top: 24px;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-content: space-between;
    width: 598px;
    height: 59px;
    margin-bottom: 32px;

    tia-input {
      flex: 1;
    }

    .firstInput {
      margin-right: 8px;
    }
    .secondInput {
      margin-left: 8px;
    }
  }
`;

export const StyledButton = styled.div`
  width: calc(100% - 24px);
  text-decoration: none;

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
  }
`;

export const MobileView = styled.div`
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const DesktopView = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;
