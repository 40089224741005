import styled from 'styled-components';
import { StyledBackButton } from '../../../styles/Styled';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const StyledInvoiceButtons = styled(StyledBackButton)``;

export const MobileTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .MobileTableRowStyle {
    color: ${ThemeUtility.colorHeaderText};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    height: 172px;
    border-radius: 8px;
    margin: 10px;
    background: ${ThemeUtility.colorPanelBackground};
    border: 1px solid ${ThemeUtility.colorDividerBackground};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  }
  .headerPanelRow {
    color: ${ThemeUtility.colorCaptionText};
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  .price {
    font-size: 20px;
    font-weight: 600;
  }
  .type {
    font-size: 14px;
    color: ${ThemeUtility.colorBodyText};
  }
  .footerPanelRow {
    color: ${ThemeUtility.colorCaptionText};
    display: flex;
    align-items: center;
    .iconedContainer {
      display: flex;
      align-items: center;
      padding-right: 16px;
      font-size: 14px;
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const StyledTable = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: inherit;
  }
`;
