let time = 0;

/**
 * @param { Function } callback
 * @param { Number } delay
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const debounce = (callback: Function, delay: number): void => {
  clearTimeout(time);

  if (time) {
    clearTimeout(time);
  }

  time = window.setTimeout(callback, delay);
};

export default debounce;
