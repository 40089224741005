import {
  ICoverGroup,
  IField,
  ILov,
  IObjectGroup,
  IOpt,
  ISalesProduct,
} from '@tia/salesproduct-parser/dist/interfaces/ISalesProduct';
import { convertISOReadFriendlyDateString } from './dateFormatter';
import { SalesProductOpt } from './salesProductOpt';
import ISalesProductName from '@tia/customer-api-connector/dist/model/ISalesProductName';
import { default as IOptName } from '@tia/customer-api-connector/dist/model/IOpt';
import { EObjectGroup, ISalesProductRoutingGroup } from '../interfaces/ISalesProductRouting';
import generateRandomString from './generateRandomString';
import {
  INormalizedGeneralGroups,
  INormalizedObjectGroups,
  INormalizedObjectInstances,
  INormalizedSalesObject,
  INormalizedSalesProduct,
} from './salesProductSchema';
import { getFieldById, getObjectInstanceById } from './salesProductSelectors';

const COVER_START_DATE_PATTERN = 'AGR_LINE_COVER_START_DATE';

/**
 * Returns the IOpt value matching the provided key
 * @param salesProduct sales product from the Sales Product Parser
 */
export const getSalesProductImage = (salesProduct: ISalesProduct | INormalizedSalesObject): string => {
  const optValue = getOptValue(salesProduct.salesProductUiProperties.opt, SalesProductOpt.OPT_IMAGE_KEY);

  return optValue ? optValue : '';
};

/**
 * Returns the IOpt value matching the provided key
 * @param salesProductName sales product
 */
export const getSalesProductNameImage = (salesProductName: ISalesProductName): string => {
  return getOptName(salesProductName.salesProductOpt, SalesProductOpt.OPT_IMAGE_KEY);
};

/**
 * Returns the IOpt value matching the provided key
 * @param salesProduct sales product from the Sales Product Parser
 * @param key opt key
 */
const getOptName = (opts: IOptName[], key: string): string => {
  const imageOpt = opts.find((opt: IOptName) => opt.key === key);
  const bannerUrl = imageOpt ? `url(${imageOpt.value})` : '';

  return bannerUrl;
};

/**
 * Returns the IOpt value matching the provided key
 * @param salesProduct sales product from the Sales Product Parser
 * @param key opt key
 */
export const getOptValue = (opts: IOpt[], key: string): string | undefined => {
  const opt = opts && opts.find((opt: IOpt) => opt.key === key);

  return opt?.value;
};

/**
 * Tries to find cover start date form general groups
 * @param salesProduct SPD sales product
 */
export const getCoverStartDate = (salesProduct: INormalizedSalesProduct): IField | undefined => {
  const generalGroups = getGeneralGroups(salesProduct);

  for (let fieldIndex = 0; fieldIndex < generalGroups.length; fieldIndex++) {
    const generalGroup = generalGroups[fieldIndex];
    const fields = getGeneralGroupFields(salesProduct, generalGroup.nodeID);

    const field = getField(fields, COVER_START_DATE_PATTERN);

    if (field) {
      return field;
    }
  }

  return undefined;
};

/**
 * Returns first field with backendProductField that matches pattern
 * @param fields SPD fields that will be iterated to find match
 * @param pattern used to match backendProductField
 */
const getField = (fields: IField[], pattern: string): IField | undefined => {
  const field = fields.find((field: IField) =>
    field.fieldSystemProperties.backendProductFields.find((backendField: string) => backendField.includes(pattern))
  );

  return field;
};

/**
 * Get ObjectGroup title
 * 1. It will try to find value from titleFieldId on objectGroupSystemProperties
 * 2. It will return objectGroupUiProperties.label
 */
export const getObjectGroupTitle = (
  entities: INormalizedSalesProduct,
  objectGroup: INormalizedObjectGroups,
  objectInstance: INormalizedObjectInstances
): string => {
  if (objectGroup.objectGroupSystemProperties.titleFieldId) {
    const fields = objectInstance.fields.map((fieldId) => getFieldById(entities, fieldId));
    const titleField = fields.find(
      (field: IField) => field.id === objectGroup.objectGroupSystemProperties.titleFieldId
    );

    if (titleField?.value.value) {
      return titleField.value.value;
    }
  }

  return objectGroup.objectGroupUiProperties.label;
};

/**
 * Returns a list of ascending sorted CoverGroups using coverGroupUiProperties.order
 * @param coverGroups
 */
export const getSortedCoverGroups = (coverGroups: ICoverGroup[]): ICoverGroup[] =>
  coverGroups.sort((a, b) => a.coverGroupUiProperties.order - b.coverGroupUiProperties.order);

/**
 * Returns a list of ascending sorted CoverGroups using objectGroupUiProperties.order
 * @param objectGroups
 */
export const getSortedObjectGroups = (objectGroups: IObjectGroup[]): IObjectGroup[] =>
  objectGroups.sort((a, b) => a.objectGroupUiProperties.order - b.objectGroupUiProperties.order);

/**
 * Returns a list of ascending sorted CoverGroups using fieldUiProperties.order
 * @param fields
 */
export const getSortedFields = (fields: IField[]): IField[] =>
  fields.sort((a, b) => a.fieldUiProperties.order - b.fieldUiProperties.order);

/**
 * Returns field value
 * 1. Lov will be returned as Lov Label
 * 2. Dates will be returned in the format October 24, 2019
 * @param field
 */
export const getFieldValue = (field: IField): string => {
  const lov = field.fieldUiProperties.lov;

  if (Array.isArray(lov) && lov.length) {
    const selectedLov = field.fieldUiProperties.lov.find((lov: ILov) => lov.value.value === field.value.value);

    if (selectedLov) {
      return selectedLov.label;
    }

    return '';
  }

  if (field.fieldUiProperties.dataType === 'DATE') {
    return convertISOReadFriendlyDateString(field.value.value);
  }

  return field.value.value;
};

export const getObjectGroups = (state: INormalizedSalesProduct): INormalizedObjectGroups[] => {
  return Object.keys(state.objectGroups).map((key) => state.objectGroups[key]);
};

export const getGeneralGroupById = (state: INormalizedSalesProduct, nodeID: string): INormalizedGeneralGroups => {
  return state.generalGroups[nodeID];
};

export const getGeneralGroupFields = (state: INormalizedSalesProduct, objectGroupNodeId: string): IField[] => {
  const generalGroup = state.generalGroups[objectGroupNodeId];

  return generalGroup.fields.map((key) => state.fields[key]);
};

export const getGeneralGroups = (state: INormalizedSalesProduct): INormalizedGeneralGroups[] => {
  return Object.keys(state.generalGroups).map((key) => state.generalGroups[key]);
};

export const fieldsSelector = (state: INormalizedSalesProduct): IField[] => {
  return Object.keys(state.fields).map((key) => state.fields[key]);
};

export const objectInstancesSelector = (state: INormalizedSalesProduct): INormalizedObjectInstances[] => {
  return Object.keys(state.objectInstances).map((key) => state.objectInstances[key]);
};

export const getSalesProductRouting = (
  normalized: INormalizedSalesProduct,
  coverGroupTitle: string
): ISalesProductRoutingGroup[] => {
  const sortedGeneralGroupsWithOrder: ISalesProductRoutingGroup[] = getGeneralGroups(normalized).map(
    (generalGroup) => ({
      order: generalGroup.generalGroupUiProperties.order,
      title: generalGroup.generalGroupUiProperties.label,
      nodeId: generalGroup.nodeID,
      isCompleted: false,
      type: EObjectGroup.GeneralGroup,
    })
  );

  const sortedObjectGroupsWithOrder: ISalesProductRoutingGroup[] = getObjectGroups(normalized).map((objectGroup) => ({
    order: objectGroup.objectGroupUiProperties.order,
    title: objectGroup.objectGroupUiProperties.label,
    nodeId: objectGroup.nodeID,
    type: EObjectGroup.ObjectGroup,
    isCompleted: false,
  }));

  const coverGroupSection: ISalesProductRoutingGroup = {
    order: 50,
    title: coverGroupTitle,
    nodeId: normalized.salesProduct.root.nodeID,
    isCompleted: false,
    type: EObjectGroup.CoverGroup,
  };

  const sections = [...sortedGeneralGroupsWithOrder, ...sortedObjectGroupsWithOrder, coverGroupSection];

  return sections.sort((a, b) => a.order - b.order);
};

export interface ISubGroup {
  stepTitle: string;
  stepQuestion?: string;
  subGroupFields: IField[];
  nodeId: string;
  isCompleted: boolean;
}

export const groupFieldsByPage = (fields: IField[]): Record<string, IField[]> => {
  return fields.reduce((groupedFieldsByPage, field) => {
    return {
      ...groupedFieldsByPage,
      [field.fieldUiProperties.page]: [...(groupedFieldsByPage[field.fieldUiProperties.page] || []), field],
    };
  }, {} as Record<string, IField[]>);
};

const getOptByKey = (options: IOpt[], key: string): IOpt | undefined => {
  return options.find((opt) => opt.key === key);
};

export const getSubGroups = (fields: IField[], options: IOpt[]): ISubGroup[] => {
  const groupedFieldsByPage = groupFieldsByPage(fields);

  const subGroupsOpt = getOptByKey(options, 'subGroups');
  const subGroupNames: { [key: string]: string } = (subGroupsOpt && JSON.parse(subGroupsOpt.value)) || {};

  const subGroupDescriptionOpt = getOptByKey(options, 'subGroupDescriptions');
  const subGroupDescriptionNames: { [key: string]: string } =
    (subGroupDescriptionOpt && JSON.parse(subGroupDescriptionOpt.value)) || {};

  return Object.keys(groupedFieldsByPage).reduce((subGroups: ISubGroup[], page) => {
    const subGroup: ISubGroup = {
      stepTitle: subGroupNames[page] || '',
      subGroupFields: groupedFieldsByPage[page],
      stepQuestion: subGroupDescriptionNames[page],
      nodeId: generateRandomString(),
      isCompleted: false,
    };

    subGroups.push(subGroup);

    return subGroups;
  }, []);
};

export const getObjectGroupSubGroups = (state: INormalizedSalesProduct, nodeId: string): ISubGroup[] => {
  const generalGroup = state.objectGroups[nodeId];
  const objectInstance = getObjectInstanceById(state, generalGroup.objectInstances[0]);
  const fields = objectInstance.fields.map((nodeId) => getFieldById(state, nodeId));

  const subGroupNamesOpt = getOptByKey(generalGroup.objectGroupUiProperties.opt, 'subGroups');
  const subGroupNames = (subGroupNamesOpt && JSON.parse(subGroupNamesOpt.value)) || {};

  const subGroupDescriptionsOpt = getOptByKey(generalGroup.objectGroupUiProperties.opt, 'subGroupDescriptions');
  const subGroupDescriptions = (subGroupDescriptionsOpt && JSON.parse(subGroupDescriptionsOpt.value)) || {};

  const subGroups: ISubGroup[] = [];

  fields.forEach((field) => {
    const page = field.fieldUiProperties.page;

    let subGroup = subGroups.find((sg) => `${sg.nodeId}` === `${page}`);

    if (!subGroup) {
      subGroup = {
        nodeId: `${page}`,
        stepTitle: subGroupNames[page],
        subGroupFields: [field],
        isCompleted: false,
        stepQuestion: subGroupDescriptions[page],
      };
      subGroups.push(subGroup);
    } else {
      subGroup.subGroupFields.push(field);
    }
  });

  return subGroups.sort((a, b) => Number(a.nodeId) - Number(b.nodeId));
};

export const getGeneralGroupSubGroups = (state: INormalizedSalesProduct, nodeID: string): ISubGroup[] => {
  const generalGroup = getGeneralGroupById(state, nodeID);
  const generalGroupFields = getGeneralGroupFields(state, nodeID);
  const visibleFields = generalGroupFields.filter((field: IField) => field.fieldUiProperties.visible);
  const sortedFields = getSortedFields(visibleFields);

  return getSubGroups(sortedFields, generalGroup.generalGroupUiProperties.opt);
};
