import ClaimStatus from '@tia/cjd-api-connector/dist/models/ClaimStatus';

export const getClaimStatusLabelTranslationKey = (status: ClaimStatus): string => {
  switch (status) {
    case ClaimStatus.PENDING:
      return 'views.claim-details.claimStatus.pending';

    case ClaimStatus.ONGOING:
      return 'views.claim-details.claimStatus.ongoing';

    case ClaimStatus.SOLVED:
      return 'views.claim-details.claimStatus.solved';

    case ClaimStatus.PROCESSING:
      return 'views.claim-details.claimStatus.processing';

    case ClaimStatus.CANCELLED:
      return 'views.claim-details.claimStatus.cancelled';

    default:
      return 'views.claim-details.claimStatus.unknown';
  }
};
