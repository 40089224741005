import { toast, ToastPosition, ToastVariant } from '@tia/react-ui-library';

interface IToastMessage {
  message: string;
  variant: ToastVariant;
  timer?: number;
  position?: ToastPosition;
  pause?: boolean;
  delay?: number;
}

const showToastMessage = ({
  message,
  variant,
  timer = 3000,
  pause = false,
  delay = 0,
  position = ToastPosition.TOP_CENTER,
}: IToastMessage): void => {
  toast.show(message, {
    timeout: timer,
    variant: variant,
    pause: pause,
    delay: delay,
    position: position,
  });
};

export default showToastMessage;
