import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const StyledTiaLogo = styled(NavLink)`
  display: inline-block;
  width: 100%;
  height: 30px;
  margin-top: 20px;
  background-image: ${ThemeUtility.sidebarMenuLogoUrl};
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (min-width: 1025px) {
    display: none;
  }
`;

export const StyledMenuItems = styled.div`
  height: 100%;
  display: flex !important;
  flex-direction: column;
`;

export const MenuItemSpacer = styled.div`
  margin-top: auto;
`;

export const StyledSignOutButton = styled.div`
  margin: 16px;
  justify-content: center;
`;

export const StyledSideBar = styled.div`
  .bm-menu {
    background: #fff;
  }

  .bm-item-list {
    overflow: hidden;
  }

  .bm-overlay {
    background: #fff;
  }
`;
