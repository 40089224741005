import React, { useState } from 'react';
import { Icon, IconName, Toggle } from '@tia/react-ui-library';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { INormalizedCoverGroup } from '../../utils/salesProductSchema';
import { useSalesPolicyProvider } from '../../hooks/useSalesPolicy';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

const getCovered = (coverGroup: INormalizedCoverGroup): string[] => {
  const covered = coverGroup.coverGroupUiProperties.opt.find((o) => o.key === 'covered');

  return covered ? JSON.parse(covered.value) : [];
};

const getNotCovered = (coverGroup: INormalizedCoverGroup): string[] => {
  const covered = coverGroup.coverGroupUiProperties.opt.find((o) => o.key === 'notCovered');

  return covered ? JSON.parse(covered.value) : [];
};

interface IExpandIcon {
  expanded: boolean;
}

export const CoverGroupField: React.FC<{
  coverGroup: INormalizedCoverGroup;
  editable: boolean;
  disableToggle: boolean;
}> = ({ coverGroup, editable, disableToggle }) => {
  const { t } = useTranslation();
  const [expanded, setIsExpanded] = useState<boolean>(false);
  const [covered] = useState(getCovered(coverGroup));
  const [notCovered] = useState(getNotCovered(coverGroup));
  const { updateCoverGroup } = useSalesPolicyProvider();

  const handleOnCoverGroupChange = (value: boolean): void => {
    updateCoverGroup({ ...coverGroup, active: value });
  };

  const expandable = covered.length || notCovered.length;

  const getCoveredDetails = (): JSX.Element | null => {
    if (!covered.length) {
      return null;
    }

    return (
      <>
        <label>{t('views.productDetails.productDetailsCoverGroup.coverGroupField.cover')}</label>
        <div data-testid="covered">
          {covered.map((text, index) => (
            <CoverType key={index}>
              <CoveredBadge>
                <Icon icon={IconName.CHECK} />
              </CoveredBadge>
              <p>{text}</p>
            </CoverType>
          ))}
        </div>
      </>
    );
  };

  const getNotCoveredDetails = (): JSX.Element | null => {
    if (!notCovered.length) {
      return null;
    }

    return (
      <>
        <label>{t('views.productDetails.productDetailsCoverGroup.coverGroupField.doesNotCover')}</label>
        <div data-testid="not-covered">
          {notCovered.map((text, index) => (
            <CoverType key={index}>
              <NotCoveredBadge>
                <Icon icon={IconName.CLOSE} />
              </NotCoveredBadge>
              <p>{text}</p>
            </CoverType>
          ))}
        </div>
      </>
    );
  };

  const coverExpand = (): void => {
    if (!expandable) {
      return;
    }

    setIsExpanded(!expanded);
  };

  return (
    <CoverGroupFieldContainer>
      <CoverGroupHeader expandable={expandable} onClick={coverExpand} data-testid="header">
        <TitleAndIcon>
          {!!expandable && <ExpandIcon icon={IconName.CHEVRON_RIGHT} expanded={expanded} />}
          <HeaderTitle>{coverGroup.coverGroupUiProperties.label}</HeaderTitle>
        </TitleAndIcon>
        {editable && (
          <Toggle
            data-testid="toggle-active"
            disabled={disableToggle}
            onChange={handleOnCoverGroupChange}
            checked={coverGroup.active}
          />
        )}
      </CoverGroupHeader>

      {expanded && (
        <CoverGroupFeaturesContainer>
          {getCoveredDetails()}
          {getNotCoveredDetails()}
        </CoverGroupFeaturesContainer>
      )}
    </CoverGroupFieldContainer>
  );
};

const CoverGroupHeader = styled.div<{ expandable: number }>`
  padding: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: ${ThemeUtility.colorMenuItemHoverBackground};
    cursor: ${({ expandable }): string => (expandable ? 'pointer' : 'default')};
  }
`;

const CoveredBadge = styled.div`
  background-color: ${ThemeUtility.colorSuccess};
  width: 1.4rem;
  min-width: 1.4rem;
  height: 1.4rem;
  margin-right: 1rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
`;

const NotCoveredBadge = styled(CoveredBadge)`
  background-color: ${ThemeUtility.colorError};
`;

const CoverType = styled.div`
  display: flex;
  align-items: center;

  color: rgb(76, 117, 133);
  font-size: 0.8rem;
  font-weight: normal;
  padding-bottom: 1.4rem;
`;

const CoverGroupFieldContainer = styled.div`
  color: ${ThemeUtility.colorBodyText};
  justify-content: space-between;
  position: relative;

  &:not(:last-child)::after {
    content: ' ';
    height: 1px;
    position: absolute;
    left: 1.4rem;
    right: 0;
    bottom: 0;
    background-color: rgb(237, 238, 243);
  }
`;

const CoverGroupFeaturesContainer = styled.div`
  padding: 0 1.4rem 1.4rem 2rem;
  min-width: 100%;
  width: 100%;

  label {
    display: block;
    color: ${ThemeUtility.colorBodyText};
    font-size: 0.7rem;
    font-weight: bold;
    height: 0.9rem;
    letter-spacing: 0.08rem;
    text-transform: uppercase;
    padding-bottom: 1.4rem;
  }
`;

const HeaderTitle = styled.div``;

const ExpandIcon = styled(Icon)<IExpandIcon>`
  padding-right: 0.5rem;
  align-self: center;
  transform: rotate(${({ expanded }): string => (expanded ? '90deg' : '0deg')});
`;

const TitleAndIcon = styled.div`
  display: flex;
`;
