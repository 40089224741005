import { generatePath } from 'react-router-dom';

export enum Routes {
  Callback = '/callback',
  Home = '/home',
  ClaimDetails = '/claim-details',
  Support = '/support',
  Profile = '/profile',
  ProfileInvoices = '/profile/invoices',
  ProfileDetails = '/profile/details',
  Claim = '/claim',
  MakeAClaim = '/make-a-claim',
  Policy = '/:policyStatus(policy|quote)',
  PolicyDetails = '/:policyStatus(policy|quote)/:policyId',
  QuoteEdit = '/:policyStatus(policy|quote)/:policyId/edit',
  QuoteEditGroup = '/quote/:policyId/edit/:groupId',
  Products = '/products',
  ProductOverview = '/products/:productId',
  ProductDetails = '/products/:productId/details',
  ProductDetailsGroup = '/products/:productId/details/:groupId',
  Checkout = '/checkout',
}

enum QueryNames {
  ClaimId = 'claimId',
  PolicyUuid = 'policyUuid',
}

export const getClaimDetailsRoute = (claimId: string): string =>
  `${Routes.ClaimDetails}?${QueryNames.ClaimId}=${claimId}`;

export const getPolicyDetailsRoute = (policyId: string): string =>
  generatePath(Routes.PolicyDetails, { policyId, policyStatus: 'policy' });

export const getQuoteDetailsRoute = (policyId: string): string =>
  generatePath(Routes.PolicyDetails, { policyId, policyStatus: 'quote' });

export const getProductRoute = (productName: string): string =>
  `${Routes.ProductOverview.replace(':productId', productName)}`;

export const getProductDetailsRoute = (productName: string): string =>
  `${Routes.ProductDetails.replace(':productId', productName)}`;

export const getQuoteEditRoute = (policyId: string): string =>
  generatePath(Routes.QuoteEdit, { policyId, policyStatus: 'quote' });

export const getPolicyEditRoute = (policyId: string): string =>
  generatePath(Routes.QuoteEdit, { policyId, policyStatus: 'quote' });

export const getPolicyInitialGroupRoute = (policyId: string): string =>
  generatePath(Routes.QuoteEditGroup, {
    policyId,
    groupId: 'root',
    policyStatus: 'quote',
  });

export const getProductInitialGroupRoute = (productName: string, initialGroupNodeId: string): string =>
  generatePath(Routes.ProductDetailsGroup, {
    productId: productName,
    groupId: initialGroupNodeId,
  });
