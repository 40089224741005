import IPartyNameAndAddress from '../interfaces/IPartyNameAndAddress';
import IParty from '@tia/customer-api-connector/dist/model/IParty';

const getPartyNameAndAddress = (partyData: IParty | undefined): IPartyNameAndAddress => {
  const partyNameAndAddress: IPartyNameAndAddress = {
    name: '',
    street: '',
    streetNo: '',
    postArea: '',
    city: '',
    country: '',
  };

  if (partyData !== undefined && Object.values(partyData).length) {
    partyNameAndAddress.name = partyData.name;
    partyNameAndAddress.street = partyData.street;
    partyNameAndAddress.streetNo = partyData.streetNo;
    partyNameAndAddress.postArea = partyData.postArea;
    partyNameAndAddress.city = partyData.city;
    partyNameAndAddress.country = partyData.country;
  }

  return partyNameAndAddress;
};

export default getPartyNameAndAddress;
