import {
  INormalizedCoverGroup,
  INormalizedGeneralGroups,
  INormalizedObjectInstances,
  INormalizedSalesObject,
  INormalizedSalesProduct,
} from './salesProductSchema';
import { IField } from '@tia/salesproduct-parser/dist/interfaces/ISalesProduct';
import { ICovers } from '../interfaces/ICovers';
import { getGeneralGroupFields, getOptValue } from './salesPolicy';
import { SalesProductOpt } from './salesProductOpt';

export const getSalesProduct = (state: INormalizedSalesProduct): INormalizedSalesObject => {
  return state.salesProduct.root;
};

export const salesProductImageSelector = (state: INormalizedSalesProduct): string => {
  const salesProduct = getSalesProduct(state);
  const optValue = getOptValue(salesProduct.salesProductUiProperties.opt, SalesProductOpt.OPT_IMAGE_KEY);

  return optValue ? optValue : '';
};

export const getFieldById = (state: INormalizedSalesProduct, nodeId: string): IField => {
  return state.fields[nodeId];
};

export const generalGroupsSelector = (state: INormalizedSalesProduct): INormalizedGeneralGroups[] => {
  return Object.values(state.generalGroups);
};

export const objectGroupFieldsSelector = (state: INormalizedSalesProduct, objectGroupNodeId: string): IField[] => {
  const objectGroup = state.objectGroups[objectGroupNodeId];

  const objectInstances = objectGroup.objectInstances.map((id) => state.objectInstances[id]);
  let fields: string[] = [];
  let coverGroups: INormalizedCoverGroup[] = [];

  objectInstances.forEach((oi) => {
    fields = [...fields, ...oi.fields];
    coverGroups = [...coverGroups, ...oi.coverGroups.map((cg) => state.coverGroups[cg])];
  });

  coverGroups.forEach((coverGroup) => {
    fields = [...fields, ...coverGroup.fields];
  });

  return fields.map((id) => state.fields[id]);
};

export const allGeneralGroupFieldsSelector = (state: INormalizedSalesProduct): IField[] => {
  const generalGroups = generalGroupsSelector(state);

  return generalGroups.reduce<IField[]>((previousValue, currentValue) => {
    const fields = getGeneralGroupFields(state, currentValue.nodeID);

    return [...previousValue, ...fields];
  }, []);
};

export const getObjectInstanceById = (state: INormalizedSalesProduct, nodeId: string): INormalizedObjectInstances => {
  return state.objectInstances[nodeId];
};

export const coverGroupSelector = (state: INormalizedSalesProduct, nodeId: string): INormalizedCoverGroup => {
  return state.coverGroups[nodeId];
};

export const coverGroupsSelector = (state: INormalizedSalesProduct): INormalizedCoverGroup[] => {
  return Object.values(state.coverGroups);
};

// TODO name is somewhat not good...
export const getFilteredCoverGroupsSelector = (entities: INormalizedSalesProduct): ICovers => {
  const result: ICovers = {
    includedCovers: [],
    addons: [],
  };

  const coverGroups = coverGroupsSelector(entities);

  coverGroups.forEach((coverGroup) => {
    if (coverGroup.coverGroupUiProperties.visible && coverGroup.coverGroupUiProperties.mandatory) {
      result.includedCovers.push(coverGroup);
    }

    if (coverGroup.coverGroupUiProperties.visible && !coverGroup.coverGroupUiProperties.mandatory) {
      result.addons.push(coverGroup);
    }
  });

  return result;
};
