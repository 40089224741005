import React, { useEffect } from 'react';

const BrandedContentContext = React.createContext<Record<BrandedContentEnum, string> | undefined>(undefined);
const BrandedContentContextProvider = BrandedContentContext.Provider;

export enum BrandedContentEnum {
  CLAIM = 'tia-css-branded-claim',
  MAKE_A_CLAIM = 'tia-css-branded-make-a-claim',
  PRODUCT_VARIANT_ONE = 'tia-css-branded-product-variant-one',
  PRODUCT_VARIANT_TWO = 'tia-css-branded-product-variant-two',
}

export const BrandedContentProvider: React.FC<{ value?: Record<string, string> }> = ({ children, value }) => {
  const widgets = value || window.custEnv.brandedWidgets;

  return <BrandedContentContextProvider value={widgets}>{children}</BrandedContentContextProvider>;
};

/** Lazy loads branded content scripts */
export const useBrandedContentLoader = (type: BrandedContentEnum): void => {
  const brandedContents = React.useContext(BrandedContentContext);

  if (!brandedContents) {
    throw new Error('useBrandedContent is expected to be used within BrandedContentProvider');
  }

  useEffect(() => {
    loadBrandedContent(type, brandedContents[type]);
  }, []);
};

/**
 * A branded content widget fetcher
 **/
const loadBrandedContent = (type: BrandedContentEnum, resourceUrl: string): void => {
  const loadedScripts = document.querySelectorAll<HTMLScriptElement>(`.branded-content-script.${type}`);

  if (loadedScripts.length > 0) {
    // Script has already been fetched
    return;
  }

  if (!resourceUrl) {
    // The script does not exist
    return;
  }

  const brandedContentScript = document.createElement('script');

  brandedContentScript.src = resourceUrl;
  brandedContentScript.type = 'module';
  brandedContentScript.className = `branded-content-script ${type}`;
  document.body.appendChild(brandedContentScript);
};
