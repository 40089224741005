import styled from 'styled-components';

export const FabContainer = styled.div`
  z-index: 9;
  position: fixed;
  right: 56px;
  bottom: 24px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;

  > :not(first-child) {
    margin-top: 1rem;
  }
`;
