import styled from 'styled-components';
import { fadeInKeyframes, fadeOutKeyframes, StyledMainWrapper } from '../../styles/Styled';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const Container = styled(StyledMainWrapper)`
  background: ${ThemeUtility.colorBodyBackground};
  padding: 16px 0 0 0;

  @media (min-width: 1024px) {
    padding: 48px 208px 0 208px;
    max-width: 1440px;
    margin: auto;
  }
`;

/**
 * Since hero component can For hero to be of consistent height we need a spacer
 **/
export const HomeHeroContainerSpacer = styled.div`
  background-color: ${ThemeUtility.colorPanelBackground};
  height: 196px;

  @media (min-width: 768px) {
    height: 276px;
  }
  @media (min-width: 1025px) {
    height: 292px;
  }
`;

export const HomeHeroStickyContainer = styled.div`
  position: sticky;
  top: 0;
  height: 0;
  z-index: 2;

  @media (max-width: 1024px) {
    position: relative;
  }
`;

export const HomeHeroContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  transition: all 350ms;
  height: 196px;

  @media (min-width: 768px) {
    height: 276px;
    &.active {
      height: auto;
    }
  }
  @media (min-width: 1025px) {
    height: 292px;
    &.active {
      height: auto;
    }
  }
`;

export const FixedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${ThemeUtility.colorPanelBackground};
  width: 100%;

  @media (min-width: 1024px) {
    z-index: 1;

    &.active {
      top: 64px;
      height: 80px;
      padding: 0 32px;
      animation: ${fadeInKeyframes} 500ms ease-in-out forwards;

      h1,
      h2,
      div {
        margin: 0;
        padding: 0;
      }
    }

    &.inactive {
      animation: ${fadeOutKeyframes} 500ms ease-in-out forwards;
    }
  }
`;

export const HomeHeroPrimaryText = styled.h1`
  font-weight: 300;
  font-size: 28px;
  color: ${ThemeUtility.colorHeaderText};
  letter-spacing: 0;
  margin: 0 0 8px 16px;
  align-self: baseline;
  white-space: nowrap;

  @media (min-width: 1024px) {
    align-self: center;
    font-size: 32px;
    margin: 0 0 16px 0;
  }
`;

export const HomeHeroSecondaryText = styled.h2`
  font-weight: 400;
  font-size: 16px;
  color: ${ThemeUtility.colorCaptionText};
  opacity: 1;
  align-self: baseline;
  margin: 0 0 0 16px;

  @media (min-width: 1024px) {
    align-self: center;
    text-align: center;
    margin: 0 0 32px 0;

    &.active {
      display: none;
      margin: 0;
      opacity: 0;
    }
  }
`;

export const HomeHeroButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 350ms ease-in-out;
  width: 100%;
  padding: 16px;

  &.active {
    align-self: center;
    justify-content: flex-end;
  }

  a {
    margin: 8px 0;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;

    #heroSecondaryCTA {
      min-width: 208px;
    }
  }
`;
