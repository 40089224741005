import React, { FC, ReactElement } from 'react';
import { IField } from '@tia/salesproduct-parser/dist/interfaces/ISalesProduct';
import DetailsSummary from '../../../components/detailsSummary/DetailsSummary.component';
import { getSortedFields } from '../../../utils/salesPolicy';
import { useSalesPolicyProvider } from '../../../hooks/useSalesPolicy';
import { generalGroupsSelector, getFieldById } from '../../../utils/salesProductSelectors';
import { INormalizedGeneralGroups } from '../../../utils/salesProductSchema';

export const PolicyGeneralGroupDetails: FC = () => {
  const { entities } = useSalesPolicyProvider();
  const generalGroups = generalGroupsSelector(entities);

  const buildDetailsSummaryFromFields = (fields: IField[], title: string, key: number): JSX.Element => {
    const visibleFields = fields.filter((field) => field.fieldUiProperties.visible);
    const sortedFields = getSortedFields(visibleFields);

    return <DetailsSummary key={key} fields={sortedFields} title={title} />;
  };

  const sortedGeneralGroups = getSortedGeneralGroups(generalGroups);

  return (sortedGeneralGroups.map((generalGroup: INormalizedGeneralGroups, index: number) => {
    const fields = generalGroup.fields.map((fieldId) => getFieldById(entities, fieldId));

    return buildDetailsSummaryFromFields(fields, generalGroup.generalGroupUiProperties.label, index);
  }) as unknown) as ReactElement;
};

const getSortedGeneralGroups = (generalGroups: INormalizedGeneralGroups[]): INormalizedGeneralGroups[] =>
  generalGroups.sort((a, b) => a.generalGroupUiProperties.order - b.generalGroupUiProperties.order);
