import styled from 'styled-components';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

interface ITimelineContentContainer {
  active: boolean;
}

interface IContentValue {
  hasTitle: boolean;
}

interface ITimelineContainer {
  isEmpty: boolean;
}

export const NoEventsFound = styled.div`
  color: ${ThemeUtility.colorCaptionText};
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

export const TimelineContainer = styled.div<ITimelineContainer>`
  position: relative;
  margin-bottom: ${({ isEmpty }): string => (isEmpty ? '0' : '17px')};

  &:after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: ${ThemeUtility.colorTimelineBackground};
    top: 30px;
    bottom: 35px;
    margin-left: -3px;
    left: 17px;
  }
`;

export const TimelineContentContainer = styled.div<ITimelineContentContainer>`
  padding: 15px 25px 15px 50px;
  position: relative;
  width: 100%;
  color: ${({ active, theme }): string => (active ? theme.colorBodyText : theme.colorCaptionText)};

  &:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    right: -17px;
    background-color: ${({ active, theme }): string => (active ? theme.colorPrimary : theme.colorPanelBorder)};
    border: 4px solid ${ThemeUtility.colorPanelBackground};
    top: 23px;
    border-radius: 50%;
    z-index: 1;
    left: 6px;
  }
`;

export const ContentTitle = styled.div`
  color: ${ThemeUtility.colorCaptionText};
  font-size: 14px;
  font-weight: normal;
  min-width: 100%;
`;

export const ContentValue = styled.div<IContentValue>`
  font-size: 16px;
  font-weight: bold;
  min-width: 100%;
  padding: ${({ hasTitle }): string => (hasTitle ? '0' : '9px 0')};
`;
