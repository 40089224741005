import React, { useEffect, useState } from 'react';
import { apiConnector } from '../utils/apiConnector';
import userIdentityTypes from '../utils/userIdentityTypes';
import IPortfolio from '@tia/customer-api-connector/dist/model/IPortfolio';

interface ISalesBasketProps {
  loading: boolean;
  isBasketVisible: boolean;
  openBasket: () => void;
  closeBasket: () => void;
  refreshAndOpenBasket: () => void;
  itemCount: number | undefined;
  setItemCount: React.Dispatch<React.SetStateAction<number | undefined>>;
  salesBasket: IPortfolio[] | undefined;
  setSalesBasket: React.Dispatch<React.SetStateAction<IPortfolio[] | undefined>>;
  totalPremium: () => number | undefined;
  currency: () => string | undefined;
  successful: boolean;
}

const SalesBasketContext = React.createContext<ISalesBasketProps>({} as ISalesBasketProps);

export const SalesBasketProvider: React.FC = ({ children }) => {
  const [itemCount, setItemCount] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [salesBasket, setSalesBasket] = useState<IPortfolio[]>();
  const [isBasketVisible, setBasketVisibility] = useState<boolean>(false);
  const [successful, setSuccessful] = useState<boolean>(true);

  const identityType = userIdentityTypes();

  const fetchSalesBasket = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await apiConnector().salesBasket.getBasket(Number(identityType.customer), {
        page: '1',
        size: '100',
      });

      if (response) {
        setSuccessful(response.successful);
        if (successful) {
          setSalesBasket(response.data);
          setItemCount(response.data.length);
        } else {
          setSalesBasket([]);
          setItemCount(0);
        }
      }

      setLoading(false);
    } catch (e) {
      console.error('Could not fetch sales basket', e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSalesBasket();
  }, []);

  const openBasket = (): void => {
    setBasketVisibility(!isBasketVisible);
  };

  const closeBasket = (): void => {
    if (isBasketVisible) {
      setBasketVisibility(!isBasketVisible);
    }
  };

  const refreshAndOpenBasket = (): void => {
    fetchSalesBasket();
    setBasketVisibility(true);
  };

  const totalPremium = (): number | undefined => {
    return salesBasket?.map((b: IPortfolio) => b.totalPremium).reduce((acc, totalPremium) => acc + totalPremium, 0);
  };

  const currency = (): string | undefined => {
    return salesBasket?.length ? salesBasket?.[0].currency : undefined;
  };

  const context = {
    loading,
    salesBasket,
    setSalesBasket,
    itemCount,
    setItemCount,
    isBasketVisible,
    openBasket,
    closeBasket,
    totalPremium,
    currency,
    refreshAndOpenBasket,
    successful,
  };

  return <SalesBasketContext.Provider value={context}>{children}</SalesBasketContext.Provider>;
};

export const useSalesBasketProvider = (): ISalesBasketProps => {
  const context = React.useContext(SalesBasketContext);

  if (context === undefined) {
    throw new Error('useSalesBasketProvider must be used within a SalesBasketProvider');
  }

  return context;
};
