import React, { FC, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getClaimDetailsRoute } from '../router/routes';

interface IProps {
  userId: string;
}

const ClaimsListWidget: FC<IProps> = (props) => {
  const widgetRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const history = useHistory();

  useEffect(() => {
    if (!widgetRef.current) { return; }

    const handleClick = (e: Event): void => {
      const { id } = (e as CustomEvent).detail;

      history.push(getClaimDetailsRoute(id));
    };

    const element = widgetRef.current;
    const eventName = 'on-claim-click';

    element.addEventListener(eventName, handleClick);

    return (): void => {
      element.removeEventListener(eventName, handleClick);
    };
  }, [widgetRef, history]);

  return <div>
    <tia-claim-list-widget ref={widgetRef} userId={props.userId} />
  </div>;
};

export default ClaimsListWidget;
