"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var API_URL = 'v1/parties/me';
exports.getPartyUsingGET = function () {
    return {
        url: "" + API_URL,
        method: 'GET',
    };
};
exports.updateContactDetailsUsingPUT = function (data) {
    return {
        url: API_URL + "/contact-details",
        method: 'PUT',
        data: data,
    };
};
