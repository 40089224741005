import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { getSalesProductRouting } from '../../utils/salesPolicy';
import { ISalesProductRoutingGroup } from '../../interfaces/ISalesProductRouting';
import { useSalesPolicyProvider } from '../../hooks/useSalesPolicy';
import { INormalizedSalesProduct } from '../../utils/salesProductSchema';

interface ISalesProductGroups {
  routeGroups: ISalesProductRoutingGroup[];
  updateGroup: (group: ISalesProductRoutingGroup) => void;
  isGroupEnabled: (group: ISalesProductRoutingGroup) => boolean;
}

export const SalesProductGroupsContext = React.createContext<ISalesProductGroups>({} as ISalesProductGroups);

const getInitialGroups = (
  entities: INormalizedSalesProduct,
  mode: 'CREATE' | 'EDIT',
  t: TFunction
): ISalesProductRoutingGroup[] => {
  if (!entities) {
    return [];
  }

  const salesProductRouting = getSalesProductRouting(entities, t('views.productOverview.coverGroupTitle'));

  salesProductRouting.forEach((group) => {
    if (group.nodeId === 'root') {
      group.isCompleted = true;
    }
  });

  if (mode === 'EDIT') {
    salesProductRouting.forEach((spr) => (spr.isCompleted = true));
  }

  return salesProductRouting;
};

export const useSalesProductGroups = (
  { mode }: { mode: 'CREATE' | 'EDIT' } = { mode: 'CREATE' }
): ISalesProductGroups => {
  const { entities } = useSalesPolicyProvider();
  const { t } = useTranslation();
  const [routeGroups, setRouteGroups] = useState<ISalesProductRoutingGroup[]>(getInitialGroups(entities, mode, t));

  const isGroupEnabled = (routeGroup: ISalesProductRoutingGroup): boolean => {
    const index = routeGroups.findIndex((sg) => sg.nodeId === routeGroup.nodeId);
    const elementsUntilGroup = [...routeGroups].slice(0, index);
    const incompleteStep = elementsUntilGroup.find((sg) => !sg.isCompleted);

    return !incompleteStep;
  };

  const updateGroup = (group: ISalesProductRoutingGroup): void => {
    const clonedGroups = [...routeGroups].map((g) => (g.nodeId === group.nodeId ? group : g));

    setRouteGroups(clonedGroups);
  };

  return {
    routeGroups,
    isGroupEnabled,
    updateGroup,
  };
};
