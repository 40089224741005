import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  BadgeContainer,
  StepBadge,
  StepBody,
  StepContainer,
  StepDescription,
  StepQuestionAndTitle,
  StepTitle,
} from '../Wizard.styled';
import { Button, Icon, IconName } from '@tia/react-ui-library';
import { useHistory, useLocation } from 'react-router';
import { WizardForm } from '../WizardForm.component';
import { WizardFormField } from '../WizardFormField.component';
import { IField, IValidation } from '@tia/salesproduct-parser/dist/interfaces/ISalesProduct';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { IFieldValidationContext, ProductSubGroupsContext } from '../../../views/productDetails/productSubGroupContext';
import { ISubGroup } from '../../../utils/salesPolicy';
import { getFieldById } from '../../../utils/salesProductSelectors';
import { toMathJsExpression } from '@tia/frontend-validation-library/dist/helpers';
import { tiaFrontendValidation } from '@tia/frontend-validation-library';
import { TFunction } from 'i18next';
import { useSalesPolicyProvider } from '../../../hooks/useSalesPolicy';
import { usePolicyNavigation } from '../../../views/policy/PolicyGroupsNavigation.component';

interface IWizardStepProps {
  stepIndex: number;
  stepTitle: string;
  isLastStep: boolean;
  isLastGroup: boolean;
  fields: IField[];
  stepDescription?: string;
  subGroup: ISubGroup;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

interface WizardField {
  field: IField;
  validationFn: (context: IFieldValidationContext) => string | undefined;
  error?: string;
}

export const WizardStep: FC<IWizardStepProps> = ({
  stepIndex,
  stepTitle,
  stepDescription,
  isLastGroup,
  isLastStep,
  subGroup,
  fields = [],
}): JSX.Element => {
  const stepNumber = stepIndex + 1;
  const { navigateToNextGroup } = usePolicyNavigation();
  const { t } = useTranslation();
  const { entities, CompleteButton } = useSalesPolicyProvider();
  const history = useHistory();
  const stepQuery = useQuery().get('step');
  const isActive = `${stepNumber}` === `${stepQuery}` || (stepQuery === null && stepNumber === 1);
  const canCompleteQuote = isLastGroup && isLastStep;
  const { isStepEnabled, updateSubGroup, getValidationContext } = useContext(ProductSubGroupsContext);
  const isEnabled = isStepEnabled(subGroup);
  const fieldValues = fields.map((f) => getFieldById(entities, f.nodeID).value.value);
  const validationContext = useMemo(() => getValidationContext(subGroup), fieldValues);
  const [wizardStepFields, setWizardStepFields] = useState<WizardField[]>(
    initWizardStepFields(fields, validationContext, t)
  );

  useEffect(() => {
    if (!isActive) {
      // Do not validate subtracted steps
      return;
    }

    // Update fields validity
    const fields = [...wizardStepFields].map((wizardStepField) => ({
      ...wizardStepField,
      field: getFieldById(entities, wizardStepField.field.nodeID),
      error: wizardStepField.validationFn(validationContext),
    }));

    updateSubGroup({ ...subGroup, isCompleted: !fields.map((f) => !!f.error).includes(true) });
    setWizardStepFields(fields);
  }, [validationContext, isActive]);

  const navigateToStep = React.useMemo(() => (step: number): void => history.push(`?step=${step}`), [history]);
  const setActive = (): void => {
    if (isActive || !isEnabled) {
      return;
    }

    navigateToStep(stepNumber);
  };

  const handleNextSectionClick = (): void => {
    if (!subGroup.isCompleted) {
      return;
    }

    if (isLastStep) {
      navigateToNextGroup();

      return;
    }

    navigateToStep(stepNumber + 1);
  };

  return (
    <StepContainer onClick={setActive} className={!isActive ? 'closed' : ''}>
      <BadgeContainer>
        <StepBadge className={subGroup.isCompleted && isEnabled ? 'active' : undefined}>
          {subGroup.isCompleted && <Icon icon={IconName.CHECK} />}
          {!subGroup.isCompleted && stepNumber}
        </StepBadge>
      </BadgeContainer>
      <StepBody>
        <StepQuestionAndTitle>
          <div>
            <StepTitle>{stepTitle}</StepTitle>
            <StepDescription>{stepDescription || ''}</StepDescription>
          </div>
        </StepQuestionAndTitle>
        {isActive && (
          <div>
            <WizardForm>
              {wizardStepFields.map(({ field, error }) => (
                <WizardFormField key={field.nodeID} field={field} error={error} />
              ))}
            </WizardForm>
            <ButtonContainer>
              {!canCompleteQuote && (
                <Button onClick={handleNextSectionClick} disabled={!subGroup.isCompleted} data-testid={'button-next'}>
                  {t('components.wizard.step.next')}
                </Button>
              )}
              {canCompleteQuote && CompleteButton && <CompleteButton enabled={subGroup.isCompleted} />}
            </ButtonContainer>
          </div>
        )}
      </StepBody>
    </StepContainer>
  );
};

const ButtonContainer = styled.div`
  padding-top: 1rem;
`;

export const createValidationFn: (
  field: IField,
  translateFn: TFunction
) => (context: IFieldValidationContext) => string | undefined = (field, translateFn) => {
  const fieldValidations = (((field.fieldUiProperties.validations as never) as { validateOnAPI: boolean }[]).filter(
    (v: { validateOnAPI: boolean }) => !v.validateOnAPI
  ) as never) as IValidation[];
  const validations: Array<(context: IFieldValidationContext) => string | undefined> = [];

  if (field.fieldUiProperties.mandatory) {
    validations.push((context: IFieldValidationContext) => {
      const isFalsyValue = typeof context[field.id] === 'undefined' || context[field.id] === null;

      if (isFalsyValue || `${context[field.id]}`.length === 0) {
        return translateFn('components.wizard.errors.fieldIsRequired');
      }
    });
  }

  fieldValidations.forEach((validation) => {
    const validationString = toMathJsExpression(JSON.parse(validation.validation));
    const validationFn = tiaFrontendValidation.parse(validationString).evaluate;

    validations.push((context: IFieldValidationContext) => {
      const isValid = validationFn(context);

      if (!isValid) {
        return validation.message;
      }
    });
  });

  return (validationContext: IFieldValidationContext): string | undefined => {
    let error: string | undefined = undefined;

    for (let i = 0; i < validations.length; i++) {
      const validationFn = validations[i];

      try {
        error = validationFn(validationContext);
      } catch (e) {
        error = e.message;
      }

      if (error) {
        break;
      }
    }

    return error;
  };
};

const initWizardStepFields = (
  fields: IField[],
  validationContext: IFieldValidationContext,
  translateFn: TFunction
): WizardField[] => {
  return fields.map((field: IField) => {
    const validationFn = createValidationFn(field, translateFn);

    return {
      field,
      validationFn: validationFn,
      error: validationFn(validationContext),
    };
  });
};
