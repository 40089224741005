import styled from 'styled-components';
import { StyledMainWrapper } from '../../styles/Styled';
import { Link } from 'react-router-dom';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

interface IClaimHeroText {
  padding: string;
  fontSize: string;
}

export const Container = styled(StyledMainWrapper)`
  background: ${ThemeUtility.colorBodyBackground};
`;

export const StyledLink = styled(Link)`
  width: calc(100% - 24px);
  text-decoration: none;

  @media screen and (min-width: 1025px) {
    display: flex;
    justify-content: center;
  }
`;

export const MobileView = styled.div`
  @media screen and (min-width: 1025px) {
    display: none;
  }
`;

export const DesktopView = styled.div`
  display: none;

  @media screen and (min-width: 1025px) {
    display: flex;
    flex-direction: column;
  }
`;

export const SubPageHeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px 0;
  background: ${ThemeUtility.colorPanelBackground};
  margin: 0 0 64px 0;
`;

export const ClaimHeroHeadline = styled.h1`
  color: ${ThemeUtility.colorHeaderText};
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

export const ClaimHeroText = styled.p<IClaimHeroText>`
  color: ${ThemeUtility.colorCaptionText};
  text-align: center;
  font-weight: 400;

  padding: ${({ padding }): string => padding};
  color: ${({ fontSize }): string => fontSize};
`;

export const FloatingContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 84px;
  width: 100%;
`;
