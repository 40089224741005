import CjdApiConnector from '@tia/cjd-api-connector';
import { getLanguage } from './language';

let _apiConnector: CjdApiConnector | undefined;

export const cjdApiConnector = (): CjdApiConnector => {
  if (_apiConnector) {
    return _apiConnector;
  }

  const baseUrl = localStorage.getItem('cjd_endpoint');

  if (!baseUrl) {
    throw Error('No base-url found.');
  }

  _apiConnector = new CjdApiConnector('cjd_access_token', baseUrl);
  _apiConnector.setLanguage(getLanguage());

  return _apiConnector;
};
