"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fetchRequest_1 = require("./fetchRequest");
var UrlParam_1 = require("../helpers/UrlParam");
var salesPortfolio_1 = require("../api/salesPortfolio");
var SalesPortfolioConnector = /** @class */ (function () {
    function SalesPortfolioConnector(baseUrl) {
        this.baseUrl = baseUrl;
    }
    SalesPortfolioConnector.prototype.getPortfolio = function (partyId, apiParams) {
        var params = new URLSearchParams();
        UrlParam_1.appendDefaultParams(params, apiParams);
        return fetchRequest_1.fetchRequest(salesPortfolio_1.getPortfolioUsingGET(partyId, params), this.baseUrl);
    };
    return SalesPortfolioConnector;
}());
exports.default = SalesPortfolioConnector;
