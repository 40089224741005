"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var API_URL = 'v1/sales-products';
exports.getSalesProductByNameUsingGET = function (productName) {
    return {
        url: API_URL + "/" + productName,
        method: 'GET',
    };
};
exports.getListOfSalesProductNamesUsingGET = function () {
    return {
        url: API_URL + "/names",
        method: 'GET',
    };
};
