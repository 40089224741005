import React, { Fragment, useMemo } from 'react';
import { SummaryDetailRowTitle, SummaryDetailRowValue } from './DetailsSummary.styled';
import Details from '../details/Details.component';
import { DetailRowContainer, DetailRowSeparator } from '../details/Details.styled';
import { IField } from '@tia/salesproduct-parser/dist/interfaces/ISalesProduct';
import { WizardFormField } from '../wizard/WizardFormField.component';
import { ESalesPolicyProviderMode, useSalesPolicyProvider } from '../../hooks/useSalesPolicy';
import { getFieldById } from '../../utils/salesProductSelectors';
import {
  getGeneralGroups,
  getGeneralGroupSubGroups,
  getObjectGroups,
  getObjectGroupSubGroups,
} from '../../utils/salesPolicy';
import { useTranslation } from 'react-i18next';
import { createValidationFn } from '../wizard/WizardStep/WizardStep.component';
import styled from 'styled-components';

interface IProps {
  title: string;
  details?: IDetail[];
  fields?: IField[];
}

export interface IDetail {
  title: string;
  text: string;
}

const DetailsSummary: React.FunctionComponent<IProps> = ({ title, details, fields }) => {
  const getSummaryDetails = (): JSX.Element[] => {
    if (details) {
      return (details || []).map((detail, index) => (
        <Fragment key={index}>
          <SimpleDetailRow detail={detail} />
          {fields?.length !== index + 1 && <DetailRowSeparator />}
        </Fragment>
      ));
    }

    return (fields || []).map((field, index) => (
      <Fragment key={index}>
        <FieldDetailRow field={field} />
        {fields?.length !== index + 1 && <DetailRowSeparator />}
      </Fragment>
    ));
  };

  return <Details title={title}>{getSummaryDetails()}</Details>;
};

const SimpleDetailRow: React.FC<{ detail: IDetail }> = ({ detail }) => {
  return (
    <DetailRowContainer>
      <SummaryDetailRowTitle>{detail.title}</SummaryDetailRowTitle>
      <SummaryDetailRowValue>{detail.text}</SummaryDetailRowValue>
    </DetailRowContainer>
  );
};

const FieldDetailRow: React.FC<{ field: IField }> = ({ field }) => {
  const { mode } = useSalesPolicyProvider();
  const isMtaEditable = field.fieldUiProperties.mtaEditAble;

  const isEditable = mode === ESalesPolicyProviderMode.EDIT && isMtaEditable;

  return (
    <DetailRowContainer>
      <SummaryDetailRowTitle>{field.fieldUiProperties.label}</SummaryDetailRowTitle>
      <SummaryDetailRowValue>
        {isEditable && <MtaEditableField field={field} />}
        {!isEditable && <FieldValueWrapper>{getFieldValue(field)}</FieldValueWrapper>}
      </SummaryDetailRowValue>
    </DetailRowContainer>
  );
};

const MtaEditableField: React.FC<{ field: IField }> = (props) => {
  const { entities } = useSalesPolicyProvider();
  const { t } = useTranslation();
  const [objectGroupContexts, generalGroupContexts] = useMemo(() => {
    const generalGroups = getGeneralGroups(entities);
    const objectGroups = getObjectGroups(entities);
    const ogSubGroups = objectGroups.map((og) => getObjectGroupSubGroups(entities, og.nodeID)).flat(1);
    const ggSubGroups = generalGroups.map((gg) => getGeneralGroupSubGroups(entities, gg.nodeID)).flat(1);

    return [ogSubGroups, ggSubGroups];
  }, [entities]);

  const validationFn = useMemo(() => createValidationFn(props.field, t), []);

  const validationGroupFields = useMemo(() => {
    const generalGroupContext = generalGroupContexts.find((contextGroup) =>
      contextGroup.subGroupFields.find((f) => f.nodeID === props.field.nodeID)
    );

    if (generalGroupContext) {
      return [generalGroupContext.subGroupFields].flat(1).map((f) => f.nodeID);
    }

    const objectGroupContext = objectGroupContexts.find((contextGroup) =>
      contextGroup.subGroupFields.find((f) => f.nodeID === props.field.nodeID)
    );

    if (objectGroupContext) {
      return [objectGroupContext.subGroupFields, ...generalGroupContexts.map((c) => c.subGroupFields)]
        .flat(1)
        .map((f) => f.nodeID);
    }

    throw 'Could not find context group';
  }, [objectGroupContexts, generalGroupContexts]);

  const validationContext = useMemo(() => {
    const context: { [key: string]: number | boolean | string } = {};

    validationGroupFields
      .map((nodeId) => getFieldById(entities, nodeId))
      .forEach((f) => {
        context[f.id] = f.value.value;
      });

    return context;
  }, [validationGroupFields, entities]);

  const error = useMemo(() => {
    return validationFn(validationContext);
  }, [validationContext]);

  return <WizardFormField {...props} error={error} useLabel={false} />;
};

export default DetailsSummary;

const FieldValueWrapper = styled.div`
  text-align: right;
`;

const getFieldValue = (field: IField): string => {
  if ((field.fieldUiProperties.lov || []).length > 0) {
    const lovValue = field.fieldUiProperties.lov.find((listValue) => listValue.value.value === field.value.value);

    return lovValue?.label || field.value.value;
  }

  return field.value.value;
};
