import jwtDecode from 'jwt-decode';
import { IIdToken, IIdentity } from '../interfaces/IIdToken.interface';

const jwtToken = (): IIdToken | undefined => {
  const token: string | null = localStorage.getItem('id_token');

  if (token) {
    return jwtDecode(token);
  }
};

const userIdentityTypes = (): { [key: string]: string } => {
  const token = jwtToken();

  if (token && token['https://tiatechnology.com/identities']) {
    return token['https://tiatechnology.com/identities'].reduce(
      (acc: { [key: string]: string }, identity: IIdentity) => {
        const identityType = identity.type.toLowerCase();

        if (identityType === 'customer') {
          acc[identityType] = identity.user_id;
        }

        return acc;
      },
      {}
    );
  }

  return {};
};

export default userIdentityTypes;
