import styled from 'styled-components';
import { fadeInKeyframes, fadeOutKeyframes, StyledMainWrapper } from '../../styles/Styled';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const Container = styled(StyledMainWrapper)`
  background: ${ThemeUtility.colorBodyBackground};
`;

interface IDetailsHeroContainer {
  backgroundUrl: string;
}

export const DetailsHeroDesktopButtonsContainer = styled.div`
  display: flex;
  margin: 0 -0.2rem;

  button {
    margin: 0 0.2rem;
  }
`;

export const DetailsHeroMobileButtonsContainer = styled.div`
  button:not(:first-child) {
    margin-top: 0.4rem;
  }
`;

export const DetailsDesktopHeroContainer = styled.div<IDetailsHeroContainer>`
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 56px 0;
    background: url("${({ backgroundUrl }): string => backgroundUrl}");
    background-color: ${ThemeUtility.colorPanelBackground};
    box-shadow: inset 0px -1px 0px 0px rgb(237, 238, 243);
    margin: 0 0 64px 0;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 342px;

    &:before {
      content: '';
      position: absolute;
      background-image: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
      height:100%;
      width:100%;
      z-index: 0;
    }
  }
`;

export const MobileImage = styled.div<IDetailsHeroContainer>`
  background: url("${({ backgroundUrl }): string => backgroundUrl}");
  background-size: cover;
  background-position: center;
  position: relative;
  height: ${({ backgroundUrl }): string => (backgroundUrl ? '163px' : '24px')};

  &:before {
    content: '';
    position: absolute;
    background-image: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
    height:100%;
    width:100%;
    z-index: 0;
  }
`;

export const DetailsMobileContainer = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const GeneralInfoContainer = styled.div`
  padding-bottom: 16px;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1172px;
    padding: 0 80px 0 24px;

    &.active {
      flex-direction: row;
      justify-content: space-between;
      align-self: center;
      margin: 0 auto;
      max-width: 100%;
    }
  }
`;

export const DetailsHeroHeadline = styled.h1`
  color: ${ThemeUtility.colorBodyText};
  font-size: 11px;
  font-weight: bold;
  height: 14px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const DetailsHeroText = styled.p`
  color: ${ThemeUtility.colorHeaderText};
  font-size: 32px;
  font-weight: 300;
  height: 40px;
  letter-spacing: 0;
`;

/**
 * Container which moves the content down a bit, so it gives the overlapping effect.
 */
export const DesktopDetailsOverlapContainer = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    transform: translateY(-45%);
  }
`;

export const DetailsContainer = styled.div`
  background: ${ThemeUtility.colorPanelBackground};
  border-radius: 8px;
  border: 1px solid rgb(226, 229, 234);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  height: 104px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  @media (max-width: 767px) {
    flex-wrap: wrap;
    height: auto;
    border: 0;

    &:first-child {
      border: 0;
    }
    &:last-child {
      border: 0;
    }
  }
`;

export const Detail = styled.div`
  flex-direction: column;
  width: 33.33%;
  flex-grow: 1;
  text-align: center;
  @media (max-width: 767px) {
    flex-basis: 100%;
    padding: 24px 0;
    display: flex;
    justify-content: center;
    border-top: 1px solid rgb(237, 238, 243);
    border-bottom: 1px solid rgb(237, 238, 243);
  }
`;

export const DetailHeader = styled.div`
  color: ${ThemeUtility.colorCaptionText};
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const DetailText = styled.div`
  color: ${ThemeUtility.colorHeaderText};
  font-size: 22px;
  font-weight: normal;
  padding-top: 6px;
`;

export const HeroMobileView = styled.div`
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const HeroDesktopView = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

export const HeroStickyContainer = styled.div`
  display: none;

  @media (min-width: 1024px) {
    position: relative;
    top: 0;
    height: 0;
    display: flex;
  }
`;

export const DetailsHeroContainer = styled.div<{ backgroundUrl: string }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  transition: all 350ms;
  height: 0px;

  @media (min-width: 1024px) {
    background: url("${({ backgroundUrl }): string => backgroundUrl}");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 276px;

    &::before {
      content: "";
      position: absolute;
      background-image: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
      height: 100%;
      width: 100%;
      z-index: 0;
    }
  }
`;

export const FixedHeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  width: 100%;

  @media (min-width: 1024px) {
    z-index: 1;

    &.active {
      top: 64px;
      height: 80px;
      padding: 0 32px;
      position: fixed;
      background: ${ThemeUtility.colorPanelBackground};
      animation: ${fadeInKeyframes} 500ms ease-in-out forwards;

      h1,
      h2,
      div {
        margin: 0;
        padding: 0;
      }
    }

    &.inactive {
      position: relative;
      animation: ${fadeOutKeyframes} 500ms ease-in-out forwards;
    }
  }
`;
