import React, { FC, Dispatch, useEffect, SetStateAction } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import IMenuItems from '../../interfaces/IMenuItems';
import MenuItems from '../menuItem/MenuItems.Component';
import Menu from 'react-burger-menu/lib/menus/push';
import { Button, ButtonColor, Icon, IconName } from '@tia/react-ui-library';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../router/routes';
import { MenuItemSpacer, StyledMenuItems, StyledSideBar, StyledSignOutButton, StyledTiaLogo } from './Styled';
import { logout } from '../../utils/authUtils';

interface IProps extends RouteComponentProps {
  menuItems: IMenuItems[];
  isMobileMenuVisible: boolean;
  setMobileVisibility: Dispatch<SetStateAction<boolean>>;
}

const Sidebar: FC<IProps> = ({ menuItems, isMobileMenuVisible, setMobileVisibility }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const handleWindowResize = (): void => {
      if (window.innerWidth > 1024) {
        setMobileVisibility(false);
      }
    };

    window.addEventListener('resize', handleWindowResize);

    return (): void => window.removeEventListener('resize', handleWindowResize);
  }, [setMobileVisibility]);

  return (
    <StyledSideBar>
      <Menu
        disableAutoFocus
        customBurgerIcon={false}
        customCrossIcon={false}
        width={'240px'}
        isOpen={isMobileMenuVisible}
        onClose={(): void => setMobileVisibility(!isMobileMenuVisible)}
        pageWrapId={'page-wrap'}
      >
        <StyledMenuItems>
          <StyledTiaLogo to={Routes.Home} onClick={(): void => setMobileVisibility(!isMobileMenuVisible)} />
          <MenuItems
            menuItems={menuItems}
            isMobileMenuVisible={isMobileMenuVisible}
            setMobileVisibility={setMobileVisibility}
          />
          <MenuItemSpacer />
          <StyledSignOutButton>
            <Button
              icon={<Icon icon={IconName.EXIT_TO_APP} size={24} />}
              color={ButtonColor.SECONDARY}
              onClick={logout}
              block={true}
            >
              {t('menu.buttons.singOut')}
            </Button>
          </StyledSignOutButton>
        </StyledMenuItems>
      </Menu>
    </StyledSideBar>
  );
};

export default withRouter(Sidebar);
