import styled from 'styled-components';
import { CardContainer } from '../card/Card.styled';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const StepContainer = styled.div`
  display: flex;
  padding: 1rem;

  &.closed:hover {
    background-color: ${ThemeUtility.colorMenuItemHoverBackground};
    cursor: pointer;
  }
`;

export const BadgeContainer = styled.div`
  padding-right: 1rem;
`;

export const StepBadge = styled.div`
  color: ${ThemeUtility.colorAlternativeCaptionText};
  border: 1px solid ${ThemeUtility.colorAlternativeCaptionText};

  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transition: all 250ms;

  &.active {
    background-color: ${ThemeUtility.colorSuccess};
    border-color: ${ThemeUtility.colorSuccess};
    color: white;
  }
`;

export const StepBody = styled.div`
  flex-grow: 1;
`;

export const StepQuestionAndTitle = styled.div`
  flex-grow: 1;
  flex-direction: column;
`;

export const StepDescription = styled.p`
  color: ${ThemeUtility.colorCaptionText};
  font-size: 14px;
  font-weight: normal;
  padding-top: 0.5rem;
`;

export const StepTitle = styled.p`
  width: 100%;
  height: 14px;
  color: rgb(0, 40, 59);
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.4px;
`;

export const WizardContainer = styled(CardContainer)``;

export const StepDivider = styled.div`
  height: 1px;
  background: ${ThemeUtility.colorDividerBackground};
  border-radius: 0px;
`;
