import PaymentStatus from '@tia/customer-api-connector/dist/model/PaymentStatus';

export const getPaymentStatus = (status: string): { statusKey: string; color: string } => {
  switch (status) {
    case PaymentStatus.SUBMITTED:
      return { statusKey: 'views.profile.invoice.paymentStatus.submitted', color: 'orange' };

    case PaymentStatus.PAID:
      return { statusKey: 'views.profile.invoice.paymentStatus.paid', color: 'green' };

    case PaymentStatus.DECLINED:
      return { statusKey: 'views.profile.invoice.paymentStatus.declined', color: 'red' };

    case PaymentStatus.UNKNOWN:
      return { statusKey: 'views.profile.invoice.paymentStatus.unknown', color: 'red' };

    default:
      return { statusKey: 'views.profile.invoice.paymentStatus.unknown', color: 'red' };
  }
};
