"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fetchRequest_1 = require("./fetchRequest");
var party_1 = require("../api/party");
var PartyConnector = /** @class */ (function () {
    function PartyConnector(baseUrl) {
        this.baseUrl = baseUrl;
    }
    PartyConnector.prototype.getParty = function () {
        return fetchRequest_1.fetchRequest(party_1.getPartyUsingGET(), this.baseUrl);
    };
    PartyConnector.prototype.updateContactDetails = function (data) {
        return fetchRequest_1.fetchRequest(party_1.updateContactDetailsUsingPUT(data), this.baseUrl);
    };
    return PartyConnector;
}());
exports.default = PartyConnector;
