import { TiaCustomerSelfServicePortalThemeVars } from './ThemeUtility';

export const PrimaryAppTheme: TiaCustomerSelfServicePortalThemeVars = {
  colorPrimary: 'var(--tia-color-primary,  #ff9181)',
  colorError: 'var(--tia-color-error, #f02b43)',
  colorSuccess: 'var(--tia-color-success, #26c775)',

  colorCaptionText: 'var(--tia-color-caption-text, #929aa8)',
  colorAlternativeCaptionText: 'var(--tia-color-caption-text, #929aa8)',
  colorPrimaryText: 'var(--tia-color-primary-text, #ffffff)',

  colorBodyText: 'var(--tia-color-body-text, #405e6c)',
  colorBodyBackground: 'var(--tia-color-body-bg, #fafbfe)',

  colorPanelBackground: 'var(--tia-panel-bg, #ffffff)',
  colorPanelBorder: 'var(--tia-panel-border, #e2e5ea)',

  sidebarMenuLogoUrl: 'var(--tia-sidebar-logo-url, url("media/tia-logo.svg"))',
  topMenuLogoWidth: 'var(--tia-top-menu-logo-width, 50px)',
  topMenuLogoUrl: 'var(--tia-top-menu-logo-url, media/tia-logo.svg)',

  divider: 'var(--tia-divider, #edeef3)',

  fontFamily: 'var(--tia-font-family, "Muli")',

  colorHeaderText: 'var(--tia-color-header-text, #00283b)',

  colorFABHelpBackground: 'var(--tia-color-fab-help-background, #3654ab)',
  colorFABHelpText: 'var(--tia-color-fab-help-text, white)',

  colorChatBubbleBackground: 'var(--tia-message-user-bubble-bg-color, #3654ab)',
  colorChatBubbleText: 'var(--tia-message-user-bubble-color, #ffffff)',

  colorChatResponseBubbleText: 'var(--tia-message-response-bubble-color, #4e505e)',
  colorChatResponseBubbleBackground: 'var(--tia-message-response-bubble-bg-color, #ffffff)',

  colorTimelineBackground: 'var(--tia-color-timeline-background, #d5ecf1)',

  colorMenuItemText: 'var(--tia-menu-item-color, #8db2bf)',
  colorMenuItemBackground: 'var(--tia-menu-item-bg, transparent)',
  colorMenuItemHoverBackground: 'var(--tia-menu-item-hover, #f3f4f7)',

  profileHeroBackgroundUrl: 'var(--tia-profile-hero-url, url(media/my-profile-hero-image.jpg))',
  basketEmptyBackgroundUrl: 'var(--tia-ghost-icon-url, url(media/ghost-icon.png))',

  /** The following properties should not be exposed for customer  */
  brandedWidgetHeight: 'var(--tia-branded-widget-height, 200px)',
};
