import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { Routes } from '../../router/routes';
import { Icon, IconName } from '@tia/react-ui-library';
import styled from 'styled-components';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const HelpFAB: FC = (): JSX.Element => {
  const history = useHistory();
  const handleFabClick = React.useCallback(() => history.push(Routes.Support), [history]);

  return (
    <HelpFabButton onClick={handleFabClick}>
      <Icon icon={IconName.QUESTION_ANSWER} />
    </HelpFabButton>
  );
};

const HelpFabButton = styled.div`
  color: ${ThemeUtility.colorFABHelpText};
  background: ${ThemeUtility.colorFABHelpBackground};
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
  height: 52px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: box-shadow 250ms;
  font-size: 24px;

  &:hover {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.13);
  }
`;
