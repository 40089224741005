"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["SUBMITTED"] = "SUBMITTED";
    PaymentStatus["DECLINED"] = "DECLINED";
    PaymentStatus["PAID"] = "PAID";
    PaymentStatus["UNKNOWN"] = "UNKNOWN";
})(PaymentStatus || (PaymentStatus = {}));
exports.default = PaymentStatus;
