import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClaimHeroHeadline,
  ClaimHeroText,
  Container,
  DesktopView,
  FloatingContainer,
  MobileView,
  StyledLink,
  SubPageHeroContainer,
} from './Styled';
import { Routes } from '../../router/routes';
import { StyledAppContainer } from '../../styles/Styled';
import { Button, ButtonSize, Icon, IconName } from '@tia/react-ui-library';
import { BrandedContentEnum, useBrandedContentLoader } from '../../hooks/useBrandedContent';
import { BrandedContent } from '../../components/brandedContent/BrandedContent.component';

const Claim: FC = (): JSX.Element => {
  useBrandedContentLoader(BrandedContentEnum.CLAIM);

  const { t } = useTranslation();

  return (
    <Container>
      <MobileView>
        <StyledAppContainer>
          <ClaimHeroText fontSize={'14px'} padding={'28px 0'}>
            {t('views.claim.heroMessage')}
          </ClaimHeroText>
          <BrandedContent type={BrandedContentEnum.CLAIM} />
        </StyledAppContainer>

        <FloatingContainer>
          <StyledLink to={Routes.MakeAClaim}>
            <Button icon={<Icon icon={IconName.LIBRARY_BOOKS} />} size={ButtonSize.X_LARGE} block={true}>
              {t('views.claim.callToActionButton')}
            </Button>
          </StyledLink>
        </FloatingContainer>
      </MobileView>
      <DesktopView>
        <SubPageHeroContainer>
          <ClaimHeroHeadline>{t('views.claim.heroTitle')}</ClaimHeroHeadline>
          <ClaimHeroText fontSize={'16px'} padding={'16px 0 40px 0'}>
            {t('views.claim.heroMessage')}
          </ClaimHeroText>
          <StyledLink to={Routes.MakeAClaim}>
            <Button icon={<Icon icon={IconName.LIBRARY_BOOKS} />} size={ButtonSize.LARGE}>
              {t('views.claim.callToActionButton')}
            </Button>
          </StyledLink>
        </SubPageHeroContainer>

        <div>
          <StyledAppContainer>
            <BrandedContent type={BrandedContentEnum.CLAIM} />
          </StyledAppContainer>
        </div>
      </DesktopView>
    </Container>
  );
};

export default Claim;
