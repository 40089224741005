import React, { FC, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getPolicyDetailsRoute, getQuoteDetailsRoute } from '../router/routes';
import { PolicyStatus } from '@tia/salesproduct-parser/dist/interfaces/Enums';

interface IProps {
  partyId: string;
}

const PolicyQuotesWidget: FC<IProps> = (props) => {
  const widgetRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const history = useHistory();

  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    const handleClick = (e: Event): void => {
      const policyDetail: { policyStatus: string; uuid: string } = (e as CustomEvent).detail;

      if (policyDetail.policyStatus === PolicyStatus.Quote) {
        history.push(getQuoteDetailsRoute(policyDetail.uuid));
      } else {
        history.push(getPolicyDetailsRoute(policyDetail.uuid));
      }
    };

    const element = widgetRef.current;
    const eventName = 'on-policy-click';

    element.addEventListener(eventName, handleClick);

    return (): void => {
      element.removeEventListener(eventName, handleClick);
    };
  }, [widgetRef, history]);

  return <tia-policy-quotes-widget ref={widgetRef} partyId={props.partyId} />;
};

export default PolicyQuotesWidget;
