"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var fetchRequest_1 = require("./fetchRequest");
var salesPolicy_1 = require("../api/salesPolicy");
var UrlParam_1 = require("../helpers/UrlParam");
var SalesPoliciesConnector = /** @class */ (function () {
    function SalesPoliciesConnector(baseUrl) {
        this.baseUrl = baseUrl;
    }
    /**
     * Get sales policy by UUID
     * @param uuid
     */
    SalesPoliciesConnector.prototype.getSalesPolicyByUuid = function (uuid) {
        return fetchRequest_1.fetchRequestWithPagination(salesPolicy_1.getSalesPolicyByUuidUsingGET(uuid), this.baseUrl);
    };
    /**
     * Create a new Sales Product
     * @param data
     */
    SalesPoliciesConnector.prototype.createSalesPolicy = function (data) {
        return fetchRequest_1.fetchRequestWithPagination(salesPolicy_1.createSalesPolicyUsingPOST(data), this.baseUrl);
    };
    /**
     * Set a Sales Product in force given uuid
     * @param uuid
     */
    SalesPoliciesConnector.prototype.setSalesPolicyInForce = function (uuids) {
        return fetchRequest_1.fetchRequestWithPagination(salesPolicy_1.stSalesPolicyInForceUsingPUT(uuids), this.baseUrl);
    };
    /**
     * Calculate premium from a provided Sales Product
     * @param data
     */
    SalesPoliciesConnector.prototype.calculatePremium = function (data) {
        return fetchRequest_1.fetchRequestWithPagination(salesPolicy_1.calculatePremiumUsingPUT(data), this.baseUrl);
    };
    /**
     * Update sales policy for the provided Sales Product
     * @param uuid
     */
    SalesPoliciesConnector.prototype.updateSalesPolicy = function (data) {
        return fetchRequest_1.fetchRequestWithoutPage(salesPolicy_1.updateSalesPolicyUsingPUT(data), this.baseUrl);
    };
    /**
     *
     * @param spdUuid spd UUID of the sales policy that should be canceled
     * @param date date for when the policy should be canceled, if set as undefined date will be set to today.
     */
    SalesPoliciesConnector.prototype.cancelSalesPolicy = function (spdUuid, date) {
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = new URLSearchParams();
                        if (date) {
                            UrlParam_1.appendParam(params, 'date', date.toISOString().split('T')[0]);
                        }
                        return [4 /*yield*/, fetchRequest_1.fetchRequestWithoutPage(salesPolicy_1.cancelSalesPolicyUsingDELETE(spdUuid, params), this.baseUrl)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return SalesPoliciesConnector;
}());
exports.default = SalesPoliciesConnector;
