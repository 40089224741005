import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, ButtonColor, ButtonSize, Icon, IconName } from '@tia/react-ui-library';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../router/routes';
import { useHistory } from 'react-router-dom';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

const Checkout: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleHomeClick = (): void => history.push(Routes.Home);

  return (
    <CheckoutWrapper>
      <CheckoutContainer>
        <CheckContainer>
          <Icon icon={IconName.CHECK} />
        </CheckContainer>
        <ConfirmationMessage> {t('views.checkout.confirmationMessage')}</ConfirmationMessage>
        <CommunicationMessage> {t('views.checkout.communicationMessage')}</CommunicationMessage>
        <Button onClick={handleHomeClick} size={ButtonSize.LARGE} color={ButtonColor.PRIMARY}>
          {t('views.checkout.home')}
        </Button>
      </CheckoutContainer>
    </CheckoutWrapper>
  );
};

export default Checkout;

const CheckoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15rem;
`;

export const CheckContainer = styled.div`
  box-shadow: 0rem 0.0625rem 0.375rem 0rem rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transition: all 250ms;

  background-color: ${ThemeUtility.colorSuccess};
  border-color: ${ThemeUtility.colorSuccess};
  color: white;
`;

const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 45rem;
  height: 17.8125rem;
  width: 100%;
`;

const ConfirmationMessage = styled.div`
  width: 100%;
  height: 2.0625rem;
  color: ${ThemeUtility.colorHeaderText};
  font-size: 1.625rem;
  font-weight: 300;
  text-align: center;
  letter-spacing: 0rem;
`;

const CommunicationMessage = styled.div`
  width: 100%;
  height: 1.25rem;
  color: ${ThemeUtility.colorBodyText}
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
`;
