import React from 'react';
import { Container, DetailsContainer, DetailTitle, DetailTitleSeparator } from './Details.styled';

interface IProps {
  title: string;
  children: JSX.Element | JSX.Element[];
}

const Details: React.FunctionComponent<IProps> = ({ title, children }): JSX.Element => {
  return (
    <Container style={{ marginTop: '24px', padding: '0' }}>
      <DetailsContainer>
        <DetailTitle>{title}</DetailTitle>
        <DetailTitleSeparator />
        {children}
      </DetailsContainer>
    </Container>
  );
};

export default Details;
