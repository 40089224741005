import styled from 'styled-components';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const SummaryDetailRowTitle = styled.div`
  color: ${ThemeUtility.colorCaptionText};
  font-weight: bold;
  letter-spacing: 1.27px;
  text-transform: uppercase;
  font-size: 10px;
  flex: 1;
`;

export const SummaryDetailRowValue = styled.div`
  color: ${ThemeUtility.colorBodyText};
  font-size: 14px;
  font-weight: 600;
  flex: 1;
  padding: 9px 0;
`;
