import React, { FC, useEffect } from 'react';
import { getObjectGroupSubGroups } from '../../../utils/salesPolicy';
import { Wizard } from '../../../components/wizard/Wizard.component';
import { ISalesProductRoutingGroup } from '../../../interfaces/ISalesProductRouting';
import { ProductSubGroupsContext, useProductSubGroups } from '../productSubGroupContext';
import { useSalesPolicyProvider } from '../../../hooks/useSalesPolicy';

interface IProps {
  objectGroup: ISalesProductRoutingGroup;
}

const ProductDetailsObjectGroup: FC<IProps> = ({ objectGroup }): JSX.Element | null => {
  const { entities } = useSalesPolicyProvider();

  const productSubGroups = useProductSubGroups(objectGroup);
  const { subGroups, setSubGroups } = productSubGroups;

  useEffect(() => {
    if (!entities) {
      return;
    }

    const subGroups = getObjectGroupSubGroups(entities, objectGroup.nodeId);

    subGroups.forEach((subGroup) => {
      subGroup.isCompleted = objectGroup.isCompleted;
    });

    setSubGroups(subGroups);
  }, []);

  if (!subGroups) {
    return null;
  }

  return (
    <ProductSubGroupsContext.Provider value={productSubGroups}>
      <Wizard groupId={objectGroup.nodeId} />
    </ProductSubGroupsContext.Provider>
  );
};

export default ProductDetailsObjectGroup;
