import React, { FC, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getClaimDetailsRoute, Routes } from '../router/routes';
import { getLanguage } from '../utils/language';

enum Events {
  SuccessHomeButton = 'success-home-button-click-event',
  SuccessDetailsButton = 'success-details-button-click-event',
}

interface IProps {
  claimId?: number;
  stageId?: number;
  userId?: string;
}

const NextQuestionWidget: FC<IProps> = ({ claimId, stageId, userId }) => {
  const widgetRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const history = useHistory();

  const handleHomeClick = (): void => history.push(Routes.Home);
  const handleDetailsClick = (e: CustomEventInit<{ claimId: string }>): void => {
    if (e.detail) {
      history.push(getClaimDetailsRoute(e.detail.claimId));
    }
  };

  useEffect(() => {
    const element = widgetRef.current;

    if (widgetRef.current) {
      element.addEventListener(Events.SuccessHomeButton, handleHomeClick);
      element.addEventListener(Events.SuccessDetailsButton, handleDetailsClick);

      return (): void => {
        element.removeEventListener(Events.SuccessHomeButton, handleHomeClick);
        element.removeEventListener(Events.SuccessDetailsButton, handleDetailsClick);
      };
    }
  });

  const cjdLanguage = getLanguage() === 'tia' ? 'en' : getLanguage();

  return (
    <tia-claim-next-question-widget
      style={{ outline: 0 }}
      ref={widgetRef}
      claim-id={claimId}
      stage-id={stageId}
      user-id={userId}
      mobile="true"
      language={cjdLanguage}
    />
  );
};

export default NextQuestionWidget;
