import { Route, Switch } from 'react-router-dom';
import { Routes } from '../../router/routes';
import React from 'react';
import { PolicyDetailsView } from '../policyDetails/PolicyDetails.view';
import { PolicyEdit } from './PolicyEdit.component';

export const PolicyRouter: React.FC = () => {
  return (
    <Switch>
      <Route path={Routes.PolicyDetails} component={PolicyDetailsView} exact />
      <Route path={Routes.QuoteEdit} component={PolicyEdit} />
    </Switch>
  );
};
