import React from 'react';
import { FileDetailRowTitle, FileDetailRowValue, NoFilesFound } from './ClaimFileDetails.styled';
import { DetailRowContainer, DetailRowSeparator } from '../../../components/details/Details.styled';
import Details from '../../../components/details/Details.component';
import { Icon, IconName } from '@tia/react-ui-library';

interface IProps {
  title: string;
  files: number[];
  fileDownload: (fileId: number) => void;
  noFilesFoundMessage: string;
}

const ClaimFileDetails: React.FunctionComponent<IProps> = ({
  files,
  title,
  noFilesFoundMessage,
  fileDownload,
}): JSX.Element => {
  const getFileDetails = (): JSX.Element | JSX.Element[] => {
    const fileDetailsLength = files?.length || 0;

    if (fileDetailsLength === 0) {
      return (
        <DetailRowContainer>
          <NoFilesFound>{noFilesFoundMessage}</NoFilesFound>
        </DetailRowContainer>
      );
    }

    return files.map((element, index) => {
      const title = `File Id: ${element}`;

      const onClick = (): void => fileDownload(element);

      if (fileDetailsLength === index + 1) {
        return (
          <DetailRowContainer key={index}>
            <FileDetailRowTitle>{title}</FileDetailRowTitle>
            <FileDetailRowValue onClick={onClick}>
              <Icon color={'#929AA8'} icon={IconName.CLOUD_DOWNLOAD}></Icon>
            </FileDetailRowValue>
          </DetailRowContainer>
        );
      }

      return (
        <>
          <DetailRowContainer key={index}>
            <FileDetailRowTitle>{title}</FileDetailRowTitle>
          </DetailRowContainer>
          <DetailRowSeparator></DetailRowSeparator>
        </>
      );
    });
  };

  return <Details title={title}>{getFileDetails()}</Details>;
};

export default ClaimFileDetails;
