import styled from 'styled-components';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const FileDetailRowTitle = styled.div`
  color: ${ThemeUtility.colorBodyText};
  font-size: 14px;
  font-weight: bold;
`;

export const FileDetailRowValue = styled.div`
  cursor: pointer;
`;

export const NoFilesFound = styled.div`
  color: ${ThemeUtility.colorCaptionText};
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;
