import React, { FC, useContext } from 'react';
import { StepDivider, WizardContainer } from './Wizard.styled';
import { WizardStep } from './WizardStep/WizardStep.component';
import { ProductSubGroupsContext } from '../../views/productDetails/productSubGroupContext';
import { SalesProductGroupsContext } from '../../views/productDetails/salesProductGroups';
interface IWizardProps {
  groupId: string;
}
export const Wizard: FC<IWizardProps> = ({ groupId }): JSX.Element => {
  const { routeGroups } = useContext(SalesProductGroupsContext);
  const isLastGroup = !!routeGroups.length && routeGroups[routeGroups.length - 1].nodeId === groupId;
  const { subGroups } = useContext(ProductSubGroupsContext);

  return (
    <WizardContainer>
      {subGroups.map((subGroup, index) => (
        <div key={subGroup.nodeId}>
          <WizardStep
            stepIndex={index}
            subGroup={subGroup}
            fields={subGroup.subGroupFields}
            stepTitle={subGroup.stepTitle}
            stepDescription={subGroup.stepQuestion}
            isLastStep={subGroups.length - 1 === index}
            isLastGroup={isLastGroup}
          />
          <StepDivider />
        </div>
      ))}
    </WizardContainer>
  );
};
