import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, ButtonColor, ButtonSize } from '@tia/react-ui-library';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../router/routes';
import { useHistory } from 'react-router-dom';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

interface INoSupportProps {
  message: string;
}

const NoSupport: FC<INoSupportProps> = ({ message }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleHomeClick = (): void => history.push(Routes.Home);

  return (
    <NoSupportWrapper>
      <NoSupportMessage> {message}</NoSupportMessage>
      <Button onClick={handleHomeClick} size={ButtonSize.LARGE} color={ButtonColor.SECONDARY}>
        {t('views.checkout.home')}
      </Button>
    </NoSupportWrapper>
  );
};

export default NoSupport;

const NoSupportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4.5rem;
`;

const NoSupportMessage = styled.div`
  color: ${ThemeUtility.colorBodyText}
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  min-width: 314px;
  margin: 24px 30.5px;
`;
