"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var accessTokenKey = '';
var language = '';
/**
 * Set accessToken Key
 * @param key
 */
exports.setAccessTokenKey = function (key) {
    accessTokenKey = key;
};
/**
 * Set language header
 * @param lang
 */
exports.setLanguageHeader = function (lang) {
    if (lang) {
        language = lang;
    }
};
/**
 * Try to get access token from localStorage
 * Throw error if attempt fails
 */
var tryGetAccessToken = function () {
    var accessToken = localStorage.getItem(accessTokenKey);
    if (!accessTokenKey) {
        throw Error('Access token has not been set');
    }
    if (!accessToken) {
        throw Error("No access token found for key: " + accessTokenKey);
    }
    return accessToken;
};
/**
 * Base request for the Api Connector
 * @param request
 * @param BASE_URL
 */
var fetchRequestBase = function (request, BASE_URL) {
    var accessToken = tryGetAccessToken();
    var req = fetch("" + BASE_URL + request.url, {
        method: request.method,
        body: request.data ? JSON.stringify(request.data) : null,
        headers: {
            Authorization: "Bearer " + accessToken,
            'Content-Type': 'application/json',
            'Content-Language': language,
        },
    });
    return req;
};
/**
 * Fetch a binary file for download
 * @param request
 * @param BASE_URL
 */
exports.fetchBinary = function (request, BASE_URL) { return __awaiter(void 0, void 0, void 0, function () {
    var baseRequest;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchRequestBase(request, BASE_URL)];
            case 1:
                baseRequest = _a.sent();
                return [4 /*yield*/, baseRequest.blob()];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); };
/**
 * Fetch including pagination
 * @param request
 * @param BASE_URL
 */
exports.fetchRequestWithPagination = function (request, BASE_URL) { return __awaiter(void 0, void 0, void 0, function () {
    var baseRequest, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchRequestBase(request, BASE_URL)];
            case 1:
                baseRequest = _a.sent();
                return [4 /*yield*/, baseRequest.json()];
            case 2:
                response = _a.sent();
                return [2 /*return*/, { data: response.data, page: response.page, successful: response.successful, errors: response.errors }];
        }
    });
}); };
/**
 * Fetch unwrapped data
 * @param request
 * @param BASE_URL
 */
exports.fetchRequest = function (request, BASE_URL) { return __awaiter(void 0, void 0, void 0, function () {
    var baseRequest, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchRequestBase(request, BASE_URL)];
            case 1:
                baseRequest = _a.sent();
                return [4 /*yield*/, baseRequest.json()];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response.data];
        }
    });
}); };
exports.fetchRequestWithoutPage = function (request, BASE_URL) { return __awaiter(void 0, void 0, void 0, function () {
    var baseRequest, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchRequestBase(request, BASE_URL)];
            case 1:
                baseRequest = _a.sent();
                return [4 /*yield*/, baseRequest.json()];
            case 2:
                response = _a.sent();
                return [2 /*return*/, { data: response.data, successful: response.successful, errors: response.errors }];
        }
    });
}); };
