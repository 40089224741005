export enum EObjectGroup {
  GeneralGroup = 'generalGroup',
  ObjectGroup = 'objectGroup',
  CoverGroup = 'coverGroup',
}

export interface ISalesProductRoutingGroup {
  order: number;
  title: string;
  nodeId: string;
  isCompleted: boolean;
  type: EObjectGroup.GeneralGroup | EObjectGroup.ObjectGroup | EObjectGroup.CoverGroup;
}
