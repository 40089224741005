import React, { FC, ReactText, useEffect, useMemo } from 'react';
import { IField } from '@tia/salesproduct-parser/dist/interfaces/ISalesProduct';
import { ETypeInput, Icon, IconName, Input, Option, RadioGroup, Select, Tooltip } from '@tia/react-ui-library';
import styled from 'styled-components';
import { ESalesPolicyProviderMode, useSalesPolicyProvider } from '../../hooks/useSalesPolicy';
import { handleParse } from '../../utils/dateFormatter';
import { createErrorController, useSalesPolicyErrors } from '../../hooks/useSalesPolicyErrors';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

interface IWizardFormField {
  field: IField;
  error?: string;
  useLabel?: boolean;
}

export const WizardFormField: FC<IWizardFormField> = ({ field, error, useLabel = true }) => {
  const { updateFieldValue, mode } = useSalesPolicyProvider();
  const { registerController, unregisterController } = useSalesPolicyErrors();
  const errorController = React.useMemo(() => createErrorController(error), [error]);
  const helpText = field.fieldUiProperties.helpText;

  useEffect(() => {
    registerController(errorController);

    return (): void => unregisterController(errorController);
  }, [errorController]);

  const inputComponent = useMemo(() => {
    const handleOnFieldChange = (value: ReactText): void => {
      updateFieldValue(field.nodeID, value as string);
    };

    if (field.fieldUiProperties.lov.length > 0) {
      const inputType = field.fieldUiProperties.opt.find((v) => v.key === 'inputType');

      /** When mode is edit it means we are editing policy details, which does not radio inputs for now */
      if (mode === ESalesPolicyProviderMode.VIEW && inputType && inputType.value === 'radio') {
        return <RadioInputNode field={field} onValueChange={handleOnFieldChange} />;
      }

      return <SelectNode field={field} onValueChange={handleOnFieldChange} />;
    }

    return <InputNode field={field} onValueChange={handleOnFieldChange} />;
  }, [field, updateFieldValue]);

  if (!field.fieldUiProperties.visible) {
    return null;
  }

  return (
    <FormFieldContainer>
      {useLabel && (
        <FieldLabel>
          {field.fieldUiProperties.label}
          {helpText && (
            <StyledTooltip
              placement={'right'}
              tooltipText={<TooltipText>{helpText}</TooltipText>}
              data-testid={'help-text'}
            >
              <Icon icon={IconName.INFO} size={'1rem'} />
            </StyledTooltip>
          )}
        </FieldLabel>
      )}
      {inputComponent}
      {error && <FieldError>{error}</FieldError>}
    </FormFieldContainer>
  );
};

const StyledTooltip = styled(Tooltip)`
  margin-left: 0.3rem;
`;

const FormFieldContainer = styled.div`
  min-width: 50%;
  padding-left: var(--input-group-padding);
  padding-right: var(--input-group-padding);
`;

const TooltipText = styled.div`
  max-width: 20rem;
  width: max-content;
`;

const FieldError = styled.div`
  padding-bottom: 0.9rem;
  color: ${ThemeUtility.colorError};
  font-size: 13px;
`;

const FieldLabel = styled.div`
  color: ${ThemeUtility.colorCaptionText};
  font-size: 14px;
  font-weight: 600;
  padding: 0.3rem 0;
`;

const InputNode: React.FC<{ field: IField; onValueChange: (value: ReactText) => void }> = ({
  field,
  onValueChange,
}) => {
  let type;
  let value: string | undefined = field.value.value;

  switch (field.fieldUiProperties.dataType.toLowerCase()) {
    case 'number':
      type = ETypeInput.number;
      break;

    case 'date':
      type = ETypeInput.date;
      value = handleParse(value);
      break;

    default:
      type = ETypeInput.text;
  }

  return (
    <Input
      readOnly={field.fieldUiProperties.readonly}
      style={{ width: '100%' }}
      value={value}
      onChange={onValueChange}
      type={type}
    />
  );
};

const RadioInputNode: React.FC<{ field: IField; onValueChange: (value: string) => void }> = ({
  field,
  onValueChange,
}) => {
  const options = field.fieldUiProperties.lov.map((lovValue) => ({ key: lovValue.value.value, value: lovValue.label }));
  const handleOnChange = ({ key }: { key: string }): void => onValueChange(key);

  return (
    <RadioGroup
      defaultKey={field.value.value}
      onRadioChange={handleOnChange}
      disabled={field.fieldUiProperties.readonly}
      radioSize={'default' as never}
      options={options}
    />
  );
};

const SelectNode: React.FC<{ field: IField; onValueChange: (value: string) => void }> = ({ field, onValueChange }) => {
  const options = field.fieldUiProperties.lov.map((lovValue) => ({
    value: lovValue.value.value,
    label: lovValue.label,
  }));

  const handleOnChange = (value: string): void => onValueChange(value);

  return (
    <Select disabled={field.fieldUiProperties.readonly} allowClear onChange={handleOnChange} value={field.value.value}>
      {options.map((o) => (
        <Option key={o.value} value={o.value}>
          {o.label}
        </Option>
      ))}
    </Select>
  );
};
