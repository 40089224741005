import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { getProductDetailsRoute } from '../../router/routes';
import { EObjectGroup } from '../../interfaces/ISalesProductRouting';
import { useParams } from 'react-router';
import ProductDetailsGeneralGroup from './components/ProductDetailsGeneralGroup.component';
import ProductDetailsObjectGroup from './components/ProductDetailsObjectGroup.component';
import { ProductDetailsCoverGroupAndPrice } from './components/CoverGroup/ProductDetailsCoverGroupAndPrice.component';
import { SalesProductGroupsContext } from './salesProductGroups';
import { usePolicyNavigation } from '../policy/PolicyGroupsNavigation.component';

export const ProductDetailRouter: React.FC<{ fallbackUrl?: string }> = ({ fallbackUrl }) => {
  const match = useRouteMatch<{ productId: string }>();
  const { groupId, getActiveGroup } = usePolicyNavigation();
  const activeGroup = React.useMemo(() => {
    try {
      return getActiveGroup();
    } catch (e) {
      return null;
    }
  }, [groupId]);

  if (!activeGroup) {
    return <Redirect to={fallbackUrl || ''} />;
  }

  return (
    <Switch>
      <Route path={`${match.path}/:groupId`} component={GroupRenderer} />
    </Switch>
  );
};

const GroupRenderer: React.FC = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const { routeGroups } = useContext(SalesProductGroupsContext);
  const match = useRouteMatch<{ productId: string }>();

  const group = React.useMemo(() => routeGroups.find((g) => g.nodeId === groupId), [groupId, routeGroups]);

  if (!group) {
    return <Redirect from={`${match.path}`} to={getProductDetailsRoute(match.params.productId)} />;
  }

  switch (group.type) {
    case EObjectGroup.CoverGroup:
      return <ProductDetailsCoverGroupAndPrice />;

    case EObjectGroup.ObjectGroup:
      return <ProductDetailsObjectGroup objectGroup={group} />;

    case EObjectGroup.GeneralGroup:
      return <ProductDetailsGeneralGroup generalGroup={group} />;

    default:
      console.error('UNDEFINED TYPE FOR GROUP', group);
      throw Error('Invalid type');
  }
};
