import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const StyledProductLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 800;
  font-size: 11px;
  color: ${ThemeUtility.colorAlternativeCaptionText};
  letter-spacing: 1.4px;
  text-decoration: none;
  text-transform: uppercase;
  margin: 20px;

  &.active {
    color: ${ThemeUtility.colorPrimary};
  }

  &.disabled {
    pointer-events: none;
  }
`;

export const StyledProductCircle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${ThemeUtility.colorAlternativeCaptionText};
  width: 32px;
  height: 32px;
  border: 1px solid ${ThemeUtility.colorAlternativeCaptionText};
  border-radius: 50%;
  margin: 0 16px 0 0;

  &.is-enabled.completed {
    background-color: ${ThemeUtility.colorSuccess};
    border-color: ${ThemeUtility.colorSuccess};
    color: white;
  }

  &.active {
    color: ${ThemeUtility.colorPrimaryText};
    border-color: ${ThemeUtility.colorPrimary};
    background: ${ThemeUtility.colorPrimary};
  }
`;

export const StyledProductMenu = styled.div`
  display: flex;
  flex-direction: row;
`;
