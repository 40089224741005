import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DesktopView, MobileView, StyledAppContainer } from '../../styles/Styled';
import {
  ProductCardContainer,
  ProductCards,
  ProductDescription,
  ProductHeadline,
  ProductHeroContainer,
  ProductLink,
  StyledCard,
  CardImageOverlay,
  NoCardsContainer,
} from './Products.styled';
import ISalesProductName from '@tia/customer-api-connector/dist/model/ISalesProductName';
import { CardContent, CardDescription, CardTitle, Spinner, Icon, IconName } from '@tia/react-ui-library';
import { getSalesProductNameImage } from '../../utils/salesPolicy';
import { useSalesProductStore } from '../../hooks/salesProducts';
import { getProductRoute } from '../../router/routes';
import NoSupport from '../../components/noSupport/NoSupport.component';

interface IProductCard {
  salesProduct: ISalesProductName;
}

const Products: FC = () => {
  const { t } = useTranslation();
  const { salesProductsData, loading } = useSalesProductStore();

  const renderCards = (): JSX.Element | JSX.Element[] => {
    if (loading) {
      return (
        <div>
          <Spinner size={20} />
        </div>
      );
    }

    if (salesProductsData.length === 0) {
      return (
        <NoCardsContainer>
          <Icon icon={IconName.DESCRIPTION} size={32} />
          {t('views.product.noProducts')}
        </NoCardsContainer>
      );
    }

    return (
      <ProductCards>
        {salesProductsData.map((salesProduct: ISalesProductName) => (
          <ProductCard key={salesProduct.name} salesProduct={salesProduct} />
        ))}
      </ProductCards>
    );
  };

  return (
    <StyledAppContainer>
      <DesktopView>
        <ProductHeroContainer>
          <ProductHeadline> {t('views.product.heroTitle')}</ProductHeadline>
          <ProductDescription>{t('views.product.description')}</ProductDescription>
        </ProductHeroContainer>
        {renderCards()}
      </DesktopView>
      <MobileView>
        <NoSupport message={t('views.noSupport.mobile.message')} />
      </MobileView>
    </StyledAppContainer>
  );
};

export const ProductCard: React.FC<IProductCard> = ({ salesProduct }) => {
  const background = useMemo(() => getSalesProductNameImage(salesProduct), [salesProduct]);

  return (
    <ProductLink style={{ display: 'contents' }} to={getProductRoute(salesProduct.name)} key={salesProduct.name}>
      <ProductCardContainer>
        <StyledCard background={background} salt={salesProduct.name}>
          {background && <CardImageOverlay />}
          <CardContent>
            <CardTitle>{salesProduct.label}</CardTitle>
            <CardDescription>{salesProduct.description}</CardDescription>
          </CardContent>
        </StyledCard>
      </ProductCardContainer>
    </ProductLink>
  );
};

export default Products;
