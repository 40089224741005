import React from 'react';
import styled from 'styled-components';
import { CoverGroupField } from './CoverGroupField.component';
import { useTranslation } from 'react-i18next';
import { SalesProductOpt } from '../../utils/salesProductOpt';
import { getOptValue } from '../../utils/salesPolicy';
import { getFilteredCoverGroupsSelector, getSalesProduct } from '../../utils/salesProductSelectors';
import { useSalesPolicyProvider } from '../../hooks/useSalesPolicy';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const CoverGroups: React.FC<{
  disableToggle: boolean;
  renderToggle: boolean;
}> = ({ renderToggle, disableToggle, ...rest }) => {
  const { t } = useTranslation();
  const { entities } = useSalesPolicyProvider();

  const salesProduct = getSalesProduct(entities);
  const filteredCoverGroups = getFilteredCoverGroupsSelector(entities);
  const sortedAddonCovers = filteredCoverGroups.addons.sort(
    (a, b) => a.coverGroupUiProperties.order - b.coverGroupUiProperties.order
  );
  const sortedIncludedCovers = filteredCoverGroups.includedCovers.sort(
    (a, b) => a.coverGroupUiProperties.order - b.coverGroupUiProperties.order
  );

  const includedCoverGroupDescription = getOptValue(
    salesProduct.salesProductUiProperties.opt,
    SalesProductOpt.INCLUDED_COVERS_DESCRIPTION
  );

  const addonCoverGroupDescription = getOptValue(
    salesProduct.salesProductUiProperties.opt,
    SalesProductOpt.ADDON_COVERS_DESCRIPTION
  );

  return (
    <CoverGroupWrapper {...rest}>
      {!!sortedIncludedCovers.length && (
        <CoverGroupContainer data-testid="included-covers">
          <CoverGroupHeader>
            <h3>{t('views.productDetails.productDetailsCoverGroup.coverGroupIncludedHeader')}</h3>
            <p>
              {includedCoverGroupDescription ||
                t('views.productDetails.productDetailsCoverGroup.coverGroupIncludedHeaderDescription')}
            </p>
          </CoverGroupHeader>
          {sortedIncludedCovers.map((cg) => (
            <CoverGroupField key={cg.nodeID} coverGroup={cg} editable={false} disableToggle={disableToggle} />
          ))}
        </CoverGroupContainer>
      )}
      {!renderToggle && <StyledSectionBreaker />}
      {!!sortedAddonCovers.length && (
        <CoverGroupContainer data-testid="add-on-covers">
          <CoverGroupHeader>
            <h3>{t('views.productDetails.productDetailsCoverGroup.coverGroupAddonsHeader')}</h3>
            <p>
              {addonCoverGroupDescription ||
                t('views.productDetails.productDetailsCoverGroup.coverGroupAddonsHeaderDescription')}
            </p>
          </CoverGroupHeader>
          {sortedAddonCovers.map((cg) => (
            <CoverGroupField key={cg.nodeID} coverGroup={cg} editable={renderToggle} disableToggle={disableToggle} />
          ))}
        </CoverGroupContainer>
      )}
    </CoverGroupWrapper>
  );
};

const CoverGroupHeader = styled.div`
  padding: 1rem;
  border-bottom: 1px rgb(237, 238, 243) solid;

  h3 {
    color: ${ThemeUtility.colorHeaderText};
    margin-bottom: 0.4rem;
    font-size: 1.2rem;
    font-weight: 800;
    height: 1.3rem;
    letter-spacing: 0;

    @media (max-width: 767px) {
      font-size: 0.7rem;
      font-weight: bold;
      letter-spacing: 1.4px;
      text-transform: uppercase;
    }
  }

  p {
    color: ${ThemeUtility.colorCaptionText};
    font-size: 0.8rem;
    font-weight: 600;
    height: 1.1rem;
  }
`;

const StyledSectionBreaker = styled.div`
  width: 100%;
  max-width: 36px;
`;

const CoverGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CoverGroupContainer = styled.div`
  background: ${ThemeUtility.colorPanelBackground};
  border: 1px solid #e2e5ea;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-bottom: 1.1rem;

  @media (max-width: 767px) {
    border-radius: 0px;
    border: 0;
    box-shadow: inset 0px 1px 0px 0px rgb(237, 238, 243), inset 0px -1px 0px 0px rgb(237, 238, 243);
  }
`;
