import React from 'react';
import {
  DesktopDetailsOverlapContainer,
  Detail,
  DetailHeader,
  DetailsContainer,
  DetailsDesktopHeroContainer,
  DetailsHeroDesktopButtonsContainer,
  DetailsHeroHeadline,
  DetailsHeroMobileButtonsContainer,
  DetailsHeroText,
  DetailsMobileContainer,
  DetailText,
  GeneralInfoContainer,
  MobileImage,
} from './DetailsHero.styled';
import { MobileView, StyledAppContainer } from '../../styles/Styled';
import { Button, ButtonSize, Spinner } from '@tia/react-ui-library';

interface IProps {
  details: IDetail[];
  subTitle: string;
  title: string;
  image: string;
  loading?: boolean;
}

interface IDetail {
  title: string;
  text: string;
}

export const DetailsHeroAction: React.FC<React.ComponentProps<typeof Button> & {
  wrapperElement?: React.FC;
  'data-testid'?: string;
}> = (props) => {
  const { wrapperElement, ...buttonProps } = props;
  const WrapperElement = wrapperElement || 'span';
  const dataTestId = props['data-testid'];

  return (
    <WrapperElement data-testid={dataTestId}>
      <Button size={ButtonSize.LARGE} {...buttonProps} />
    </WrapperElement>
  );
};

const DetailsHero: React.FC<IProps> = ({ image, details, title, subTitle, children }): JSX.Element => {
  const detailElements = details.map((element, index) => (
    <Detail key={index}>
      <DetailHeader>{element.title}</DetailHeader>
      <DetailText>{element.text ? element.text : <Spinner size={20} />}</DetailText>
    </Detail>
  ));

  const actions = React.useMemo(() => {
    const filteredActionButtons = React.Children.toArray(children)
      .filter((child) => child instanceof Object)
      .filter((child) => {
        return (child as JSX.Element).type.name === DetailsHeroAction.name;
      });

    return filteredActionButtons;
  }, [children]);

  const mobileActions = actions.map((button: React.ReactNode) =>
    React.cloneElement(button as never, {
      size: ButtonSize.X_LARGE,
      block: true,
    })
  ) as JSX.Element[];

  return (
    <>
      <MobileView>
        <DetailsMobileContainer>
          <MobileImage backgroundUrl={image} />
          <GeneralInfoContainer>
            <DetailsHeroText>{title}</DetailsHeroText>
            <DetailsHeroHeadline>{subTitle}</DetailsHeroHeadline>
            <div style={{ height: '24px', width: '100%' }} />
            <DetailsHeroMobileButtonsContainer>{mobileActions}</DetailsHeroMobileButtonsContainer>
          </GeneralInfoContainer>
        </DetailsMobileContainer>
      </MobileView>

      <DetailsDesktopHeroContainer backgroundUrl={image}>
        <GeneralInfoContainer>
          <DetailsHeroHeadline>{subTitle}</DetailsHeroHeadline>
          <DetailsHeroText>{title}</DetailsHeroText>
          <DetailsHeroDesktopButtonsContainer>{actions}</DetailsHeroDesktopButtonsContainer>
        </GeneralInfoContainer>
        <DesktopDetailsOverlapContainer>
          <StyledAppContainer paddingTop="0" paddingBottom="0">
            <DetailsContainer>{detailElements}</DetailsContainer>
          </StyledAppContainer>
        </DesktopDetailsOverlapContainer>
      </DetailsDesktopHeroContainer>
    </>
  );
};

export default DetailsHero;
