import React, { useEffect, useMemo, useState } from 'react';
import { INormalizedCoverGroup, INormalizedSalesProduct } from '../utils/salesProductSchema';
import { ButtonSize } from '@tia/react-ui-library';
import { SalesPolicyErrorProvider } from './useSalesPolicyErrors';

export enum ESalesPolicyProviderMode {
  VIEW,
  EDIT,
}

interface ISalesPolicyProvider {
  entities: INormalizedSalesProduct;
  updateFieldValue: (fieldId: string, value: string) => void;
  updateCoverGroup: (cover: INormalizedCoverGroup) => void;
  mode: ESalesPolicyProviderMode;
  CompleteButton?: React.FC<{ enabled: boolean; size?: ButtonSize; block?: boolean }>;
}

const SalesPolicyContext = React.createContext<ISalesPolicyProvider | undefined>(undefined as never);

/**
 * Provides sales policy/product for children components
 */
export const SalesPolicyProvider: React.FC<{
  entities?: INormalizedSalesProduct;
  mode?: ESalesPolicyProviderMode;

  /** Method is triggered when object should be created or updated */
  completeButton?: React.FC<{ enabled: boolean; size?: ButtonSize; block?: boolean; mode?: ESalesPolicyProviderMode }>;
}> = ({ children, mode = ESalesPolicyProviderMode.VIEW, entities, completeButton }) => {
  const [normalizedData, setNormalizedData] = useState<INormalizedSalesProduct>(undefined as never);

  const updateFieldValue = (fieldId: string, value: string): void => {
    if (!normalizedData) {
      return;
    }

    const field = {
      ...normalizedData.fields[fieldId],
      value: {
        ...normalizedData.fields[fieldId].value,
        value,
      },
    };

    setNormalizedData({
      ...normalizedData,
      fields: {
        ...normalizedData.fields,
        [field.nodeID]: field,
      },
    });
  };

  const updateCoverGroup = (coverGroup: INormalizedCoverGroup): void => {
    if (!normalizedData) {
      return;
    }

    setNormalizedData({
      ...normalizedData,
      coverGroups: {
        ...normalizedData.coverGroups,
        [coverGroup.nodeID]: { ...coverGroup },
      },
    });
  };

  const context = useMemo(
    () => ({
      entities: normalizedData,
      updateFieldValue,
      updateCoverGroup,
      CompleteButton: completeButton,
      mode: mode || ESalesPolicyProviderMode.VIEW,
    }),
    [normalizedData, mode]
  );

  useEffect(() => {
    setNormalizedData(entities as INormalizedSalesProduct);
  }, [entities]);

  if (!context.entities) {
    return null;
  }

  return (
    <SalesPolicyErrorProvider>
      <SalesPolicyContext.Provider value={context}>{children}</SalesPolicyContext.Provider>
    </SalesPolicyErrorProvider>
  );
};

/**
 * Provides interaction with sales policy/product provided by SalesPolicyProvider
 */
export const useSalesPolicyProvider = (): ISalesPolicyProvider => {
  const context = React.useContext(SalesPolicyContext);

  if (context === undefined) {
    throw new Error('useSalesPolicyProvider must be used within a SalesPolicyProvider');
  }

  return context;
};
