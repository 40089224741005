import React from 'react';
import styled from 'styled-components';
import { ProductDetailsCoverPrice } from './ProductDetailsCoverPrice.component';
import { CoverGroups } from '../../../../components/coverGroups/CoverGroups.component';

export const ProductDetailsCoverGroupAndPrice: React.FC = () => {
  return (
    <CoverGroupContainer>
      <CoverGroupColumn>
        <CoverGroupsInColumn renderToggle={true} disableToggle={false} />
      </CoverGroupColumn>
      <CoverGroupColumn>
        <ProductDetailsCoverPrice />
      </CoverGroupColumn>
    </CoverGroupContainer>
  );
};

const CoverGroupContainer = styled.div`
  display: flex;
  margin: 0 -1rem;
`;

const CoverGroupColumn = styled.div`
  width: 100%;
  margin: 0 1rem;
`;

const CoverGroupsInColumn = styled(CoverGroups)`
  flex-direction: column;
`;
