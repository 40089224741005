import { useEffect, useState } from 'react';
import { createStore } from 'reusable';
import { cjdApiConnector } from '../utils/cjdApiConnector';
import IClaim from '@tia/cjd-api-connector/dist/models/IClaim';
import IFileResponse from '@tia/cjd-api-connector/dist/interfaces/IFileResponse';

export const useClaimStore = createStore(() => {
  const [claimId, setClaimId] = useState<number>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSuccessful, setSuccessful] = useState<boolean>(true);
  const [claimData, setClaimData] = useState<IClaim>();

  useEffect(() => {
    if (claimId) {
      const fetchClaimData = async (): Promise<void> => {
        setLoading(true);
        try {
          const claimResponse = await cjdApiConnector().claim.getClaim(claimId);

          if (claimResponse) {
            setClaimData(claimResponse.data);
            setSuccessful(claimResponse.successful);
          }
        } catch (e) {
          console.error('Could not fetch users', e);
        }

        setLoading(false);
      };

      fetchClaimData();
    }
  }, [claimId]);

  return {
    claimData,
    isLoading,
    setClaimId,
    isSuccessful,
  };
});

export const useClaimFileStore = createStore(() => {
  const [claimFileId, setClaimFileId] = useState<number>();
  const [isClaimFileLoading, setClaimFileLoading] = useState<boolean>(false);
  const [isClaimFileSuccessful, setClaimFileSuccessful] = useState<boolean>(true);
  const [claimFileData, setClaimFileData] = useState<IFileResponse>();

  useEffect(() => {
    if (claimFileId) {
      const fetchClaimData = async (): Promise<void> => {
        setClaimFileLoading(true);
        try {
          const claimResponse = await cjdApiConnector().claim.getClaimFile(claimFileId);

          if (claimResponse) {
            setClaimFileData(claimResponse);
            setClaimFileSuccessful(true);
          }
        } catch (e) {
          console.error('Could not fetch users', e);
        }

        setClaimFileLoading(false);
      };

      fetchClaimData();
    }
  }, [claimFileId]);

  return {
    claimFileData,
    isClaimFileLoading,
    setClaimFileId,
    isClaimFileSuccessful,
  };
});
