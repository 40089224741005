import { createStore } from 'reusable';
import { useEffect, useState } from 'react';
import { apiConnector } from '../utils/apiConnector';
import ISalesProductName from '@tia/customer-api-connector/dist/model/ISalesProductName';

export const useSalesProductStore = createStore(() => {
  const [salesProductsData, setSalesProductData] = useState([] as ISalesProductName[]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchSalesProducts = async (): Promise<void> => {
      setLoading(true);
      try {
        const salesProductResponse = await apiConnector().salesProduct.getListOfSalesProductNames();

        if (salesProductResponse) {
          setSalesProductData(salesProductResponse);
        }

        setLoading(false);
      } catch (e) {
        console.error('Could not fetch sales products', e);
        setLoading(false);
      }
    };

    fetchSalesProducts();
  }, []);

  return {
    salesProductsData,
    loading,
  };
});
