import React from 'react';
import styled from 'styled-components';
import { Button, ButtonColor, ButtonSize, Icon, IconName } from '@tia/react-ui-library';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const ProfileStyledIcon = styled(Icon)`
  margin-left: 8px;
`;

export const StyledProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;
  width: 100%;
  padding: 22px 24px 24px 24px;
  margin: 12px 0;
  background: ${ThemeUtility.colorPanelBackground};
  border-radius: 8px;
  border: 1px solid ${ThemeUtility.colorPanelBorder};
  transition: 250ms all;
  box-shadow: 0px 2px 10px 0px rgba(2, 0, 0, 0.05);

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0px 8px 24px 0px rgba(2, 0, 0, 0.05);
  }

  &:active {
    box-shadow: 0px 2px 10px 0px rgba(2, 0, 0, 0.05);
  }

  h5 {
    color: ${ThemeUtility.colorHeaderText};
    font-size: 20px;
    font-weight: var(--tia-card-font-weight, 400);
    height: 28px;
    letter-spacing: 0;
    width: 154px;
    padding-bottom: 4px;
  }

  p {
    height: 18px;
    padding-top: 4px;
    color: ${ThemeUtility.colorCaptionText};
    font-size: 14px;
    font-weight: var(--tia-card-font-weight, 400);
  }

  @media (min-width: 1024px) {
    max-width: calc(50% - 6px);
    margin: 0;

    h5 {
      font-size: 22px;
      height: 28px;
    }
  }
`;

export const StyledProfileContainersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 1024px) {
    padding-top: 48px;
    justify-content: space-between;
  }
`;

export const StyledProfileHeroData = styled.div`
  z-index: 1;
`;

export const StyledProfileHero = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 100%;
  height: 292px;
  box-shadow: inset 0px -1px 0px 0px rgb(237, 238, 243);
  background: ${ThemeUtility.colorPanelBorder};

  @media (min-width: 1024px) {
    position: relative;
    height: 276px;
    justify-content: center;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.3) 28%,
        rgba(218, 218, 218, 0.3) 48%,
        rgba(185, 185, 185, 0.3) 71%,
        rgba(68, 68, 68, 0.3) 89%,
        rgba(71, 70, 70, 0.3) 100%
      )
      rgb(255, 255, 255);
  }
`;

export const StyledProfileHeroImage = styled.div`
  width: 100%;
  min-height: 163px;
  background: ${ThemeUtility.profileHeroBackgroundUrl};
  background-position: center 30%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media (max-width: 1024px) {
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: ' ';
      background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
    }
  }

  @media (min-width: 1024px) {
    background: ${ThemeUtility.profileHeroBackgroundUrl};
    background-repeat: no-repeat;
    width: 100%;
    height: 276px;
    position: absolute;
    right: 0;
    background-size: cover;
    background-position: right;
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 1));
  }
`;

export const StyledProfileShortInfo = styled.div`
  white-space: nowrap;
  width: 100%;
  z-index: 2;

  h4 {
    color: ${ThemeUtility.colorHeaderText};
    height: 33px;
    font-size: 26px;
    letter-spacing: 0;
    font-weight: 300;
    margin-bottom: 4px;
  }

  p {
    color: ${ThemeUtility.colorCaptionText};
    width: 215px;
    height: 24px;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
  }

  @media (min-width: 1024px) {
    text-align: left;

    h4 {
      font-size: 32px;
      margin-bottom: 8px;
      height: 40px;
    }

    p {
      font-size: 16px;
      margin-top: 4px;
    }
  }
`;

export const NavigateBackButton = ({ onClick }: { onClick: () => void }): JSX.Element => (
  <ButtonContainer>
    <Button
      onClick={onClick}
      icon={<Icon icon={IconName.CHEVRON_LEFT} />}
      color={ButtonColor.SECONDARY}
      size={ButtonSize.DEFAULT}
    />
  </ButtonContainer>
);

const ButtonContainer = styled.div`
  width: 40px !important;
  margin: 0 16px 0 0;

  span {
    margin: 0;
  }
`;
