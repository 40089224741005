import { useEffect, useRef } from 'react';

const useEventBinder = <T>(
  eventName: string,
  handler: (arg?: CustomEvent<T>) => void
): React.MutableRefObject<HTMLElement> => {
  const ref = useRef() as React.MutableRefObject<HTMLElement>;

  useEffect(() => {
    if (ref.current) {
      const element = ref.current;

      element.addEventListener(eventName, handler as EventListener);

      return (): void => {
        element.removeEventListener(eventName, handler as EventListener);
      };
    }
  });

  return ref;
};

export default useEventBinder;
