import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiConnector } from '../../utils/apiConnector';
import showToastMessage from '../../utils/showToastMessage';
import {
  Button,
  ButtonColor,
  ButtonSize,
  Icon,
  IconName,
  Spinner,
  ToastPosition,
  ToastVariant,
} from '@tia/react-ui-library';
import {
  Divider,
  ExpandIcon,
  ItemContainer,
  ItemHeader,
  ItemHeaderWrapper,
  ItemName,
  ItemPrice,
  RemoveItemContainer,
} from './Basket.styled';
import IPortfolio from '@tia/customer-api-connector/dist/model/IPortfolio';
import { useSalesBasketProvider } from '../../hooks/useSalesBasket';

interface IBasketItemProps {
  portfolio: IPortfolio;
}

const BasketItem: FC<IBasketItemProps> = ({ portfolio }) => {
  const { uuid, totalPremium, currency, label } = portfolio;
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { salesBasket, setSalesBasket, setItemCount } = useSalesBasketProvider();
  const [isRemovingQuote, setRemovingQuote] = useState<boolean>(false);

  const removeItem = async (): Promise<void> => {
    if (!uuid) {
      showToastMessage({
        message: t('views.basket.failedToRemoveQuote'),
        variant: ToastVariant.ERROR,
        position: ToastPosition.TOP_RIGHT,
      });
      console.error('Failed to remove quote UUID is not set');

      return;
    }

    setRemovingQuote(true);
    try {
      const response = await apiConnector().salesPolicy.cancelSalesPolicy(uuid);

      if (!response.successful) {
        throw response;
      }

      const availableItem = salesBasket?.filter((item) => item.uuid !== uuid);

      setSalesBasket(availableItem);
      setItemCount(availableItem?.length);

      showToastMessage({
        message: t('views.basket.removeQuoteSuccess'),
        variant: ToastVariant.SUCCESS,
        position: ToastPosition.TOP_RIGHT,
      });
    } catch (e) {
      showToastMessage({
        message: t('views.basket.failedToRemoveQuote'),
        variant: ToastVariant.ERROR,
        position: ToastPosition.TOP_RIGHT,
      });
      console.error('Failed to remove quote ', e);
    }

    setRemovingQuote(false);
  };

  return (
    <ItemContainer>
      <ItemHeaderWrapper onClick={(): void => setIsExpanded(!isExpanded)}>
        <ItemHeader>
          <ItemPrice>
            {totalPremium} {currency} {t('views.basket.perYear')}
          </ItemPrice>
          <ItemName>{label}</ItemName>
        </ItemHeader>
        <ExpandIcon icon={IconName.EXPAND_MORE} isExpanded={isExpanded} size={25} />
      </ItemHeaderWrapper>
      {isExpanded && (
        <RemoveItemContainer>
          <Button
            icon={<Icon icon={IconName.DELETE_FOREVER} size={20} />}
            size={ButtonSize.LARGE}
            block={true}
            color={ButtonColor.MINIMAL}
            onClick={removeItem}
          >
            {t('views.basket.removeItem')}
            {isRemovingQuote && <Spinner size={20} />}
          </Button>
        </RemoveItemContainer>
      )}
      <Divider />
    </ItemContainer>
  );
};

export default BasketItem;
