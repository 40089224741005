import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { ProductDetailsCoverPrice } from '../productDetails/components/CoverGroup/ProductDetailsCoverPrice.component';
import { Button, ButtonColor, ButtonSize, Icon, IconName, Spinner, ToastVariant } from '@tia/react-ui-library';
import { useTranslation } from 'react-i18next';
import showToastMessage from '../../utils/showToastMessage';
import { denormalizeSalesProduct, INormalizedSalesProduct } from '../../utils/salesProductSchema';
import { apiConnector } from '../../utils/apiConnector';
import { useSalesPolicyProvider } from '../../hooks/useSalesPolicy';
import { ISalesProduct } from '@tia/salesproduct-parser/dist/interfaces/ISalesProduct';
import { useSalesPolicyErrors } from '../../hooks/useSalesPolicyErrors';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

interface IProps {
  onUpdate: (salesProduct: ISalesProduct) => void;
  onCancel: () => void;
}

export const PolicyUpdateFloatingAction: React.FC<IProps> = ({ onUpdate, onCancel }) => {
  const containerLocation = React.useMemo(() => {
    return document.getElementById('fab-container') as HTMLElement;
  }, []);

  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = (): void => {
    setExpanded(!expanded);
  };

  return createPortal(
    <PolicyUpdateContext.Provider value={{ onUpdate, onCancel }}>
      <FloatingContainer>
        <UpdateHeader>
          <div>Editing policy</div>
          <div style={{ display: 'inherit' }}>
            <Button color={ButtonColor.MINIMAL} onClick={toggleExpanded}>
              <ExpandIcon icon={IconName.KEYBOARD_ARROW_DOWN} expanded={expanded} />
            </Button>
            <Button color={ButtonColor.MINIMAL} onClick={onCancel}>
              <Icon icon={IconName.CLOSE} />
            </Button>
          </div>
        </UpdateHeader>

        {expanded && <StyledProductDetailsCoverPrice CustomActionButton={UpdatePolicyButton} />}
      </FloatingContainer>
    </PolicyUpdateContext.Provider>,
    containerLocation
  );
};

const UpdatePolicyButton: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const { t } = useTranslation();
  const { onUpdate, onCancel } = React.useContext(PolicyUpdateContext);
  const { entities } = useSalesPolicyProvider();
  const [isUpdating, setIsUpdating] = useState(false);
  const { hasError } = useSalesPolicyErrors();

  const handleClick = async (): Promise<void> => {
    setIsUpdating(true);

    try {
      const response = (await handlePolicyUpdate(entities)) as { successful: boolean; data: ISalesProduct };

      if (!response.successful) {
        throw response;
      }

      showToastMessage({
        message: t('views.policy-details.updatedSuccessfully'),
        variant: ToastVariant.SUCCESS,
      });

      onUpdate(response.data);
    } catch (e) {
      showToastMessage({
        message: t('views.policy-details.failedToComplete'),
        variant: ToastVariant.ERROR,
      });
      console.error('Failed to add to basket', e);
      onCancel();
    }

    setIsUpdating(false);
  };

  return (
    <Button
      color={ButtonColor.PRIMARY}
      size={ButtonSize.LARGE}
      disabled={disabled || isUpdating || hasError()}
      block={true}
      onClick={handleClick}
    >
      {isUpdating && <Spinner size={20} />} {t('views.policy-details.confirmPolicyUpdateButton')}
    </Button>
  );
};

const handlePolicyUpdate = (entities: INormalizedSalesProduct): Promise<never> => {
  const salesPolicy = denormalizeSalesProduct(entities as INormalizedSalesProduct);

  return apiConnector().salesPolicy.updateSalesPolicy(salesPolicy) as Promise<never>;
};

interface IPolicyUpdateContext {
  onUpdate: (updatedObject: ISalesProduct) => void;
  onCancel: () => void;
}
const PolicyUpdateContext = React.createContext<IPolicyUpdateContext>({} as never);

const StyledProductDetailsCoverPrice = styled(ProductDetailsCoverPrice)`
  background-color: inherit;
  box-shadow: none;
  margin-bottom: 0;
`;

const ExpandIcon = styled(Icon)<{ expanded: boolean }>`
  transform: rotate(${({ expanded }): string => (expanded ? '0' : '180deg')});
`;

const UpdateHeader = styled.div`
  display: flex;
  padding: 0.8rem 1rem;
  justify-content: space-between;
  align-items: center;

  color: rgb(0, 40, 59);
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0;
`;

const FloatingContainer = styled.div`
  min-width: 456px;
  background: ${ThemeUtility.colorPanelBackground};
  border: 1px solid ${ThemeUtility.colorPanelBorder};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`;
