import React, { FC, useState, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import IAuth from '@tia/authenticator/dist/interfaces/IAuth.interface';
import auth from '@tia/authenticator';
import { Header } from './components/header/Header.component';
import Sidebar from './components/sidebar/Sidebar.component';
import Footer from './components/footer/Footer.component';
import Home from './views/home/Home.view';
import Claim from './views/claim/Claim.view';
import ClaimDetails from './views/claimDetails/ClaimDetails.view';
import MakeClaim from './views/makeClaim/MakeClaim.view';
import Login from './views/Login/Login.view';
import Products from './views/products/Products.view';
import Profile from './views/profile/Profile.view';
import Invoices from './views/profile/invoices/Invoices.view';
import Information from './views/profile/information/Information.view';
import Support from './views/support/Support.view';
import { menuItems } from './router/menuItems';
import { Routes } from './router/routes';
import styled from 'styled-components';
import { PolicyDetailsView } from './views/policyDetails/PolicyDetails.view';
import { getWidgetAuthorizations, getAuthOptions } from './utils/authConfig';
import { ProductRouting } from './views/products/ProductRouting';
import Checkout from './views/checkout/Checkout.component';
import { SalesBasketProvider, useSalesBasketProvider } from './hooks/useSalesBasket';
import { ToastContainer } from '@tia/react-ui-library';
import { AppWrapper } from './components/appWrapper/AppWrapper';
import { PolicyRouter } from './views/policy/PolicyRouter.component';
import { ReusableProvider } from 'reusable';
import { FabContainer } from './components/fab/Styled';
import { HelpFAB } from './components/fab/HelpFAB.component';
import { PortalThemeProvider, ThemeUtility } from '@tia/customer-self-service-portal-theme';
import { BrandedContentProvider } from './hooks/useBrandedContent';

const AppComponent: FC = () => {
  const [isMobileMenuVisible, setMobileVisibility] = useState<boolean>(false);

  const auth0: IAuth = auth(getWidgetAuthorizations(), getAuthOptions());
  const isLoggedIn = auth0.isAuthenticated();

  if (!isLoggedIn) {
    storeVisitedPath(window.custEnv.contextPath);

    if (!auth0.isAuthenticating()) {
      auth0.login();
    }

    return (
      <BrowserRouter basename={window.custEnv.contextPath}>
        <Switch>
          <Route
            exact={true}
            isRoot={false}
            path={Routes.Callback}
            component={(): JSX.Element => <Login auth={auth0} />}
          />
        </Switch>
      </BrowserRouter>
    );
  }

  return (
    <ReusableProvider>
      <SalesBasketProvider>
        <BrowserRouter basename={window.custEnv.contextPath}>
          <>
            <Sidebar
              menuItems={menuItems}
              isMobileMenuVisible={isMobileMenuVisible}
              setMobileVisibility={setMobileVisibility}
            />
            <Header
              menuItems={menuItems}
              menuVisibility={isMobileMenuVisible}
              setMenuVisibility={setMobileVisibility}
            />
            <AppContainer>
              <BasketOverlay />
              <ToastContainer top="50px" />
              <AppWrapper>
                <ContentWrapper>
                  <Switch>
                    <Route path={Routes.Home} component={Home} exact />
                    <Route path={Routes.Claim} component={Claim} exact />
                    <Route path={Routes.ClaimDetails} component={ClaimDetails} exact />
                    <Route path={Routes.PolicyDetails} component={PolicyDetailsView} exact />
                    <Route path={Routes.MakeAClaim} component={MakeClaim} exact />
                    <Route path={Routes.Profile} component={Profile} exact />
                    <Route path={Routes.ProfileInvoices} component={Invoices} exact />
                    <Route path={Routes.ProfileDetails} component={Information} exact />
                    <Route path={Routes.Support} component={Support} exact />
                    <Route path={Routes.Products} component={Products} exact />
                    <Route path={Routes.ProductOverview} component={ProductRouting} />
                    <Route path={Routes.Checkout} component={Checkout} exact />
                    <Route path={Routes.Policy} component={PolicyRouter} />
                    <Redirect exact={true} from="*" to={Routes.Home} />
                  </Switch>
                </ContentWrapper>
                <FabContainer id="fab-container">
                  <HelpFAB />
                </FabContainer>
              </AppWrapper>

              <Footer />
            </AppContainer>
          </>
        </BrowserRouter>
      </SalesBasketProvider>
    </ReusableProvider>
  );
};

const BasketOverlay: FC = () => {
  const { isBasketVisible } = useSalesBasketProvider();

  if (!isBasketVisible) {
    return null;
  }

  return <StyledBasketOverlay />;
};

const StyledBasketOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 10;
  @media (max-width: 767px) {
    display: none;
  }
`;

const AppContainer = styled.div`
  height: 100%;
  padding-top: 64px;
  padding-bottom: 40px;
`;

const ContentWrapper = styled.div`
  background: ${ThemeUtility.colorBodyBackground};
  min-height: 100%;
  height: 100%;
`;

const storeVisitedPath = (path: string): void => {
  if (!path || path === '/' || path === Routes.Callback) {
    return;
  }

  localStorage.setItem('last_visited', path);
};

export default function App(): JSX.Element {
  return (
    <Suspense fallback="">
      <PortalThemeProvider>
        <BrandedContentProvider>
          <AppComponent />
        </BrandedContentProvider>
      </PortalThemeProvider>
    </Suspense>
  );
}
