import React from 'react';
import { DetailRowContainer } from '../details/Details.styled';

import {
  TimelineContainer,
  TimelineContentContainer,
  ContentTitle,
  ContentValue,
  NoEventsFound,
} from './Timeline.styled';
import Details from '../details/Details.component';

interface IProps {
  title: string;
  noTimelineEventsFound: string;
  timelineEntries: ITimelineEntry[];
}

export interface ITimelineEntry {
  title: string;
  description: string;
}

const Timeline: React.FunctionComponent<IProps> = ({ title, noTimelineEventsFound, timelineEntries }): JSX.Element => {
  const getTimelineElements = (): JSX.Element | JSX.Element[] => {
    const timelineEntriesLength = timelineEntries?.length || 0;

    if (timelineEntriesLength === 0) {
      return (
        <DetailRowContainer>
          <NoEventsFound>{noTimelineEventsFound}</NoEventsFound>
        </DetailRowContainer>
      );
    }

    const fileDetails: JSX.Element[] = timelineEntries.map((element, index) => {
      const isFirst = index === 0;

      return (
        <TimelineContentContainer key={index} active={isFirst}>
          <ContentTitle>{element.title}</ContentTitle>
          <ContentValue hasTitle={element.title ? true : false}>{element.description}</ContentValue>
        </TimelineContentContainer>
      );
    });

    return fileDetails;
  };

  return (
    <Details title={title}>
      <TimelineContainer isEmpty={timelineEntries.length === 0}>{getTimelineElements()}</TimelineContainer>
    </Details>
  );
};

export default Timeline;
