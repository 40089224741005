import styled from 'styled-components';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const StyledFooter = styled.div`
  bottom: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: fixed;
  border-top: 1px solid ${ThemeUtility.colorDividerBackground};
  text-align: center;
  font-size: 12px;
  color: ${ThemeUtility.colorCaptionText};
  background-color: ${ThemeUtility.colorPanelBackground};
  margin-top: -40px;
`;
