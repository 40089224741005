import React, { FC } from 'react';
import DetailsSummary from '../../../components/detailsSummary/DetailsSummary.component';
import { getObjectGroupTitle, getSortedFields, getObjectGroups } from '../../../utils/salesPolicy';
import { useSalesPolicyProvider } from '../../../hooks/useSalesPolicy';
import { INormalizedObjectGroups, INormalizedObjectInstances } from '../../../utils/salesProductSchema';
import { getFieldById, getObjectInstanceById } from '../../../utils/salesProductSelectors';

interface IObjectInstanceSummary {
  objectGroup: INormalizedObjectGroups;
  objectInstance: INormalizedObjectInstances;
}
const ObjectInstanceSummary: FC<IObjectInstanceSummary> = ({ objectGroup, objectInstance }) => {
  const { entities } = useSalesPolicyProvider();
  const fields = objectInstance.fields.map((fieldId) => getFieldById(entities, fieldId));

  const title = getObjectGroupTitle(entities, objectGroup, objectInstance);
  const visibleFields = fields.filter((field) => field.fieldUiProperties.visible);
  const sortedFields = getSortedFields(visibleFields);

  return <DetailsSummary fields={sortedFields} title={title} />;
};

const ObjectGroupSummary: FC<{ objectGroup: INormalizedObjectGroups }> = ({ objectGroup }) => {
  const { entities } = useSalesPolicyProvider();
  const objectInstances = objectGroup.objectInstances.map((instanceId) => getObjectInstanceById(entities, instanceId));

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {objectInstances.map((objectInstance) => (
        <ObjectInstanceSummary key={objectInstance.nodeID} objectGroup={objectGroup} objectInstance={objectInstance} />
      ))}
    </>
  );
};

export const PolicyObjectGroupDetails: FC = () => {
  const { entities } = useSalesPolicyProvider();
  const objectGroups = getObjectGroups(entities);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {objectGroups.map((objectGroup) => (
        <ObjectGroupSummary key={objectGroup.nodeID} objectGroup={objectGroup} />
      ))}
    </>
  );
};
