import React, { useEffect, useState } from 'react';
import { ButtonSize } from '@tia/react-ui-library';
import { useAppWrapper } from '../appWrapper/AppWrapper';
import debounce from '../../utils/debounce';
import {
  DetailsHeroDesktopButtonsContainer,
  DetailsHeroHeadline,
  DetailsHeroMobileButtonsContainer,
  DetailsHeroText,
  DetailsMobileContainer,
  FixedHeroContainer,
  GeneralInfoContainer,
  DetailsHeroContainer,
  HeroDesktopView,
  HeroMobileView,
  HeroStickyContainer,
  MobileImage,
} from '../../components/detailsHero/DetailsHero.styled';
import { HomeHeroContainerSpacer } from '../../views/home/Home.styled';
import { DetailsHeroAction } from './DetailsHero.component';

interface IProps {
  subTitle: string;
  title: string;
  image: string;
}

export const DetailsHeroHeader: React.FC<IProps> = ({ children, title, subTitle, image }) => {
  const appWrapper = useAppWrapper();
  const [scrollPosition, setScrollPosition] = useState<string>('inactive');
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const resizeEventListener = (): void => debounce(() => setScreenWidth(window.innerWidth), 100);

    window.addEventListener('resize', resizeEventListener);

    return (): void => window.removeEventListener('resize', resizeEventListener);
  }, [screenWidth]);

  const listener = (): void => {
    if (appWrapper.scrollTop > 0) {
      setScrollPosition('active');
    } else {
      setScrollPosition('inactive');
    }
  };

  useEffect(() => {
    if (!appWrapper) {
      return;
    }

    appWrapper.addEventListener('scroll', listener);

    return (): void => appWrapper.removeEventListener('scroll', listener);
  }, []);

  const actions = React.useMemo(() => {
    return React.Children.toArray(children)
      .filter((child) => child instanceof Object)
      .filter((child) => {
        return (child as JSX.Element).type.name === DetailsHeroAction.name;
      });
  }, [children]);

  const mobileActions = React.useMemo(() => {
    return actions.map((button: React.ReactNode) =>
      React.cloneElement(button as never, {
        size: ButtonSize.X_LARGE,
        block: true,
      })
    ) as JSX.Element[];
  }, [actions]);

  return (
    <>
      <HeroMobileView>
        <DetailsMobileContainer>
          <MobileImage backgroundUrl={image} />
          <GeneralInfoContainer>
            <DetailsHeroText>{title}</DetailsHeroText>
            <DetailsHeroHeadline>{subTitle}</DetailsHeroHeadline>
            <div style={{ height: '24px', width: '100%' }} />
            <DetailsHeroMobileButtonsContainer>{mobileActions}</DetailsHeroMobileButtonsContainer>
          </GeneralInfoContainer>
        </DetailsMobileContainer>
      </HeroMobileView>

      <HeroStickyContainer>
        <DetailsHeroContainer className={scrollPosition} backgroundUrl={image} data-testid="details-hero">
          <FixedHeroContainer className={scrollPosition}>
            <GeneralInfoContainer className={scrollPosition}>
              <div>
                <DetailsHeroHeadline>{subTitle}</DetailsHeroHeadline>
                <DetailsHeroText>{title}</DetailsHeroText>
              </div>
              <DetailsHeroDesktopButtonsContainer>{actions}</DetailsHeroDesktopButtonsContainer>
            </GeneralInfoContainer>
          </FixedHeroContainer>
        </DetailsHeroContainer>
      </HeroStickyContainer>

      <HeroDesktopView>
        <HomeHeroContainerSpacer />
      </HeroDesktopView>
    </>
  );
};
