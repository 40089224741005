import React, { FC, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { usePartyAndContactStore } from '../../hooks/store';
import { Button, Icon, IconName, ButtonColor, ButtonSize } from '@tia/react-ui-library';
import {
  ButtonsContainer,
  DesktopHeader,
  LogoContainer,
  MobileHeader,
  TabletHeader,
  StyledHeader,
  StyledNavLink,
  Logo,
} from './Styled';
import IMenuItems from '../../interfaces/IMenuItems';
import { Routes } from '../../router/routes';
import useEventBinder from '../../hooks/useEventBinder';

import { Basket } from '../basket/Basket.component';
import { logout } from '../../utils/authUtils';
import { useSalesBasketProvider } from '../../hooks/useSalesBasket';

interface IProps {
  menuItems: IMenuItems[];
  menuVisibility: boolean;
  setMenuVisibility: Dispatch<SetStateAction<boolean>>;
}

interface IPropsRouter extends RouteComponentProps, IProps {
  menuItems: IMenuItems[];
  menuVisibility: boolean;
  setMenuVisibility: Dispatch<SetStateAction<boolean>>;
}

interface ChildRoute {
  route: string;
  translationKey: string;
  childRoute: string;
}

const childRoutes: ChildRoute[] = [
  { route: Routes.ProfileInvoices, translationKey: 'invoices', childRoute: Routes.Profile },
  { route: Routes.MakeAClaim, translationKey: 'makeClaim', childRoute: Routes.Claim },
  { route: Routes.ProfileDetails, translationKey: 'myInformation', childRoute: Routes.Profile },
];

let isChildRoute = false;
let routeBack: string;
let currentRoute: ChildRoute | undefined;

const HeaderComponent: FC<IPropsRouter> = ({ menuItems, menuVisibility, setMenuVisibility, history }): JSX.Element => {
  const { t } = useTranslation();
  const { partyData } = usePartyAndContactStore();
  const { loading, itemCount, openBasket } = useSalesBasketProvider();

  const dropdownRef = useEventBinder<{ selectedValues: unknown[] }>('change', (e) => {
    if (e?.detail.selectedValues[0] === 'logout') {
      logout();
    }
  });

  const isChildRouteActive = (): void => {
    isChildRoute = false;
    routeBack = '';
    currentRoute = childRoutes.find((childRoute) => childRoute.route === history.location.pathname);
    if (currentRoute) {
      isChildRoute = true;
      routeBack = currentRoute.childRoute;
    }
  };

  if (childRoutes.find((childRoute) => childRoute.route === history.location.pathname)) {
    isChildRouteActive();
  }

  history.listen(() => isChildRouteActive());

  const backTo = (): void => history.push(routeBack);

  const pageTitle = (): JSX.Element => {
    const foundRoute = menuItems.find((item) => item.route === history.location.pathname) || currentRoute;

    const menuTranslationKey = foundRoute ? foundRoute.translationKey : 'home';

    return <span className="pageTitle">{t(`menu.${menuTranslationKey}`)}</span>;
  };

  const renderedMenuItems = menuItems.map((item) => (
    <StyledNavLink to={item.route} key={item.route}>
      {t(`menu.${item.translationKey}`)}
    </StyledNavLink>
  ));

  const renderMobileHeaderContent = (isChildRout: boolean, includeBasket: boolean): JSX.Element => {
    if (isChildRout) {
      return (
        <>
          <Button
            icon={<Icon icon={IconName.CHEVRON_LEFT} size={24} />}
            color={ButtonColor.MINIMAL}
            onClick={backTo}
            size={ButtonSize.SMALL}
          />
          {pageTitle()}
        </>
      );
    }

    return (
      <>
        <Button
          onClick={(): void => setMenuVisibility(!menuVisibility)}
          icon={<Icon icon={IconName.MENU} size={24} />}
          color={ButtonColor.MINIMAL}
          size={ButtonSize.SMALL}
        />
        {pageTitle()}
        {(includeBasket && (
          <div className="rightSideButtons">
            <Button
              data-testid="toggle-basket-button"
              loading={loading}
              icon={<Icon icon={IconName.SHOPPING_CART} size={24} />}
              color={ButtonColor.MINIMAL}
              onClick={openBasket}
            />
            <Basket />
          </div>
        )) ||
          null}
      </>
    );
  };

  const name = partyData?.name || '';

  return (
    <StyledHeader>
      <MobileHeader menuVisibility={menuVisibility}>{renderMobileHeaderContent(isChildRoute, false)}</MobileHeader>
      <TabletHeader menuVisibility={menuVisibility}>{renderMobileHeaderContent(isChildRoute, true)}</TabletHeader>
      <DesktopHeader>
        <LogoContainer>
          <Logo to={Routes.Home} />
        </LogoContainer>
        {renderedMenuItems}
        <ButtonsContainer>
          <Button
            loading={loading}
            data-testid="toggle-basket-button"
            color={ButtonColor.SECONDARY}
            onClick={openBasket}
            icon={<Icon icon={IconName.SHOPPING_CART} />}
          >
            {t('menu.buttons.shoppingCart')} {!!itemCount && `( ${itemCount} )`}
          </Button>
          <Basket />

          <tia-dropdown ref={dropdownRef} label={name}>
            <tia-option value="logout">Logout</tia-option>
          </tia-dropdown>
        </ButtonsContainer>
      </DesktopHeader>
    </StyledHeader>
  );
};

const HeaderWithRouter = withRouter(HeaderComponent);

export const Header = HeaderWithRouter;