"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fetchRequest_1 = require("./fetchRequest");
var UrlParam_1 = require("../helpers/UrlParam");
var payment_1 = require("../api/payment");
var PaymentsConnector = /** @class */ (function () {
    function PaymentsConnector(baseUrl) {
        this.baseUrl = baseUrl;
    }
    PaymentsConnector.prototype.getListOfPayments = function (partyId, apiParams, accountType) {
        var params = new URLSearchParams();
        UrlParam_1.appendParam(params, 'partyId', partyId);
        if (accountType) {
            UrlParam_1.appendParam(params, 'accountType', accountType);
        }
        UrlParam_1.appendDefaultParams(params, apiParams);
        return fetchRequest_1.fetchRequest(payment_1.getListOfPaymentsUsingGET(params), this.baseUrl);
    };
    return PaymentsConnector;
}());
exports.default = PaymentsConnector;
