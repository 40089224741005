import { useEffect, useState } from 'react';
import { createStore } from 'reusable';
import { apiConnector } from '../utils/apiConnector';
import getPartyInformation from '../utils/partyInformation';
import IParty from '@tia/customer-api-connector/dist/model/IParty';

export const usePartyStore = createStore(() => {
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [partyData, setPartyData] = useState<IParty>();

  useEffect(() => {
    if (!isLoaded) {
      const fetchPartyData = async (): Promise<void> => {
        try {
          const partyResponse = await apiConnector().party.getParty();

          if (partyResponse) {
            setPartyData(partyResponse);
          }
        } catch (e) {
          console.error('Could not fetch users', e);
        }
      };

      fetchPartyData();
      setLoaded(true);
    }
  }, [isLoaded, setLoaded]);

  return {
    partyData,
    setLoaded,
    isLoaded,
  };
});

export const usePartyAndContactStore = createStore(() => {
  const { partyData, setLoaded } = usePartyStore();
  const [emailState, setEmailState] = useState<string>('');
  const [mobileState, setMobileState] = useState<string>('');

  useEffect(() => {
    if (partyData) {
      const { email, mobile } = getPartyInformation(partyData);

      setMobileState(mobile);
      setEmailState(email);
    }
  }, [partyData]);

  return {
    partyData,
    emailState,
    setEmailState,
    mobileState,
    setMobileState,
    setLoaded,
  };
});
