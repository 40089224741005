"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appendParam = function (params, key, param) {
    if (param) {
        params.append(key, "" + param);
    }
    return params;
};
exports.appendDefaultParams = function (params, defaultApiParams) {
    params.append('page', defaultApiParams.page);
    params.append('size', defaultApiParams.size);
    return params;
};
