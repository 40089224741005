import styled from 'styled-components';
import { Icon } from '@tia/react-ui-library';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

interface IExpandIcon {
  isExpanded: boolean;
}

export const BasketContainer = styled.div`
  position: relative;
`;

export const BasketWrapper = styled.div`
  background-color: ${ThemeUtility.colorPanelBackground};
  border-radius: 1rem;
  border: 0.0625rem solid ${ThemeUtility.colorPanelBorder};
  box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.05);
  min-height: 21.625rem;
  width: 28.5rem;
  position: absolute;
  bottom: -1.4rem;
  transform: translateY(100%);
  right: 0;
  z-index: 2;

  @media (max-width: 1025px) {
    margin-right: 1.5rem;
    bottom: -3rem;
  }
`;

export const BasketHeader = styled.div`
  height: 3.3125rem;
  margin-bottom: 1.5625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BasketHeaderContainer = styled.div`
  margin-bottom: 1.1875rem;
`;

export const BasketTitle = styled.div`
  color: ${ThemeUtility.colorBodyText};
  font-size: 1.25rem;
  font-weight: 800;
  height: 1.5625rem;
  letter-spacing: 0rem;
  width: 4.1875rem;
  margin-top: 1.25rem;
  margin-left: 1.5rem;
`;

export const Divider = styled.div`
  background: ${ThemeUtility.colorDividerBackground};
  border-radius: 0rem;
  height: 0.0625rem;
`;

export const ItemContainer = styled.div`
  height: 100%;
  margin-bottom: 1.0625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ItemsWrapper = styled.div`
  max-height: 17.625rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ItemHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ItemPrice = styled.div`
  color: ${ThemeUtility.colorCaptionText};
  font-size: 0.875rem;
  font-weight: normal;
  height: 1.125rem;
  letter-spacing: 0rem;
  width: 100%;
  margin-left: 2rem;
`;

export const ItemName = styled.div`
  color: ${ThemeUtility.colorHeaderText};
  font-size: 1.625rem;
  font-weight: 300;
  height: 2.0625rem;
  letter-spacing: 0rem;
  min-width: 12.0625rem;
  margin-bottom: 1rem;
  margin-left: 2rem;
`;

export const TotalWrapper = styled.div`
  margin-bottom: 1.0625rem;
`;

export const TotalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  width: 100%;
`;

export const Total = styled.div`
  color: ${ThemeUtility.colorBodyText};
  font-size: 0.6875rem;
  font-weight: bold;
  height: 0.875rem;
  letter-spacing: 0.0875rem;
  text-transform: uppercase;
  width: 2.625rem;
  margin-top: 1rem;
  margin-bottom: 2.125rem;
  margin-left: 2rem;
`;

export const TotalPricePerMonth = styled.div`
  color: ${ThemeUtility.colorHeaderText};
  font-size: 1.25rem;
  font-weight: 800;
  height: 1.5625rem;
  letter-spacing: 0;
  text-align: right;
  width: 100%;
  margin-bottom: 0.25rem;
`;

export const TotalPriceContainer = styled.div`
  margin-bottom: 1.0625rem;
  margin-right: 2.5rem;
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const CheckoutContainer = styled.div`
  margin-right: 2rem;
`;

export const ContinueContainer = styled.div`
  margin-left: 2rem;
`;

export const BasketBackdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
`;

export const EmptyBasketImage = styled.div`
  height: 126px;
  width: 126px;
  background: ${ThemeUtility.basketEmptyBackgroundUrl} no-repeat center;
`;

export const EmptyBasket = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const EmptyBasketText = styled.div`
  font-weight: 600;
  font-size: 1rem;
`;

export const StyledBasket = styled.div``;

export const ExpandIcon = styled(Icon)<IExpandIcon>`
  padding-right: 0.5rem;
  align-self: flex-start;
  transform: rotate(${({ isExpanded }): string => (isExpanded ? '180deg' : '0deg')});
  color: ${ThemeUtility.colorHeaderText};
  margin: 10px;
`;

export const RemoveItemContainer = styled.div`
  width: 392px;
  background: ${ThemeUtility.colorMenuItemHoverBackground};
  border-radius: 8px;
  align-self: center;
  margin: 25px 32px;
`;
