import { INormalizedSalesProduct, normalizeSalesProduct } from './salesProductSchema';
import { apiConnector } from './apiConnector';
import SalesProductParser from '@tia/salesproduct-parser';
import { ISalesProduct } from '@tia/salesproduct-parser/dist/interfaces/ISalesProduct';

export const fetchPolicy = async (policyUuid: string): Promise<INormalizedSalesProduct> => {
  const policyResponse = await apiConnector().salesPolicy.getSalesPolicyByUuid(policyUuid);

  if (!policyResponse.successful) {
    throw policyResponse.errors;
  }

  return normalizePolicy(policyResponse.data);
};

export const normalizePolicy = (policy: ISalesProduct): INormalizedSalesProduct => {
  const parser = new SalesProductParser(policy);
  const salesPolicy = parser.getFullSalesProduct();

  return normalizeSalesProduct(salesPolicy);
};
