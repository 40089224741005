import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ContainerBody, FNOL, Header, Sidebar, Title } from './Styled';
import NextQuestionWidget from '../../widgets/NextQuestion.widget';
import userIdentityTypes from '../../utils/userIdentityTypes';
import { BrandedContentEnum } from '../../hooks/useBrandedContent';
import { BrandedContent } from '../../components/brandedContent/BrandedContent.component';

const MakeClaim = (): JSX.Element => {
  const { t } = useTranslation();

  const identityType = userIdentityTypes();

  return (
    <Container>
      <Header>
        <Title>{t('views.makeClaim.title')}</Title>
      </Header>
      <ContainerBody>
        <FNOL>
          <NextQuestionWidget userId={identityType.customer} stageId={1} />
        </FNOL>
        <Sidebar>
          <BrandedContent type={BrandedContentEnum.MAKE_A_CLAIM} />
        </Sidebar>
      </ContainerBody>
    </Container>
  );
};

export default MakeClaim;
