import React, { FC, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  DesktopView,
  HeroPrimaryText,
  HeroProductContainer,
  HeroSecondaryText,
  MobileView,
  StyledAppContainer,
  StyledMainWrapper,
} from '../../styles/Styled';
import { getProductDetailsRoute } from '../../router/routes';
import { Button, ButtonSize, Icon, IconName, Spinner } from '@tia/react-ui-library';
import { getSalesProduct, salesProductImageSelector } from '../../utils/salesProductSelectors';
import { CoverGroups } from '../../components/coverGroups/CoverGroups.component';
import { SalesPolicyProvider, useSalesPolicyProvider } from '../../hooks/useSalesPolicy';
import { INormalizedSalesProduct } from '../../utils/salesProductSchema';
import NoSupport from '../../components/noSupport/NoSupport.component';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';
import { BrandedContentEnum } from '../../hooks/useBrandedContent';
import { BrandedContent } from '../../components/brandedContent/BrandedContent.component';

export const ProductOverview: FC = () => {
  const { t } = useTranslation();
  const { entities } = useSalesPolicyProvider();
  const productEntities = entities;
  const { productId } = useParams<{ productId: string }>();
  const salesProduct = getSalesProduct(entities);
  const salesProductImage = useMemo(() => getProductImage(productEntities), [productEntities]);

  const renderHero = (): JSX.Element => {
    if (!salesProduct) {
      return (
        <HeroProductContainer>
          <Spinner size={20} />
        </HeroProductContainer>
      );
    }

    return (
      <HeroProductContainer img={salesProductImage}>
        <HeroPrimaryText>{salesProduct?.salesProductUiProperties.label}</HeroPrimaryText>
        <HeroSecondaryText>{salesProduct?.salesProductUiProperties.description}</HeroSecondaryText>
        <UnstyledLink to={getProductDetailsRoute(productId)}>
          <Button icon={<Icon icon={IconName.LIBRARY_BOOKS} />} size={ButtonSize.LARGE}>
            {t('views.sales-product-flow.calculatePrice')}
          </Button>
        </UnstyledLink>
      </HeroProductContainer>
    );
  };

  return (
    <Container>
      <DesktopView>
        {renderHero()}

        <SalesPolicyProvider entities={productEntities as INormalizedSalesProduct}>
          <div>
            <StyledAppContainer>
              <BrandedContent type={BrandedContentEnum.PRODUCT_VARIANT_ONE} />
            </StyledAppContainer>
          </div>

          <FullWidthContainer paddingTop={'20px'}>
            <CoverGroupsInRow renderToggle={false} disableToggle={false} />
          </FullWidthContainer>

          <div>
            <StyledAppContainer>
              <BrandedContent type={BrandedContentEnum.PRODUCT_VARIANT_TWO} />
            </StyledAppContainer>
          </div>
        </SalesPolicyProvider>
      </DesktopView>
      <MobileView>
        <NoSupport message={t('views.noSupport.mobile.message')} />
      </MobileView>
    </Container>
  );
};

const getProductImage = (entities: INormalizedSalesProduct | undefined): string => {
  if (!entities) {
    return '';
  }

  return salesProductImageSelector(entities);
};

const Container = styled(StyledMainWrapper)`
  background: ${ThemeUtility.colorBodyBackground};
`;

const FullWidthContainer = styled(StyledAppContainer)`
  padding-top: 20px;
  width: 100%;
`;

const UnstyledLink = styled(Link)`
  text-decoration: none;
`;

const CoverGroupsInRow = styled(CoverGroups)`
  flex-direction: row;
`;
