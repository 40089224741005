import { TFunction } from 'i18next';

/**
 * convert YYYY-MM-DDThh:mm:ssZ to dd-MM-YYYY
 * @param date ISO8601 string
 */
export const convertISOToDateString = (date: Date | string): string => {
  if (!date) {
    return '';
  }

  return new Date(date).toLocaleDateString('nl', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

/**
 * Returns read friendly time stamp in the format October 24, 2019
 * @param date
 */
export const convertISOReadFriendlyDateString = (date: Date | string | undefined): string => {
  if (!date) {
    return '';
  }

  const dateObj = new Date(date);
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(dateObj);
  const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(dateObj);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(dateObj);

  return `${month} ${day}, ${year}`;
};

/**
 * Returns date since current local date without decimals
 * @param date
 */
export const convertISOToDaysSinceString = (date: Date | string): string => {
  if (!date) {
    return '';
  }

  const dateObj = new Date(date);
  const today = new Date();

  const millisecondsPerDay = 24 * 60 * 60 * 1000;

  return Math.trunc((treatAsUTC(today).getTime() - treatAsUTC(dateObj).getTime()) / millisecondsPerDay).toString();
};

/**
 * Returns time since local time in the format hh:mm
 * @param date
 */
export const convertISOToTimeAgo = (date: Date | string): string => {
  if (!date) {
    return '';
  }

  const dateObj = new Date(date);
  const hours = new Intl.DateTimeFormat('en', { hour: 'numeric', hour12: false }).format(dateObj);
  const minutes = new Intl.DateTimeFormat('en', { minute: 'numeric' }).format(dateObj);

  return `${hours}:${minutes}`;
};

const treatAsUTC = (date: Date): Date => {
  const result = new Date(date);

  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());

  return result;
};

/**
 * Returns greeting based on the users local time
 * 00-12 - Morning
 * 12-17 - Afternoon
 * 17-00 - Evening
 * @param t
 */
export const getGreeting = (t: TFunction): string => {
  const hours = Number(new Intl.DateTimeFormat('en', { hour: 'numeric', hour12: false }).format(new Date()));

  if (hours < 12) {
    return t('utils.dateFormatter.greetings.morning');
  } else if (hours >= 12 && hours <= 17) {
    return t('utils.dateFormatter.greetings.afternoon');
  }

  return t('utils.dateFormatter.greetings.evening');
};

/**
 * Date parse
 * @param str Date in format YYYY-MM-DD
 * @returns Date in format DD-MM-YYYY
 */
export const handleParse = (str: string): string | undefined => {
  const arrayDate = str.split('-');

  if (arrayDate.length === 3 && arrayDate[0].length && arrayDate[1].length && arrayDate[2].length) {
    return `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}`;
  }

  return;
};
