export interface TiaCustomerSelfServicePortalThemeVars {
  colorPrimary: string;
  colorError: string;
  colorSuccess: string;

  colorCaptionText: string;
  colorAlternativeCaptionText: string;
  colorPrimaryText: string;

  colorBodyText: string;
  colorBodyBackground: string;

  colorPanelBackground: string;
  colorPanelBorder: string;

  sidebarMenuLogoUrl: string;
  topMenuLogoWidth: string;
  topMenuLogoUrl: string;

  colorMenuItemText: string;
  colorMenuItemBackground: string;
  colorMenuItemHoverBackground: string;

  divider: string;

  fontFamily: string;

  colorHeaderText: string;

  colorFABHelpBackground: string;
  colorFABHelpText: string;

  colorChatBubbleBackground: string;
  colorChatBubbleText: string;

  colorChatResponseBubbleText: string;
  colorChatResponseBubbleBackground: string;

  colorTimelineBackground: string;

  profileHeroBackgroundUrl: string;
  basketEmptyBackgroundUrl: string;

  /** The following properties should not be overwritten by customer  */
  brandedWidgetHeight: string;
}

interface ThemeProps {
  theme: TiaCustomerSelfServicePortalThemeVars;
}

export const ThemeUtility = {
  colorPrimary: ({ theme }: ThemeProps): string => theme.colorPrimary,
  colorError: ({ theme }: ThemeProps): string => theme.colorError,
  colorSuccess: ({ theme }: ThemeProps): string => theme.colorSuccess,
  colorPrimaryText: ({ theme }: ThemeProps): string => theme.colorPrimaryText,

  colorCaptionText: ({ theme }: ThemeProps): string => theme.colorCaptionText,
  colorAlternativeCaptionText: ({ theme }: ThemeProps): string => theme.colorAlternativeCaptionText,

  colorBodyText: ({ theme }: ThemeProps): string => theme.colorBodyText,
  colorBodyBackground: ({ theme }: ThemeProps): string => theme.colorBodyBackground,

  colorPanelBackground: ({ theme }: ThemeProps): string => theme.colorPanelBackground,
  colorPanelBorder: ({ theme }: ThemeProps): string => theme.colorPanelBorder,

  colorMenuItemText: ({ theme }: ThemeProps): string => theme.colorMenuItemText,
  colorMenuItemBackground: ({ theme }: ThemeProps): string => theme.colorMenuItemBackground,
  colorMenuItemHoverBackground: ({ theme }: ThemeProps): string => theme.colorMenuItemHoverBackground,

  sidebarMenuLogoUrl: ({ theme }: ThemeProps): string => theme.sidebarMenuLogoUrl,
  topMenuLogoWidth: ({ theme }: ThemeProps): string => theme.topMenuLogoWidth,
  topMenuLogoUrl: ({ theme }: ThemeProps): string => theme.topMenuLogoUrl,

  colorDividerBackground: ({ theme }: ThemeProps): string => theme.divider,

  fontFamily: ({ theme }: ThemeProps): string => theme.fontFamily,
  colorHeaderText: ({ theme }: ThemeProps): string => theme.colorHeaderText,

  colorFABHelpBackground: ({ theme }: ThemeProps): string => theme.colorFABHelpBackground,
  colorFABHelpText: ({ theme }: ThemeProps): string => theme.colorFABHelpText,

  colorChatBubbleBackground: ({ theme }: ThemeProps): string => theme.colorChatBubbleBackground,
  colorChatBubbleText: ({ theme }: ThemeProps): string => theme.colorChatBubbleText,

  colorChatResponseBubbleBackground: ({ theme }: ThemeProps): string => theme.colorChatResponseBubbleBackground,
  colorChatResponseBubbleText: ({ theme }: ThemeProps): string => theme.colorChatResponseBubbleText,

  colorTimelineBackground: ({ theme }: ThemeProps): string => theme.colorTimelineBackground,

  profileHeroBackgroundUrl: ({ theme }: ThemeProps): string => theme.profileHeroBackgroundUrl,
  basketEmptyBackgroundUrl: ({ theme }: ThemeProps): string => theme.basketEmptyBackgroundUrl,

  brandedWidgetHeight: ({ theme }: ThemeProps): string => theme.brandedWidgetHeight,
};
