import IPartyInfo from '../interfaces/IPartyInfo';
import ContactType from '@tia/customer-api-connector/dist/model/ContactType';
import IParty from '@tia/customer-api-connector/dist/model/IParty';

const partyInformation = (partyData: IParty | undefined): IPartyInfo => {
  const partyInfo: IPartyInfo = { name: '', email: '', mobile: '' };

  if (partyData !== undefined && Object.values(partyData).length) {
    partyInfo.name = partyData.name;
    if (partyData.contactDetails && partyData.contactDetails.length > 0) {
      partyData.contactDetails.forEach((contactDetail) => {
        if (contactDetail.type) {
          if (contactDetail.type === ContactType.EMAIL) {
            partyInfo.email = contactDetail.detail;
          } else if (contactDetail.type === ContactType.MOBIL) {
            partyInfo.mobile = contactDetail.detail;
          }
        }
      });
    }
  }

  return partyInfo;
};

export default partyInformation;
