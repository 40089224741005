import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Card } from '@tia/react-ui-library';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const ProductHeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px 0;
  background: ${ThemeUtility.colorPanelBackground};
  min-height: 276px;
`;

export const ProductHeadline = styled.div`
  color: ${ThemeUtility.colorHeaderText};
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

export const ProductDescription = styled.div`
  color: ${ThemeUtility.colorCaptionText};
  font-size: 16px;
  margin-top: 16px;
  text-align: center;
`;

export const ProductCards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ProductCardContainer = styled.div`
  flex-wrap: nowrap;
  width: 100%;
  margin: 10px 0;

  @media (min-width: 500px) {
    width: calc(50% - 12px);
  }

  @media (min-width: 1025px) {
    flex-grow: 1;
    min-width: 30%;
    max-width: calc(33.333332% - 16.5px);
  }
`;

export const ProductLink = styled(Link)`
  cursor: pointer;
  display: contents;
`;

export const CardImageOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(
    -180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 33%,
    rgba(0, 0, 0, 0) 46%,
    rgba(0, 0, 0, 0.77) 100%
  );
`;

export const StyledCard = styled(Card)`
  transition: transform 200ms ease, box-shadow 200ms ease-in;

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.24);
    opacity: 1;
    transform: scale(1.02);
  }
`;

export const NoCardsContainer = styled.div`
  color: ${ThemeUtility.colorCaptionText};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`;
