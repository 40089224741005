import React, { FC } from 'react';
import styled from 'styled-components';

export const WizardForm: FC = ({ children }): JSX.Element => {
  return <WizardFormContainer>{children}</WizardFormContainer>;
};

const WizardFormContainer = styled.div`
  --input-group-padding: 0.75rem;
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.5rem;
  margin-left: calc(var(--input-group-padding) * -1);
  margin-right: calc(var(--input-group-padding) * -1);
`;
