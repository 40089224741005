import styled from 'styled-components';
import { StyledMainWrapper } from '../../styles/Styled';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const Container = styled(StyledMainWrapper)`
  display: inline-block;
`;

export const DetailsContainer = styled.div`
  background: ${ThemeUtility.colorPanelBackground};
  border-radius: 0px;
  box-shadow: inset 0px 1px 0px 0px rgb(237, 238, 243), inset 0px -1px 0px 0px rgb(237, 238, 243);
  width: 100%;
  flex-direction: column;
  display: flex;
  padding: 0 24px;

  @media (min-width: 768px) {
    border-radius: 8px;
    border: 1px solid ${ThemeUtility.colorPanelBorder};
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  }
`;

export const DetailTitle = styled.div`
  display: flex;
  color: ${ThemeUtility.colorHeaderText};
  font-size: 0.7rem;
  font-weight: bold;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  align-items: center;
  margin: 23px 0px 13px 0;

  @media (min-width: 768px) {
    font-size: 1.2rem;
    letter-spacing: 0px;
    max-width: 100%;
    text-transform: none;
  }
`;

export const DetailRowContainer = styled.div`
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DetailRowSeparator = styled.div`
  background: ${ThemeUtility.colorDividerBackground};
  border-radius: 0;
  height: 1px;
  width: 100%;
`;

export const DetailTitleSeparator = styled.div`
  @media (min-width: 768px) {
    background: ${ThemeUtility.colorDividerBackground};
    border-radius: 0px;
    height: 1px;
    width: calc(100% + 48px);
    margin-left: -24px;
  }
`;
