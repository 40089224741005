import styled from 'styled-components';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const CardContainer = styled.div`
  background: ${ThemeUtility.colorPanelBackground};
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.05);
  border-radius: 7.2px;
  margin-bottom: 1.1rem;
`;

export const CardRow = styled.div`
  color: ${ThemeUtility.colorBodyText}
  justify-content: space-between;
  position: relative;
  padding: 1rem;

  &:not(:last-child)::after {
    content: ' ';
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${ThemeUtility.colorDividerBackground};
  }
`;

export const CardTitle = styled.div`
  color: ${ThemeUtility.colorHeaderText};
  font-size: 22px;
  font-weight: var(--tia-label-font-weight, 700);
`;
