/**
 * Triggers a download of a file given a Blob and a file name
 * @param blob
 * @param fileName
 */
export const triggerDownload = (blob: Blob, fileName: string): void => {
  const placeholderTag = document.createElement('a');
  const url = window.URL.createObjectURL(blob);

  document.body.append(placeholderTag);

  placeholderTag.style.display = 'none';
  placeholderTag.href = url;
  placeholderTag.download = fileName;
  placeholderTag.click();

  window.URL.revokeObjectURL(url);

  document.body.removeChild(placeholderTag);
};
