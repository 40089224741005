import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, ButtonColor, ButtonSize, Icon, IconName, Spinner } from '@tia/react-ui-library';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { apiConnector } from '../../../../utils/apiConnector';
import { CardContainer, CardRow, CardTitle } from '../../../../components/card/Card.styled';
import { denormalizeSalesProduct, INormalizedSalesObject } from '../../../../utils/salesProductSchema';
import { SalesProductGroupsContext } from '../../salesProductGroups';
import { useSalesPolicyProvider } from '../../../../hooks/useSalesPolicy';
import { usePolicyNavigation } from '../../../policy/PolicyGroupsNavigation.component';
import { useParams } from 'react-router-dom';
import { getSalesProduct } from '../../../../utils/salesProductSelectors';
import { getOptValue } from '../../../../utils/salesPolicy';
import { SalesProductOpt } from '../../../../utils/salesProductOpt';
import { IField } from '@tia/salesproduct-parser/dist/interfaces/ISalesProduct';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

interface ICoverPriceProps {
  isPriceChanged: boolean;
}

const getUpdatedFields = (fieldsBefore: IField[], fieldsAfterUpdate: IField[]): IField[] => {
  return fieldsAfterUpdate.filter((field, index) => {
    return fieldsBefore[index].value.value !== field.value.value;
  });
};

const _isQuickQuoteSupported = (salesProduct: INormalizedSalesObject): boolean => {
  const optValue = getOptValue(salesProduct.salesProductUiProperties.opt, SalesProductOpt.QUICK_QUOTE_SUPPORT);

  if (!optValue) {
    return true;
  }

  return `${optValue}`.toLowerCase() !== 'false';
};

export const ProductDetailsCoverPrice: React.FC<{
  CustomActionButton?: React.FC<{ disabled: boolean }>;
}> = ({ CustomActionButton, ...restProps }) => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const { entities, CompleteButton } = useSalesPolicyProvider();
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [calculatedPrice, setCalculatedPrice] = useState<{ totalPremium: number; currency: string }>();
  const { routeGroups } = useContext(SalesProductGroupsContext);
  const isLastGroup = routeGroups && !!routeGroups.length && routeGroups[routeGroups.length - 1].nodeId === groupId;
  const { navigateToNextGroup } = usePolicyNavigation();
  const [isPriceChanged, setPriceChanged] = useState<boolean>(false);

  const salesProduct = getSalesProduct(entities);

  const isQuickQuoteSupported = useMemo(() => _isQuickQuoteSupported(salesProduct), [salesProduct]);

  const fieldsBeforeUpdate = useRef<IField[] | undefined>();

  const updatePrice = useCallback(async () => {
    setLoading(true);

    const product = denormalizeSalesProduct(entities);

    try {
      const response = await apiConnector().salesPolicy.calculatePremium(product);

      if (!response.successful) {
        throw response.errors;
      }

      const { totalPremium, currency } = response.data.salesProductHeader;

      setCalculatedPrice({
        totalPremium,
        currency,
      });
      setPriceChanged(false);
    } catch (e) {
      console.error('Could not get policy price', e);
    } finally {
      setLoading(false);
      setFirstRender(false);
    }
  }, [entities]);

  useEffect(() => {
    if (!isQuickQuoteSupported) {
      return;
    }

    const currentFields = Object.values(entities.fields);

    if (!fieldsBeforeUpdate.current) {
      fieldsBeforeUpdate.current = currentFields;

      return;
    }

    const updatedFields = getUpdatedFields(fieldsBeforeUpdate.current, currentFields);
    const shouldUpdatePrice = !!updatedFields.find((f: IField) => f.fieldUiProperties.affectsPremium);

    if (shouldUpdatePrice) {
      setPriceChanged(true);
    }

    fieldsBeforeUpdate.current = currentFields;
  }, [entities.fields, updatePrice]);

  useEffect(() => {
    if (!isQuickQuoteSupported) {
      return;
    }

    setPriceChanged(true);
  }, [entities.coverGroups]);

  useEffect(() => {
    if (!isQuickQuoteSupported) {
      return;
    }

    // initialise price at the component startup
    if (calculatedPrice) {
      return;
    }

    updatePrice();
  }, [updatePrice, calculatedPrice]);

  const handleOnContinueClick = (): void => {
    navigateToNextGroup();
  };

  const renderActionButton = (): JSX.Element => {
    if (CustomActionButton) {
      return <CustomActionButton disabled={isPriceChanged} />;
    }

    if (!isLastGroup) {
      return (
        <Button size={ButtonSize.LARGE} block disabled={isPriceChanged} onClick={handleOnContinueClick}>
          {t('views.productDetails.productDetailsCoverGroupPrice.continue')}
        </Button>
      );
    }

    if (isLastGroup && CompleteButton) {
      return <CompleteButton size={ButtonSize.LARGE} enabled={!isPriceChanged} block />;
    }

    throw 'Failed to render action button';
  };

  const calculatePriceButtonName =
    firstRender || calculatedPrice?.totalPremium
      ? t('views.productDetails.productDetailsCoverGroupPrice.updatePrice')
      : t('views.productDetails.productDetailsCoverGroupPrice.updatePriceError');

  return (
    <CardContainer {...restProps}>
      <CardHeader>
        <CenteredCardTitle data-testid="calculated-price">
          {loading && isQuickQuoteSupported ? (
            <Spinner size={20} data-testid="loading-spinner" />
          ) : (
            <PriceWrapper
              isPriceChanged={isPriceChanged}
              totalPremium={calculatedPrice?.totalPremium}
              currency={calculatedPrice?.currency}
              period={t('views.productDetails.productDetailsCoverGroupPrice.period')}
              errorMessage={t('views.productDetails.productDetailsCoverGroupPrice.errorMessage')}
              newPrice={t('views.productDetails.productDetailsCoverGroupPrice.newPrice')}
            />
          )}
        </CenteredCardTitle>
        <div>
          <Button
            data-testid="update-price"
            icon={<Icon icon={IconName.REFRESH} />}
            color={ButtonColor.SECONDARY}
            size={ButtonSize.LARGE}
            onClick={updatePrice}
            disabled={!isQuickQuoteSupported}
          >
            {calculatePriceButtonName}
          </Button>
        </div>
      </CardHeader>
      <CardRow>{renderActionButton()}</CardRow>
    </CardContainer>
  );
};

const CardHeader = styled.div`
  padding: 1rem;
  border-bottom: 1px ${ThemeUtility.colorDividerBackground} solid;
  display: flex;
  justify-content: space-between;
`;

const CenteredCardTitle = styled(CardTitle)`
  display: flex;
  align-items: center;
`;

const Price = styled.span<ICoverPriceProps>`
  color: ${({ isPriceChanged, theme }): string => (isPriceChanged ? theme.colorCaptionText : theme.colorHeaderText)};
  font-size: 1.375rem;
  font-family: ${ThemeUtility.colorPrimary};
  font-weight: 800;
  letter-spacing: 0;
  margin-bottom: 0.5rem;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const NewPriceInfo = styled.span`
  color: ${ThemeUtility.colorPrimary};
  font-size: 0.6875rem;
  font-weight: normal;
  align-self: flex-start;
`;
const StyledErrorMessage = styled.span`
  color: ${ThemeUtility.colorCaptionText};
`;

const PriceWrapper: FC<{
  totalPremium?: number;
  currency?: string;
  period: string;
  errorMessage: string;
  newPrice: string;
  isPriceChanged: boolean;
}> = ({ isPriceChanged, newPrice, errorMessage, totalPremium, currency, period }) => {
  if (!totalPremium) {
    return <StyledErrorMessage data-testid="error-message">{errorMessage}</StyledErrorMessage>;
  }

  if (isPriceChanged) {
    return (
      <PriceContainer>
        <Price isPriceChanged={isPriceChanged}>
          {totalPremium} {currency}/{period}
        </Price>
        <NewPriceInfo>{newPrice}</NewPriceInfo>
      </PriceContainer>
    );
  }

  return (
    <span>
      {totalPremium} {currency}/{period}
    </span>
  );
};
