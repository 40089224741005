import CustomerApiConnector from '@tia/customer-api-connector';

let _apiConnector: CustomerApiConnector | undefined;

export const apiConnector = (): CustomerApiConnector => {
  if (_apiConnector) {
    return _apiConnector;
  }

  const baseUrl = localStorage.getItem('css_api');

  if (!baseUrl) {
    throw Error('No base-url found. Check localStorage: "css_api"');
  }

  _apiConnector = new CustomerApiConnector('tiacustomer_access_token', baseUrl);

  return _apiConnector;
};
