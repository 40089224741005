import { useEffect, useMemo, useState } from 'react';
import { INormalizedSalesProduct } from '../../utils/salesProductSchema';
import { useHistory, useParams } from 'react-router-dom';
import { fetchPolicy, normalizePolicy } from '../../utils/policyUtils';
import { getSalesProduct } from '../../utils/salesProductSelectors';
import { TFunction } from 'i18next';
import { convertISOReadFriendlyDateString } from '../../utils/dateFormatter';
import { getCoverStartDate, getSalesProductImage } from '../../utils/salesPolicy';
import showToastMessage from '../../utils/showToastMessage';
import { ToastVariant } from '@tia/react-ui-library';
import { useTranslation } from 'react-i18next';
import { PolicyStatus } from '@tia/salesproduct-parser/dist/interfaces/Enums';
import { ESalesPolicyProviderMode } from '../../hooks/useSalesPolicy';
import { ISalesProduct } from '@tia/salesproduct-parser/dist/interfaces/ISalesProduct';

type PolicyDetailsType = {
  policyUuid: string;
  policyData: INormalizedSalesProduct;
  policyDetails: { title: string; text: string }[];
  title: string;
  subTitle: string;
  backgroundImage: string;
  policyMode: ESalesPolicyProviderMode;
  togglePolicyMode: () => void;
  policyStatus: PolicyStatus;
  updateSalesPolicyData: (policy: ISalesProduct) => void;
  resetPolicy: () => void;
};

export const usePolicyDetails = (): PolicyDetailsType => {
  const { t } = useTranslation();
  const history = useHistory();

  const params = useParams<{ policyId: string; policyStatus: string }>();
  const policyUuid = params.policyId;
  const routePolicyStatus = params.policyStatus;

  const [policyData, setPolicyData] = useState<INormalizedSalesProduct>(undefined as never);
  const [pristinePolicyData, setPristinePolicyData] = useState<INormalizedSalesProduct>(undefined as never);
  const [policyMode, setPolicyMode] = useState<ESalesPolicyProviderMode>(ESalesPolicyProviderMode.VIEW);
  const policyDetails = useMemo(() => extractPolicyData(policyData, t), [policyData]);
  const title = useMemo(() => extractTitle(policyData, t), [policyData]);
  const subTitle = useMemo(() => extractSubTitle(policyData), [policyData]);
  const backgroundImage = useMemo(() => extractBackgroundImage(policyData), [policyData]);

  const togglePolicyMode = (): void => {
    setPolicyMode(
      policyMode === ESalesPolicyProviderMode.EDIT ? ESalesPolicyProviderMode.VIEW : ESalesPolicyProviderMode.EDIT
    );
  };

  const updateSalesPolicyData = (salesPolicy: ISalesProduct): void => {
    const normalizedPolicy = normalizePolicy(salesPolicy);

    setPolicyData(normalizedPolicy);
    setPristinePolicyData(JSON.parse(JSON.stringify(normalizedPolicy)));
  };

  const resetPolicy = (): void => {
    const pristineData = JSON.parse(JSON.stringify(pristinePolicyData));

    setPolicyData(pristineData);
  };

  const handleFailedFetch = (): void => {
    showToastMessage({ message: t('views.policy-details.policyNotFound'), variant: ToastVariant.ERROR });
    history.push('/');
  };

  const policyStatus = useMemo(() => {
    switch (routePolicyStatus) {
      case 'policy':
        return PolicyStatus.Policy;

      case 'quote':
        return PolicyStatus.Quote;

      default:
        throw 'Unexpected type';
    }
  }, [routePolicyStatus]);

  useEffect(() => {
    fetchPolicy(policyUuid)
      .then((policy) => {
        setPolicyData(policy);
        setPristinePolicyData(JSON.parse(JSON.stringify(policy)));
      })
      .catch((e) => {
        console.error('Could not fetch policy', e);
        handleFailedFetch();
      });
  }, [policyUuid]);

  return {
    policyUuid,
    policyData,
    policyDetails,
    title,
    subTitle,
    backgroundImage,
    policyMode,
    togglePolicyMode,
    updateSalesPolicyData,
    policyStatus,
    resetPolicy,
  };
};

const extractSubTitle = (policyData: INormalizedSalesProduct | undefined): string => {
  const salesPolicy = policyData && getSalesProduct(policyData);

  return salesPolicy?.salesProductUiProperties.label || '';
};

const extractTitle = (policyData: INormalizedSalesProduct | undefined, t: TFunction): string => {
  const salesPolicy = policyData && getSalesProduct(policyData);

  if (!salesPolicy) {
    return '';
  }

  return `${salesPolicy.salesProductHeader.totalPremium} ${salesPolicy.salesProductHeader.currency}/${t(
    'views.productDetails.productDetailsCoverGroupPrice.period'
  )}`;
};

const extractBackgroundImage = (policyData?: INormalizedSalesProduct): string => {
  const salesPolicy = policyData && getSalesProduct(policyData);

  return (salesPolicy && getSalesProductImage(salesPolicy)) || '';
};

const extractPolicyData = (
  policyData: INormalizedSalesProduct | undefined,
  t: TFunction
): { title: string; text: string }[] => {
  const salesProduct = policyData?.salesProduct.root;

  return [
    {
      title: 'Policy number',
      text: salesProduct?.salesProductSystemProperties.backendPolicies[0].policyLineNo.toString() || '',
    },
    {
      title: 'Cover start date',
      text: (policyData && convertISOReadFriendlyDateString(getCoverStartDate(policyData)?.value.value)) || '',
    },
    {
      title: 'Policy status',
      text:
        (salesProduct && t(`views.policy-details.policyStatus.${salesProduct.salesProductHeader.policyStatus}`)) || '',
    },
  ];
};
