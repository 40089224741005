import React from 'react';
import { BrandedContentEnum, useBrandedContentLoader } from '../../hooks/useBrandedContent';
import i18next from 'i18next';
import { usePartyStore } from '../../hooks/store';

export const BrandedContent: React.FC<{ type: BrandedContentEnum }> = ({ type }) => {
  useBrandedContentLoader(type);

  const BrandedContentComponent = type;
  const { partyData } = usePartyStore();

  return (
    <BrandedContentComponent
      style={{ width: '100%' }}
      language={i18next.language}
      partyId={partyData?.id || partyData?.idAlt}
    />
  );
};
