import { Route, Switch, useParams } from 'react-router-dom';
import { Routes } from '../../router/routes';
import { ProductOverview } from '../productOverview/ProductOverview.view';
import ProductDetails from '../productDetails/ProductDetails.view';
import React, { useEffect, useState } from 'react';
import {
  denormalizeSalesProduct,
  INormalizedSalesProduct,
  normalizeSalesProduct,
} from '../../utils/salesProductSchema';
import { apiConnector } from '../../utils/apiConnector';
import SalesProductParser from '@tia/salesproduct-parser';
import { SalesPolicyProvider, useSalesPolicyProvider } from '../../hooks/useSalesPolicy';
import styled from 'styled-components';
import showToastMessage from '../../utils/showToastMessage';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ButtonSize, Spinner, ToastVariant } from '@tia/react-ui-library';
import { WizardFinaliseButton } from '../../components/wizard/WizardStep/WizardFinaliseButton';
import { useSalesBasketProvider } from '../../hooks/useSalesBasket';

/**
 * Fetches a product and provides it to nested routes
 */
export const ProductRouting: React.FC = () => {
  const { productId } = useParams<{ productId: string }>();
  const { t } = useTranslation();
  const history = useHistory();

  const [productEntities, setProductEntities] = useState<INormalizedSalesProduct | undefined>(undefined);

  const handleFailedFetch = (): void => {
    showToastMessage({ message: t('views.product.failedToFetch'), variant: ToastVariant.ERROR });
    history.push('/');
  };

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        setProductEntities(await fetchSalesProductByName(productId));
      } catch (e) {
        console.error('Could not fetch sales products', e);
        handleFailedFetch();
      }
    })();
  }, [productId]);

  if (!productEntities) {
    return (
      <CenteredLoader>
        <Spinner size={20} />
      </CenteredLoader>
    );
  }

  return (
    <SalesPolicyProvider entities={productEntities} completeButton={ProductCreateButton}>
      <Switch>
        <Route path={Routes.ProductOverview} component={ProductOverview} exact />
        <Route path={Routes.ProductDetails} component={ProductDetails} />
      </Switch>
    </SalesPolicyProvider>
  );
};

const ProductCreateButton: React.FC<{ enabled: boolean; size?: ButtonSize; block?: boolean }> = ({
  enabled,
  size,
  block,
}) => {
  const [isInUse, setInUse] = useState(false);
  const { t } = useTranslation();
  const { entities } = useSalesPolicyProvider();
  const { refreshAndOpenBasket } = useSalesBasketProvider();
  const handleClick = async (): Promise<void> => {
    setInUse(true);

    try {
      const response = (await handleProductCreate(entities)) as { successful: boolean };

      if (!response.successful) {
        throw response;
      }

      refreshAndOpenBasket();
    } catch (e) {
      showToastMessage({
        message: t('views.product.failedToSubmit'),
        variant: ToastVariant.ERROR,
      });
      console.error('Failed to add to basket', e);
    }

    setInUse(false);
  };

  return (
    <WizardFinaliseButton enabled={enabled} onClick={handleClick} isInUse={isInUse} size={size} block={block}>
      {t('views.product.submit')}
    </WizardFinaliseButton>
  );
};

const handleProductCreate = (entities: INormalizedSalesProduct): Promise<never> => {
  const salesPolicy = denormalizeSalesProduct(entities as INormalizedSalesProduct);

  return apiConnector().salesPolicy.createSalesPolicy([salesPolicy]) as Promise<never>;
};

const fetchSalesProductByName = async (productName: string): Promise<INormalizedSalesProduct> => {
  const salesProductResponse = await apiConnector().salesProduct.getSalesProductByName(productName);

  if (!salesProductResponse) {
    throw Error('Failed to fetch product');
  }

  const parsedSalesProduct = new SalesProductParser(salesProductResponse);

  return normalizeSalesProduct(parsedSalesProduct.getFullSalesProduct());
};

const CenteredLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;
