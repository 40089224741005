"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fetchRequest_1 = require("./fetchRequest");
var UrlParam_1 = require("../helpers/UrlParam");
var salesBasket_1 = require("../api/salesBasket");
var SalesBasketConnector = /** @class */ (function () {
    function SalesBasketConnector(baseUrl) {
        this.baseUrl = baseUrl;
    }
    SalesBasketConnector.prototype.getBasket = function (partyId, apiParams) {
        var params = new URLSearchParams();
        UrlParam_1.appendParam(params, 'partyId', partyId);
        UrlParam_1.appendDefaultParams(params, apiParams);
        return fetchRequest_1.fetchRequestWithPagination(salesBasket_1.getBasketUsingGET(params), this.baseUrl);
    };
    return SalesBasketConnector;
}());
exports.default = SalesBasketConnector;
