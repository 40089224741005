import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledAppContainer, StyledMainWrapper } from '../../../styles/Styled';
import { MobileTable, StyledInvoiceButtons, StyledTable } from './Invoices.styled';
import { RouteComponentProps, withRouter } from 'react-router';
import { getPaymentStatus } from '../../../utils/paymentStatus';
import userIdentityTypes from '../../../utils/userIdentityTypes';
import { apiConnector } from '../../../utils/apiConnector';
import IPayment from '@tia/customer-api-connector/dist/model/IPayment';
import { convertISOToDateString } from '../../../utils/dateFormatter';
import { Routes } from '../../../router/routes';
import { Icon, IconName, Spinner } from '@tia/react-ui-library';
import { NavigateBackButton } from '../Profile.styled';

const Invoice: FC<RouteComponentProps> = ({ history }): JSX.Element => {
  const { t } = useTranslation();
  const identityType = userIdentityTypes();
  const [loading, setLoading] = useState<boolean>(false);
  const [invoicesData, setInvoicesData] = useState([] as IPayment[]);

  useEffect(() => {
    const fetchInvoicesData = async (): Promise<void> => {
      setLoading(true);
      try {
        const paymentResponse = await apiConnector().payment.getListOfPayments(Number(identityType.customer), {
          page: '1',
          size: '100',
        });

        if (paymentResponse) {
          setInvoicesData(paymentResponse);
        }

        setLoading(false);
      } catch (e) {
        console.error('Could not fetch invoices', e);
        setLoading(false);
      }
    };

    fetchInvoicesData();
  }, [identityType.customer]);

  const returnToProfile = (): void => {
    history.push(Routes.Profile);
  };

  const renderMobileView = (invoicesData: IPayment[]): JSX.Element => {
    if (loading) {
      return (
        <tia-table-row>
          <div>
            <Spinner size={20} />
          </div>
        </tia-table-row>
      );
    }

    if (invoicesData.length === 0) {
      return (
        <MobileTable>
          <div className="contentPanelRow">{t('views.profile.invoice.noRows')}</div>
        </MobileTable>
      );
    }

    return (
      <MobileTable>
        {invoicesData.map((invoiceData) => (
          <div key={invoiceData.paymentNo} className="MobileTableRowStyle">
            <div className="headerPanelRow">
              <span>{convertISOToDateString(invoiceData.dueDate)}</span>
              <tia-tag
                read-only="true"
                label={t(getPaymentStatus(invoiceData.paymentStatus).statusKey)}
                color={getPaymentStatus(invoiceData.paymentStatus).color}
              />
            </div>
            <div className="contentPanelRow price">
              <span>
                {invoiceData.amount} {invoiceData.currencyCode}
              </span>
            </div>
            <div className="footerPanelRow">
              <div className="iconedContainer">
                <Icon icon={IconName.LOOP} />
                <span>{invoiceData.instruction}</span>
              </div>
              <div className="iconedContainer">
                <Icon icon={IconName.CREDIT_CARD} />
                <span>{invoiceData.paymentMethod}</span>
              </div>
            </div>
          </div>
        ))}
      </MobileTable>
    );
  };

  const renderDesktopView = (invoicesData: IPayment[]): JSX.Element[] => {
    if (invoicesData.length === 0) {
      return [
        <tia-table-row key={t('views.profile.invoice.noRows')}>
          <div>
            <div />
            <div />
            <div>{t('views.profile.invoice.noRows')}</div>
            <div />
            <div />
          </div>
        </tia-table-row>,
      ];
    }

    return invoicesData.map((invoiceData) => (
      <tia-table-row key={invoiceData.paymentNo}>
        <div>
          <div>{convertISOToDateString(invoiceData.dueDate)}</div>
          <div>{invoiceData.instruction}</div>
          <div>{invoiceData.paymentMethod}</div>
          <div>
            {invoiceData.amount} {invoiceData.currencyCode}
          </div>
          <tia-tag
            read-only="true"
            label={t(getPaymentStatus(invoiceData.paymentStatus).statusKey)}
            color={getPaymentStatus(invoiceData.paymentStatus).color}
          />
        </div>
      </tia-table-row>
    ));
  };

  return (
    <Container>
      <StyledAppContainer>
        <StyledInvoiceButtons>
          <NavigateBackButton onClick={returnToProfile} />
          <h2>Invoices</h2>
        </StyledInvoiceButtons>
        <StyledTable>
          <tia-table>
            <tia-table-header>
              <div>{t('views.profile.invoice.tableHeader.dueDate')}</div>
              <div>{t('views.profile.invoice.tableHeader.paymentInstruction')}</div>
              <div>{t('views.profile.invoice.tableHeader.paymentMethod')}</div>
              <div>{t('views.profile.invoice.tableHeader.amount')}</div>
              <div>{t('views.profile.invoice.tableHeader.status')}</div>
            </tia-table-header>
            {renderDesktopView(invoicesData)}
          </tia-table>
        </StyledTable>
        {renderMobileView(invoicesData)}
      </StyledAppContainer>
    </Container>
  );
};

const Container = styled(StyledMainWrapper)``;

export default withRouter(Invoice);
