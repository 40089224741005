import React from 'react';
import { useTranslation } from 'react-i18next';
import IAuth from '@tia/authenticator/dist/interfaces/IAuth.interface';
import Unauthorized from './Unauthorized.view';
import { Spinner } from '@tia/react-ui-library';
import styled from 'styled-components';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

interface IProps {
  auth: IAuth;
}

const Login: React.FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation();

  if (/access_token|id_token/.test(window.location.hash)) {
    props.auth.handleAuthentication().then(() => {
      window.location.pathname = window.custEnv.contextPath;
    });
  }

  if (/error=unauthorized|access_denied/.test(window.location.hash)) {
    return <Unauthorized auth={props.auth} />;
  }

  return (
    <LoginContainer>
      <LoadingText> {t('views.login.loadingText')}</LoadingText>
      <Spinner size={50} />
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoadingText = styled.div`
  color: ${ThemeUtility.colorHeaderText};
  font-size: 1.375rem;
  font-weight: normal;
  align-self: center;
  margin-bottom: 2rem;
`;

export default Login;
