"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var salesProduct_1 = require("../api/salesProduct");
var fetchRequest_1 = require("./fetchRequest");
var SalesProductConnector = /** @class */ (function () {
    function SalesProductConnector(baseUrl) {
        this.baseUrl = baseUrl;
    }
    /**
     * Get a sales product by name
     * @param name
     */
    SalesProductConnector.prototype.getSalesProductByName = function (name) {
        return fetchRequest_1.fetchRequest(salesProduct_1.getSalesProductByNameUsingGET(name), this.baseUrl);
    };
    /**
     * Get a list of sales products
     */
    SalesProductConnector.prototype.getListOfSalesProductNames = function () {
        return fetchRequest_1.fetchRequest(salesProduct_1.getListOfSalesProductNamesUsingGET(), this.baseUrl);
    };
    return SalesProductConnector;
}());
exports.default = SalesProductConnector;
