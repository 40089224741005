import React, { FC } from 'react';
import styled from 'styled-components';
import { StyledMainWrapper } from '../../styles/Styled';
import { Icon, IconName } from '@tia/react-ui-library';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

const Support: FC = (): JSX.Element => {
  return (
    <Container>
      <Icon icon={IconName.QUESTION_ANSWER} size={30} />
      <h3> Customer support page </h3>
    </Container>
  );
};

const Container = styled(StyledMainWrapper)`
  text-align: center;
  padding-top: 150px;
  color: ${ThemeUtility.colorHeaderText};
  background: ${ThemeUtility.colorBodyBackground};
  h3 {
    padding-top: 6px;
    font-weight: 900;
  }
`;

export default Support;
