"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ContactType;
(function (ContactType) {
    ContactType["HOME"] = "01";
    ContactType["WORK"] = "02";
    ContactType["TELEFAX"] = "03";
    ContactType["EMAIL"] = "04";
    ContactType["TELEX"] = "05";
    ContactType["CONTACT"] = "06";
    ContactType["MOBIL"] = "07";
    ContactType["CELLULAR"] = "08";
    ContactType["WORK2"] = "09";
    ContactType["UNKNOW"] = "UNKNOW";
})(ContactType || (ContactType = {}));
exports.default = ContactType;
