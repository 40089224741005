import styled from 'styled-components';
import { StyledMainWrapper } from '../../styles/Styled';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

export const Container = styled(StyledMainWrapper)`
  background: ${ThemeUtility.colorBodyBackground};
  height: 100%;
`;

const hideOnMobile = styled.div`
  display: none;

  @media screen and (min-width: 1025px) {
    display: flex;
  }
`;

export const Header = styled(hideOnMobile)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${ThemeUtility.colorPanelBackground};
  height: 80px;
  padding: 0 32px 0 80px;
  border-bottom: 1px solid ${ThemeUtility.colorDividerBackground};
`;

export const Title = styled.h1`
  font-weight: 300;
  font-size: 28px;
  color: ${ThemeUtility.colorHeaderText};
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  margin: auto;
  height: 100%;

  @media (min-width: 1024px) {
    height: calc(100% - 81px);
  }
`;

export const FNOL = styled.div`
  width: 100%;
  height: 100%;
`;

export const Sidebar = styled(hideOnMobile)`
  --tia-branded-widget-height: 100%;

  min-width: 400px;
  padding: 40px 16px;
  border-left: 1px solid ${ThemeUtility.colorDividerBackground};
`;
