import React, { useEffect, useState } from 'react';
import { fetchPolicy } from '../../utils/policyUtils';
import { useParams } from 'react-router-dom';
import { denormalizeSalesProduct, INormalizedSalesProduct } from '../../utils/salesProductSchema';
import { SalesPolicyProvider, useSalesPolicyProvider } from '../../hooks/useSalesPolicy';
import { ProductDetailRouter } from '../productDetails/ProductDetailsRouter';
import { SalesProductGroupsContext, useSalesProductGroups } from '../productDetails/salesProductGroups';
import { StyledAppContainer } from '../../styles/Styled';
import { PolicyGroupsNavigation } from './PolicyGroupsNavigation.component';
import { getPolicyDetailsRoute, getPolicyInitialGroupRoute, Routes } from '../../router/routes';
import showToastMessage from '../../utils/showToastMessage';
import { ButtonSize, ToastVariant } from '@tia/react-ui-library';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { apiConnector } from '../../utils/apiConnector';
import { WizardFinaliseButton } from '../../components/wizard/WizardStep/WizardFinaliseButton';

const PolicyEditGroupsProvider: React.FC = ({ children }) => {
  const salesProductGroups = useSalesProductGroups({ mode: 'EDIT' });

  return <SalesProductGroupsContext.Provider value={salesProductGroups}>{children}</SalesProductGroupsContext.Provider>;
};

export const PolicyEdit: React.FC = () => {
  const policyId = (useParams() as { policyId: string }).policyId;
  const { t } = useTranslation();
  const history = useHistory();
  const [policyData, setPolicyData] = useState<INormalizedSalesProduct | undefined>(undefined);

  const fallbackUrl = React.useMemo(() => {
    return getPolicyInitialGroupRoute(policyId);
  }, [policyData]);

  const handleFailedFetch = (): void => {
    showToastMessage({ message: t('views.policy-details.policyNotFound'), variant: ToastVariant.ERROR });
    history.push(Routes.Home);
  };

  useEffect(() => {
    fetchPolicy(policyId)
      .then(setPolicyData)
      .catch((e) => {
        console.error('Could not fetch policy', e);
        handleFailedFetch();
      });
  }, []);

  if (!policyData) {
    return null;
  }

  return (
    <SalesPolicyProvider entities={policyData} completeButton={UpdatePolicyButton}>
      <PolicyEditGroupsProvider>
        <PolicyGroupsNavigation>
          <StyledAppContainer>
            <ProductDetailRouter fallbackUrl={fallbackUrl} />
          </StyledAppContainer>
        </PolicyGroupsNavigation>
      </PolicyEditGroupsProvider>
    </SalesPolicyProvider>
  );
};

const UpdatePolicyButton: React.FC<{ enabled: boolean; size?: ButtonSize; block?: boolean }> = ({
  enabled,
  size,
  block,
}) => {
  const [isInUse, setInUse] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { entities } = useSalesPolicyProvider();

  const handleClick = async (): Promise<void> => {
    setInUse(true);

    try {
      const response = (await handlePolicyUpdate(entities)) as { successful: boolean };

      if (!response.successful) {
        throw response;
      }

      showToastMessage({
        message: t('views.policy-details.updatedSuccessfully'),
        variant: ToastVariant.SUCCESS,
      });
      history.push(getPolicyDetailsRoute(entities.salesProduct.root.salesProductSystemProperties.UUID));
    } catch (e) {
      showToastMessage({
        message: t('views.policy-details.failedToComplete'),
        variant: ToastVariant.ERROR,
      });
      console.error('Failed to add to basket', e);
    }

    setInUse(false);
  };

  return (
    <WizardFinaliseButton enabled={enabled} onClick={handleClick} isInUse={isInUse} size={size} block={block}>
      {t('views.policy-details.buttonUpdateQuote')}
    </WizardFinaliseButton>
  );
};

const handlePolicyUpdate = (entities: INormalizedSalesProduct): Promise<never> => {
  const salesPolicy = denormalizeSalesProduct(entities as INormalizedSalesProduct);

  return apiConnector().salesPolicy.updateSalesPolicy(salesPolicy) as Promise<never>;
};
