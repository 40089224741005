"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var API_URL = 'v1/ui-configurations';
exports.getEntitiesWithUiConfigUsingGET = function (name, params) {
    return {
        url: API_URL + "/" + name + "/get-entities-with-uiconfigurations?" + params.toString(),
        method: 'GET',
    };
};
exports.updateEntitiesUsingPUT = function (name, data, params) {
    return {
        data: data,
        url: API_URL + "/" + name + "/save-entities?" + params.toString(),
        method: 'PUT',
    };
};
