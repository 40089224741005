import { IStorageMapping, IAuthOptions } from '@tia/authenticator/dist/interfaces/IAuth.interface';

const WIDGET_EXPIRES_POSTFIX = '_expires_at';

export const getWidgetAuthorizations = (): IStorageMapping[] => {
  const { cjdAudience } = window.custEnv;

  if (!cjdAudience) {
    throw Error('Please check the configuration. cjdAudience is missing');
  }

  return [
    {
      aud: cjdAudience,
      key: 'cjd_access_token',
      expiresAtKey: `cjd_access_token${WIDGET_EXPIRES_POSTFIX}`,
    },
  ];
};

export const getAuthOptions = (): IAuthOptions => {
  const { domain, clientID, audience, additionalAuthOptions } = window.custEnv;

  if (!domain || !clientID) {
    throw Error(
      'Please check the configuration. Either Domain, clientId, redirectUri or successfulLogoutUri might be missing'
    );
  }

  return {
    domain,
    clientID,
    audience,
    additionalAuthOptions,
    redirectUri: `${window.custEnv.contextLocation}/callback`,
    successfulLogoutUri: `${window.custEnv.contextLocation}/logout`,
    responseType: process.env.REACT_APP_RESPONSE_TYPE,
    scope: process.env.REACT_APP_SCOPE,
    tokenKey: 'tiacustomer_access_token',
  };
};
