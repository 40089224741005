import React, { FC, useState } from 'react';

import {
  BasketBackdrop,
  BasketContainer,
  BasketHeader,
  BasketHeaderContainer,
  BasketTitle,
  BasketWrapper,
  CheckoutContainer,
  ContinueContainer,
  Divider,
  EmptyBasket,
  EmptyBasketImage,
  EmptyBasketText,
  FooterContainer,
  ItemsWrapper,
  StyledBasket,
  Total,
  TotalContainer,
  TotalPriceContainer,
  TotalPricePerMonth,
  TotalWrapper,
} from './Basket.styled';

import { Button, ButtonColor, ButtonSize, Icon, IconName, Spinner, ToastVariant } from '@tia/react-ui-library';
import { useTranslation } from 'react-i18next';

import { useSalesBasketProvider } from '../../hooks/useSalesBasket';
import IPortfolio from '@tia/customer-api-connector/dist/model/IPortfolio';
import { Routes } from '../../router/routes';
import { useHistory } from 'react-router-dom';
import { apiConnector } from '../../utils/apiConnector';
import showToastMessage from '../../utils/showToastMessage';
import BasketItem from './BasketItem.component';

export const Basket: FC = () => {
  const [isOfferConfirming, setOfferConfirming] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    loading,
    salesBasket,
    itemCount,
    setItemCount,
    isBasketVisible,
    closeBasket,
    totalPremium,
    currency,
    successful,
  } = useSalesBasketProvider();
  const history = useHistory();

  if (loading) {
    return <Spinner size={20} />;
  }

  const handleBuy = async (): Promise<void> => {
    try {
      const uuids = salesBasket?.map((b: IPortfolio) => b.uuid);

      setOfferConfirming(true);
      if (uuids) {
        const response = await apiConnector().salesPolicy.setSalesPolicyInForce(uuids);

        if (!response.successful) {
          throw response;
        }

        setItemCount(0);
        closeBasket();
        history.push(Routes.Checkout);
      }
    } catch (e) {
      closeBasket();
      showToastMessage({
        message: t('views.basket.failedToSetInForce'),
        variant: ToastVariant.ERROR,
      });
      console.error('Failed to set in force ', e);
    }

    setOfferConfirming(false);
  };

  const renderBasketItems = (): JSX.Element => {
    return (
      <ItemsWrapper data-testid="basket-item">
        {salesBasket?.map((portfolio: IPortfolio) => (
          <BasketItem key={portfolio.uuid} portfolio={portfolio} />
        ))}
      </ItemsWrapper>
    );
  };

  const getBasketContent = (): JSX.Element => {
    if (!successful) {
      return (
        <EmptyBasket data-testid="basket-not-successful">
          <EmptyBasketImage />
          <EmptyBasketText>{t('views.basket.error')}</EmptyBasketText>
        </EmptyBasket>
      );
    }

    const emptyBasket = itemCount === undefined || itemCount === 0;

    if (emptyBasket) {
      return (
        <EmptyBasket data-testid="basket-empty">
          <EmptyBasketImage />
          <EmptyBasketText>{t('views.basket.emptyBasket')}</EmptyBasketText>
        </EmptyBasket>
      );
    }

    return (
      <>
        {renderBasketItems()}
        <TotalWrapper>
          <TotalContainer>
            <Total> {t('views.basket.total')}</Total>
            <TotalPriceContainer>
              <TotalPricePerMonth>
                {totalPremium()} {currency()} {t('views.basket.perYear')}
              </TotalPricePerMonth>
            </TotalPriceContainer>
          </TotalContainer>
          <Divider />
        </TotalWrapper>

        <FooterContainer>
          <ContinueContainer>
            <Button onClick={closeBasket} size={ButtonSize.LARGE} color={ButtonColor.SECONDARY}>
              {t('views.basket.continueShopping')}
            </Button>
          </ContinueContainer>
          <CheckoutContainer>
            <Button
              onClick={handleBuy}
              size={ButtonSize.LARGE}
              color={ButtonColor.PRIMARY}
              disabled={isOfferConfirming}
            >
              {isOfferConfirming && <Spinner size={20} />}
              {t('views.basket.gotoCheckout')}
            </Button>
          </CheckoutContainer>
        </FooterContainer>
      </>
    );
  };

  return (
    <StyledBasket>
      {isBasketVisible && <BasketBackdrop data-testid="outside-container" onClick={closeBasket} />}
      <BasketContainer>
        {isBasketVisible && (
          <BasketWrapper data-testid="basket-dropdown-container">
            <BasketHeaderContainer>
              <BasketHeader>
                <BasketTitle>{t('views.basket.basketHeader')}</BasketTitle>
                <Button
                  icon={<Icon icon={IconName.CLOSE} size={20} />}
                  color={ButtonColor.MINIMAL}
                  size={ButtonSize.SMALL}
                  onClick={closeBasket}
                />
              </BasketHeader>
              <Divider />
            </BasketHeaderContainer>
            {getBasketContent()}
          </BasketWrapper>
        )}
      </BasketContainer>
    </StyledBasket>
  );
};
