import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { StyledMainWrapper, StyledAppContainer, DesktopView, MobileView } from '../../styles/Styled';
import { ProductDetailRouter } from './ProductDetailsRouter';
import { useSalesProductGroups, SalesProductGroupsContext } from './salesProductGroups';
import { PolicyGroupsNavigation } from '../policy/PolicyGroupsNavigation.component';
import { useRouteMatch } from 'react-router-dom';
import { getProductInitialGroupRoute } from '../../router/routes';
import NoSupport from '../../components/noSupport/NoSupport.component';
import { useTranslation } from 'react-i18next';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

const ProductDetails: React.FC<RouteComponentProps> = () => {
  const salesProductGroups = useSalesProductGroups();
  const { t } = useTranslation();
  const {
    params: { productId },
  } = useRouteMatch<{ productId: string }>();

  const fallbackUrl = React.useMemo(() => {
    return getProductInitialGroupRoute(productId, salesProductGroups.routeGroups[0].nodeId);
  }, [productId]);

  return (
    <React.StrictMode>
      <SalesProductGroupsContext.Provider value={salesProductGroups}>
        <Container>
          <PolicyGroupsNavigation>
            <StyledAppContainer>
              <DesktopView>
                <ProductDetailRouter fallbackUrl={fallbackUrl} />
              </DesktopView>
              <MobileView>
                <NoSupport message={t('views.noSupport.mobile.message')} />
              </MobileView>
            </StyledAppContainer>
          </PolicyGroupsNavigation>
        </Container>
      </SalesProductGroupsContext.Provider>
    </React.StrictMode>
  );
};

export default ProductDetails;

const Container = styled(StyledMainWrapper)`
  background: ${ThemeUtility.colorBodyBackground};
`;
