import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useClaimStore, useClaimFileStore } from '../../hooks/claims';
import DetailsHero, { DetailsHeroAction } from '../../components/detailsHero/DetailsHero.component';
import {
  convertISOReadFriendlyDateString,
  convertISOToDaysSinceString,
  convertISOToTimeAgo,
} from '../../utils/dateFormatter';
import { getClaimStatusLabelTranslationKey } from '../../utils/claimStatus';
import showToastMessage from '../../utils/showToastMessage';
import { useHistory } from 'react-router-dom';
import { MobileView, StyledAppContainer } from '../../styles/Styled';
import ClaimFileDetails from './components/ClaimFileDetails.component';
import Timeline, { ITimelineEntry } from '../../components/timeline/Timeline.component';
import DetailsSummary from '../../components/detailsSummary/DetailsSummary.component';
import { triggerDownload } from '../../utils/download';
import { Container } from './ClaimDetails.styled';
import ClaimStatus from '@tia/cjd-api-connector/dist/models/ClaimStatus';
import { Routes } from '../../router/routes';
import { ButtonColor, Spinner, ToastVariant } from '@tia/react-ui-library';
import { DetailsHeroHeader } from '../../components/detailsHero/DetailsHeroHeader.component';
import { DesktopDetailsOverlapContainer, Detail, DetailHeader, DetailsContainer, DetailText } from '../../components/detailsHero/DetailsHero.styled';

const ClaimDetails: FC<RouteComponentProps> = (props): JSX.Element | null => {
  const { t } = useTranslation();
  const { claimData, setClaimId, isSuccessful } = useClaimStore();
  const { claimFileData, setClaimFileId, isClaimFileLoading } = useClaimFileStore();
  const [downloadFile, setDownloadFile] = useState<boolean>(false);
  const urlParams = props.location.search;
  const formattedParams = new URLSearchParams(urlParams);
  const claimId = formattedParams.get('claimId');
  const history = useHistory();

  if (!claimId) {
    showToastMessage({
      message: t('views.claim-details.noClaimIdFound'),
      variant: ToastVariant.ERROR,
    });

    history.push(Routes.Home);
  }

  if (!isSuccessful) {
    showToastMessage({
      message: t('views.claim-details.noClaimIdFound'),
      variant: ToastVariant.ERROR,
    });

    history.push(Routes.Home);
  }

  setClaimId(Number(claimId));

  const loadingHeroDetails = [
    {
      title: t('views.claim-details.detailSummary.claimNumber'),
      text: '',
    },
    {
      title: t('views.claim-details.detailSummary.status'),
      text: '',
    },
    {
      title: t('views.claim-details.detailSummary.reported'),
      text: '',
    },
  ];

  if (!claimData) {
    return (
      <>
        <DetailsHero
          details={loadingHeroDetails}
          subTitle={t('views.claim-details.detailsHero.subTitle')}
          title={''}
          image={''}
          loading={true}
        />
        <div style={{ marginTop: '50px', textAlign: 'center' }}>
          <Spinner size={20} />
        </div>
      </>
    );
  }

  let image = '';

  if (Array.isArray(claimData?.claimStages)) {
    image = claimData?.claimStages[0].image;
  }

  const heroDetails = [
    { title: t('views.claim-details.detailSummary.claimNumber'), text: claimData.alternativeId },
    {
      title: t('views.claim-details.detailSummary.status'),
      text: t(getClaimStatusLabelTranslationKey(claimData.status)),
    },
    {
      title: t('views.claim-details.detailSummary.reported'),
      text: convertISOReadFriendlyDateString(claimData.notificationDate),
    },
  ];

  const detailElements = heroDetails.map((element, index) => (
    <Detail key={index}>
      <DetailHeader>{element.title}</DetailHeader>
      <DetailText>{element.text ? element.text : <Spinner size={20} />}</DetailText>
    </Detail>
  ));


  if (claimFileData && downloadFile && !isClaimFileLoading) {
    triggerDownload(claimFileData.date, claimFileData.fileName?.toString() || 'undefined');
    setDownloadFile(false);
  }

  const fileDownload = (fileId: number): void => {
    setClaimFileId(fileId);
    setDownloadFile(true);
  };

  const getClaimEvents = (): ITimelineEntry[] => {
    const createdEvent = {
      title: `${convertISOToDaysSinceString(claimData.notificationDate)} day(s) ago - ${convertISOToTimeAgo(
        claimData.notificationDate
      )}`,
      description: t('views.claim-details.timeline.events.created'),
    };

    const processEvent = {
      title: '',
      description: t('views.claim-details.timeline.events.processing'),
    };

    const cancelledEvent = {
      title: '',
      description: t('views.claim-details.timeline.events.cancelled'),
    };

    const resolvedEvent = {
      title: '',
      description: t('views.claim-details.timeline.events.resolved'),
    };

    switch (claimData.status) {
      case ClaimStatus.PENDING:
        return [processEvent, createdEvent];

      case ClaimStatus.ONGOING:
        return [processEvent, createdEvent];

      case ClaimStatus.PROCESSING:
        return [processEvent, createdEvent];

      case ClaimStatus.SOLVED:
        return [resolvedEvent, processEvent, createdEvent];

      case ClaimStatus.CANCELLED:
        return [cancelledEvent, processEvent, createdEvent];

      default:
        return [];
    }
  };

  const handleRoute = (): void => history.push(Routes.Support);

  return (
    <>
      <DetailsHeroHeader
        subTitle={t('views.claim-details.detailsHero.subTitle')}
        title={claimData.description}
        image={image}
      >
        <DetailsHeroAction color={ButtonColor.SECONDARY} onClick={handleRoute} data-testid="edit-policy-button">
          {t('views.claim-details.detailsHero.support')}
        </DetailsHeroAction>
      </DetailsHeroHeader>
      <DesktopDetailsOverlapContainer>
        <StyledAppContainer paddingTop="0" paddingBottom="0">
          <DetailsContainer>{detailElements}</DetailsContainer>
        </StyledAppContainer>
      </DesktopDetailsOverlapContainer>
      <StyledAppContainer>
        <Container>
          <Timeline
            noTimelineEventsFound={'No timeline events found'}
            timelineEntries={getClaimEvents()}
            title={t('views.claim-details.timeline.title')}
          />
          <ClaimFileDetails
            files={claimData.files}
            title={t('views.claim-details.fileDetails.title')}
            noFilesFoundMessage={t('views.claim-details.fileDetails.noClaimFilesFound')}
            fileDownload={fileDownload}
          />

          <MobileView>
            <DetailsSummary details={heroDetails} title={t('views.claim-details.detailSummary.title')} />
          </MobileView>
        </Container>
      </StyledAppContainer>
    </>
  );
};

export default withRouter(ClaimDetails);
