import React from 'react';
import { Button, ButtonColor, ButtonSize, Spinner } from '@tia/react-ui-library';
import styled from 'styled-components';

interface IWizardFinaliseButton {
  enabled: boolean;
  isInUse: boolean;
  onClick: () => void;
  size?: ButtonSize;
  block?: boolean;
}

export const WizardFinaliseButton: React.FC<IWizardFinaliseButton> = ({
  children,
  onClick,
  enabled,
  isInUse,
  size,
  block,
}) => {
  return (
    <Button
      disabled={isInUse || !enabled}
      color={ButtonColor.PRIMARY}
      data-testid={'button-complete'}
      onClick={onClick}
      size={size}
      block={block}
    >
      <CenteredButtonContent>
        {isInUse && (
          <SpinnerWrapper>
            <Spinner size={20} />
          </SpinnerWrapper>
        )}
        {children}
      </CenteredButtonContent>
    </Button>
  );
};

const SpinnerWrapper = styled.span`
  margin-right: 0.5rem;
`;

const CenteredButtonContent = styled.div`
  display: flex;
  align-items: center;
`;
