import { normalize, schema, denormalize } from 'normalizr';
import {
  ISalesProduct,
  IClause,
  IGeneralGroupSystemProperties,
  IOpt,
  IField,
  IObjectGroupSystemProperties,
  IGroupUiProperty,
  IDetail,
  ISalesProductSystemProperties,
  ISalesProductUiProperties,
  IDetailInstance,
  ICoverGroupSystemProperties,
  ICoverGroupUiProperties,
  ISalesProductHeader,
} from '@tia/salesproduct-parser/dist/interfaces/ISalesProduct';

export interface INormalizedGeneralGroups {
  clauses: IClause;
  fields: string[];
  generalGroupSystemProperties: IGeneralGroupSystemProperties;
  generalGroupUiProperties: {
    label: string;
    nodeID: string;
    opt: IOpt[];
    order: number;
    rendered: string;
  };
  nodeID: string;
}

export interface INormalizedObjectInstances {
  UUID: string;
  clauses: IClause;
  coverGroups: string[];
  details: IDetail;
  fields: string[];
  nodeID: string;
}

export interface INormalizedObjectGroups {
  nodeID: string;
  objectGroupSystemProperties: IObjectGroupSystemProperties;
  objectGroupUiProperties: IGroupUiProperty;
  objectInstances: string[];
}

interface INormalizedSalesParties {
  generalPartyGroups: [];
  nodeID: string;
  salesPartySystemProperties: {
    UUID: string;
    backendPartyName: string;
    extBackendPartyId: string;
    name: string;
    nodeID: string;
    schema: string;
    schemaVersion: string;
  };
  salesPartyUiProperties: {
    label: string;
    mandatory: boolean;
    nodeID: string;
    opt: IOpt;
  };
  staticPartyGroup: {
    fields: [];
    nodeID: string;
    staticPartyGroupSystemProperties: {
      group: string;
      nodeID: string;
    };
  };
}

export interface INormalizedSalesObject {
  generalGroups: string[];
  nodeID: 'root';
  objectGroups: string[];
  salesParties: string[];
  salesProductHeader: ISalesProductHeader;
  salesProductSystemProperties: ISalesProductSystemProperties;
  salesProductUiProperties: ISalesProductUiProperties;
  searchConfigurations: [];
}

export interface INormalizedCoverGroup {
  nodeID: string;
  active: boolean;
  fields: string[];
  clauses: string[];
  coverGroupSystemProperties: ICoverGroupSystemProperties;
  coverGroupUiProperties: ICoverGroupUiProperties;
  premium: number;
  tooltipText?: string;
}

interface ISearchConfiguration {
  nodeID: string;
  name: string;
  version: number;
  description: string;
  inputFields: {
    param: string;
    field: string;
    nodeID: string;
  }[];
  outputFields: {
    param: string;
    field: string;
    nodeID: string;
  }[];
  outputObjects: unknown[];
  opt: [];
  URL: string;
}

export interface INormalizedSalesProduct {
  generalGroups: {
    [key: string]: INormalizedGeneralGroups;
  };
  objectGroups: {
    [key: string]: INormalizedObjectGroups;
  };
  objectInstances: {
    [key: string]: INormalizedObjectInstances;
  };
  coverGroups: {
    [key: string]: INormalizedCoverGroup;
  };
  fields: {
    [key: string]: IField;
  };
  salesParties: {
    [key: string]: INormalizedSalesParties;
  };
  salesProduct: {
    root: INormalizedSalesObject;
  };
  clauses: {
    [key: string]: IClause;
  };
  searchConfiguration: {
    [key: string]: ISearchConfiguration;
  };
  detailInstances: {
    [key: string]: IDetailInstance;
  };
}

const idAttribute = { idAttribute: 'nodeID' };

const saleProductSchema = new schema.Entity('salesProduct', {}, idAttribute);
const generalGroupSchema = new schema.Entity('generalGroups', {}, idAttribute);
const objectGroupSchema = new schema.Entity('objectGroups', {}, idAttribute);
const salesPartySchema = new schema.Entity('salesParties', {}, idAttribute);

const objectInstanceSchema = new schema.Entity('objectInstances', {}, idAttribute);
const detailSchema = new schema.Entity('details', {}, idAttribute);

const coverGroupSchema = new schema.Entity('coverGroups', {}, idAttribute);
const fieldSchema = new schema.Entity('fields', {}, idAttribute);
const clauseSchema = new schema.Entity('clauses', {}, idAttribute);

const detailInstanceSchema = new schema.Entity('detailInstances', {}, idAttribute);

const searchConfigurationSchema = new schema.Entity('searchConfiguration', {}, { idAttribute: 'name' });

saleProductSchema.define({
  generalGroups: [generalGroupSchema],
  objectGroups: [objectGroupSchema],
  salesParties: [salesPartySchema],
  searchConfigurations: [searchConfigurationSchema],
});

generalGroupSchema.define({
  fields: [fieldSchema],
  clauses: [clauseSchema],
});

objectGroupSchema.define({
  objectInstances: [objectInstanceSchema],
});

objectInstanceSchema.define({
  coverGroups: [coverGroupSchema],
  fields: [fieldSchema],
  clauses: [clauseSchema],
  details: [detailSchema],
});

coverGroupSchema.define({
  fields: [fieldSchema],
  clauses: [clauseSchema],
});

detailSchema.define({
  detailInstances: [detailInstanceSchema],
});

detailInstanceSchema.define({
  fields: [fieldSchema],
});

/**
 * Normalizes sales product into normalized schema
 * @param salesProduct
 */
export const normalizeSalesProduct = (salesProduct: ISalesProduct): INormalizedSalesProduct => {
  return normalize<INormalizedSalesProduct, INormalizedSalesProduct, string>(salesProduct, saleProductSchema).entities;
};

/**
 * Denormalizes normalized product back into its original schema
 * @param entities
 */
export const denormalizeSalesProduct = (entities: INormalizedSalesProduct): ISalesProduct => {
  return denormalize('root', saleProductSchema, entities);
};
