import React, { FC, useEffect } from 'react';
import { getGeneralGroupSubGroups } from '../../../utils/salesPolicy';
import { Wizard } from '../../../components/wizard/Wizard.component';
import { ISalesProductRoutingGroup } from '../../../interfaces/ISalesProductRouting';
import { useProductSubGroups, ProductSubGroupsContext } from '../productSubGroupContext';
import { useSalesPolicyProvider } from '../../../hooks/useSalesPolicy';

interface IProps {
  generalGroup: ISalesProductRoutingGroup;
}

const ProductDetailsGeneralGroup: FC<IProps> = ({ generalGroup }): JSX.Element | null => {
  const { entities } = useSalesPolicyProvider();
  const productSubGroups = useProductSubGroups(generalGroup);
  const { subGroups, setSubGroups } = productSubGroups;

  useEffect(() => {
    if (!entities) {
      return;
    }

    const generalGroupSubGroups = getGeneralGroupSubGroups(entities, generalGroup.nodeId);

    generalGroupSubGroups.forEach((subGroup) => {
      subGroup.isCompleted = generalGroup.isCompleted;
    });

    setSubGroups(generalGroupSubGroups);
  }, []);

  if (!subGroups) {
    return null;
  }

  return (
    <ProductSubGroupsContext.Provider value={productSubGroups}>
      <Wizard groupId={generalGroup.nodeId} />
    </ProductSubGroupsContext.Provider>
  );
};

export default ProductDetailsGeneralGroup;
