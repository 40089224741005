import React, { createRef, useContext } from 'react';
import styled from 'styled-components';

const AppWrapperHtmlElementContext = React.createContext<React.RefObject<HTMLDivElement> | null>(null);
const AppWrapperHtmlElementProvider = AppWrapperHtmlElementContext.Provider;

export const AppWrapper: React.FC = ({ children }) => {
  const htmlRef = createRef<HTMLDivElement>();

  return (
    <AppWrapperHtmlElementProvider value={htmlRef}>
      <StyledAppWrapper ref={htmlRef} id="page-wrap">
        {children}
      </StyledAppWrapper>
    </AppWrapperHtmlElementProvider>
  );
};

export const useAppWrapper = (): HTMLDivElement => {
  const ref = useContext(AppWrapperHtmlElementContext);

  return ref?.current as HTMLDivElement;
};

const StyledAppWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;
