import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ThemeUtility } from '@tia/customer-self-service-portal-theme';

interface IHeaderProps {
  menuVisibility: boolean;
}

export const StyledHeader = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 11;
  border-bottom: 1px solid ${ThemeUtility.colorDividerBackground};
  font-size: 14px;
  color: ${ThemeUtility.colorCaptionText};
  background-color: ${ThemeUtility.colorPanelBackground};
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.09);
`;

export const LogoContainer = styled.div`
  padding: 0 32px;
`;

export const StyledNavLink = styled(NavLink).attrs({
  activeClassName: 'active',
})`
  padding: 0 20px;
  margin: 0 10px;
  font-size: 14px;
  font-weight: 700;
  color: ${ThemeUtility.colorCaptionText};
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  &.active {
    color: ${ThemeUtility.colorPrimary};
    border-bottom: 2px solid ${ThemeUtility.colorPrimary};
    font-weight: 800;
  }
`;

export const DesktopHeader = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const MobileDeviceHeader = styled.div<IHeaderProps>`
  transition: all 0.5s ease 0s;
  display: grid;
  grid-template-columns: 120px auto 120px;
  align-items: center;
  height: 64px;

  ${({ menuVisibility }): false | FlattenSimpleInterpolation =>
    menuVisibility &&
    css`
      transform: translate3d(240px, 0px, 0px);
      transition: all 0.5s ease 0s;
    `}

  .pageTitle {
    font-size: 18px;
    font-weight: 700;
    color: ${ThemeUtility.colorBodyText};
    text-align: center;
  }
  tia-button {
    padding: 0 6px;
  }

  .rightSideButtons {
    display: flex;
    justify-content: flex-end;
  }
`;

export const MobileHeader = styled(MobileDeviceHeader)`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const TabletHeader = styled(MobileDeviceHeader)`
  @media (max-width: 767px) {
    display: none;
  }

  @media (min-width: 1025px) {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  tia-dropdown:last-child {
    margin: 0 32px -4px 16px;
    width: 270px;
  }
`;

export const Logo = styled(NavLink)`
  display: inline-block;
  height: 30px;
  width: ${ThemeUtility.topMenuLogoWidth};
  background-image: ${ThemeUtility.topMenuLogoUrl};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;
